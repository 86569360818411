require('@app/__tests__/setup')
const { createNewMask } = require('./utils')

describe('createNewMask', () => {
    beforeEach(() => {
        document.body.innerHTML = `
            <div>
                <form>
                    <input type='text' id='test-datepicker' />
                </form>
            </div>
        `
    })

    test('should create imask with default format DD/MM/YYYY when locale is not provided', async () => {
        const iMask = createNewMask('test')
        expect(iMask.mask).toBe('DD/MM/YYYY')
    })

    test('should create imask with default format DD / MM / YYYY when locale fr-FR is provided', async () => {
        const iMask = createNewMask('test', 'fr-FR')
        expect(iMask.mask).toBe('DD / MM / YYYY')
    })

    test('should create imask with default format DD / MM / YYYY when locale en-US is provided', async () => {
        const iMask = createNewMask('test', 'en-US')
        expect(iMask.mask).toBe('MM / DD / YYYY')
    })

    test('should create imask with default moment format MM / DD / YYYY when locale is provided but wrong', async () => {
        const iMask = createNewMask('test', 'sldjkf')
        expect(iMask.mask).toBe('MM / DD / YYYY')
    })
})
