import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { APP_API_URL } = settings

function getHospitals() {
    return queueQueries(`${APP_API_URL}/theraflow/hospitals/`, 'GET')
}

export const Hospital = {
    getHospitals,
}
