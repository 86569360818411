import { removeURLParameter } from './../removeURLParameter'

const getQueryVariable = function (variable, editQuery = true) {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == variable && pair[1]) {
            if (editQuery) {
                window.history.pushState(
                    '',
                    '',
                    removeURLParameter(
                        window.location.pathname + window.location.search,
                        variable
                    )
                )
            }
            return pair[1]
        }
    }

    return false
}

export { getQueryVariable }
