import { h } from 'hyperapp';
import { default as classMerge } from 'classcat';
import './index.scss';

/**
 * Button
 * @see https://picturepan2.github.io/spectre/elements.html#buttons
 * @param {any} props All properties passed to element
 * @param {any} props.primary TBD
 * @param {any} props.link TBD
 * @param {any} props.success TBD
 * @param {any} props.error TBD
 * @param {any} props.block TBD
 * @param {any} props.small TBD
 * @param {any} props.large TBD
 * @param {any} props.action TBD
 * @param {any} props.circle TBD
 * @param {any} props.active TBD
 * @param {any} props.loading TBD
 * @param {any} props.classes All CSS classes extending the element
 * @param {any} children All children elements to be nested inside
 * @return vNode
 */
const Button = (props, children) => {
    const {
        flat = false,
        primary = false,
        link = false,
        dangerous = false,
        successed = false,
        cancel = false,
        blue = false,
        anchorLeft = false,
        anchorRight = false,

        bgImg = '',
        width = 'auto',
        type = 'button',

        success = false,
        error = false,

        block = false,
        small = false,
        large = false,
        action = false,
        circle = false,

        active = false,
        loading = false,
        colored = false,

        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge({
        btn: !!true,

        'btn-flat': !!flat,
        'btn-primary': !!primary,
        'btn-link': !!link,
        'btn-success': !!success,
        'btn-error': !!error,
        'btn-block': !!block,
        'btn-small': !!small,
        'btn-large': !!large,
        'btn-dangerous': !!dangerous,
        'btn-successed': !!successed,
        'btn-cancel': !!cancel,
        'btn-blue': !!blue,
        'btn-anchor-left': !!anchorLeft,
        'btn-anchor-right': !!anchorRight,

        'btn-action': !!action,
        'btn-circle': !!circle,

        'btn-active': !!active,
        'btn-loading': !!loading,
        'btn-colored': !!colored,
        classes,
    });

    return (
        <div class='btzButtonWrapper' style={{ 'display': (props.flat ? 'inline-block' : 'block'), 'float': ((props.anchorLeft) ? 'left' : (props.anchorRight ? 'right' : '')), 'margin': (props.margin || ''), 'width': width }}>
            {(props.bgImg !== undefined) ?
                 <button class={allClasses} style={{ 'background-image': 'url(' + props.bgImg + ')', 'width': '100%' }} {...otherProps} type={type}>
                    {children}
                </button>
                :
                <button class={allClasses} style={{ 'width': '100%' }} {...otherProps} type={type}>
                    {children}
                </button>
            }
        </div>
    );
};

const Group = (props, children) => {
    const {
        block = false,

        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge({
        'btn-group': !!true,
        'btn-group-block': !!block,

        classes,
    });

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    );
};

const SwitchCheckBox = (props, children) => {
    const {
        checked = false,

        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge({
        'btn-checkbox': !!true,

        classes,
    });

    let labelOff = (props.label !== undefined) ? props.label.off : '';
    let labelOn = (props.label !== undefined) ? props.label.on : '';

    return (
        <div>
            {props.invert ?
                <div>
                    <div class={labelOff === '' ? 'col-3 p-0' : ''}>
                        <div class='btzButtonWrapper btzButtonWrapper-btzSwitcherWrapper' style={props.style}>
                            {labelOff !== '' ?
                                <div class={allClasses}>
                                    <input type='checkbox' name='checkbox1' id='checkbox3' class='ios-toggle' checked={props.checked ? 'checked': ''} {...otherProps} />
                                    <label for='checkbox3' class='checkbox-label' data-off={labelOff} data-on={labelOn}></label>
                                </div>
                                :
                                <div class={allClasses}>
                                    <input type='checkbox' name='checkbox1' id='checkbox3' class='ios-toggle' checked={props.checked ? 'checked': ''} {...otherProps} />
                                    <label for='checkbox3' class='checkbox-label'></label>
                                </div>
                            }
                        </div>
                    </div>
                    {labelOff === '' &&
                        <p class='col-9 btzPageContentTexte' style={{ 'display': 'inline-block', 'vertical-align': 'middle', 'margin': '0' }}>{children}</p>
                    }
                </div>
                :
                <div>
                    {labelOff === '' &&
                        <p class='col-9 btzPageContentTexte' style={{ 'display': 'inline-block', 'vertical-align': 'middle', 'margin': '0' }}>{children}</p>
                    }
                    <div class={labelOff === '' ? 'col-3 p-0' : ''}>
                        <div class='btzButtonWrapper btzButtonWrapper-btzSwitcherWrapper' style={props.style}>
                            {labelOff !== '' ?
                                <div class={allClasses}>
                                    <input type='checkbox' name='checkbox1' id='checkbox3' class='ios-toggle' checked={props.checked ? 'checked': ''} {...otherProps} />
                                    <label for='checkbox3' class='checkbox-label' data-off={labelOff} data-on={labelOn}></label>
                                </div>
                                :
                                <div class={allClasses}>
                                    <input type='checkbox' name='checkbox1' id='checkbox3' class='ios-toggle' checked={props.checked ? 'checked': ''} {...otherProps} />
                                    <label for='checkbox3' class='checkbox-label'></label>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

Button.Group = Group;
Button.SwitchCheckBox = SwitchCheckBox;
export { Button };
