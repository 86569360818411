import { default as isLength } from 'validator/lib/isLength'
import { default as isNumeric } from 'validator/lib/isNumeric'
import { t } from 'i18next'
import { h } from 'hyperapp'
import { default as classMerge } from 'classcat'
import { isEmail, isDefined } from '@app/core'
import './index.scss'

import icoValidQCM from '@app/img/ico/ico-valid-green.png'
import icoValidQCMx2 from '@app/img/ico/ico-valid-green@2x.png'

import icoValidRadio from '@app/img/ico/ico-valid-white.png'
import icoValidRadiox2 from '@app/img/ico/ico-valid-white@2x.png'

export const actions = {
    switchPwd: ({ target }) => {
        var inp = target.parentNode.firstChild
        inp.setAttribute(
            'type',
            inp.getAttribute('type') === 'text' ? 'password' : 'text'
        )
    },
    updateListingFilter: ({ target }) => {
        actions.debounce({ func: actions.debounceListing(target), wait: 500 })
    },
    debounceListing: (target) => {
        let filter = target.value
        let parentNode = target.parentNode
        let childNode = parentNode.querySelectorAll(
            '.btzForm-btzFormInputListing'
        )
        childNode.forEach(function (entry) {
            if (
                entry.textContent.toLowerCase().indexOf(filter.toLowerCase()) >
                -1
            ) {
                entry.style.display = ''
            } else {
                entry.style.display = 'none'
            }
        })
    },
    debounce: ({ func: func, wait: wait, immediate: immediate }) => {
        var timeout
        return function () {
            var context = this,
                args = arguments
            var later = function () {
                timeout = null
                if (!immediate) func.apply(context, args)
            }
            var callNow = immediate && !timeout
            clearTimeout(timeout)
            timeout = setTimeout(later, wait)
            if (callNow) func.apply(context, args)
        }
    },
    updateIsInputValid: ({ target, validationcb }) => {
        actions.debounce({
            func: actions.onUpdate({
                target: target,
                validationcb: validationcb,
            }),
            wait: 500,
        })
    },
    focusNextField: ({ target }) => {
        let newValue = target.value,
            maxValue = target.getAttribute('maxlength')
        if (String(newValue).length === +maxValue) {
            var next = target
            // eslint-disable-next-line
            while ((next = next.nextElementSibling)) {
                if (!isDefined(next)) break
                if (next.tagName.toLowerCase() === 'input') {
                    next.focus()
                    next.setSelectionRange(0, next.value.length)
                    break
                }
            }
        }
    },
    onUpdate: ({ target, validationcb }) => {
        let name = target.name.toLowerCase()
        let value = target.value
        let isValid = false
        if (name === undefined) {
            return false
        }

        switch (true) {
            case name.indexOf('code') > -1:
                isValid =
                    isNumeric(value) &&
                    isLength(value, { min: 1, max: 1 }) &&
                    value > 0 &&
                    value < 10

                break
            case name.indexOf('phone') > -1:
                isValid =
                    isNumeric(value) &&
                    isLength(value, { min: 1, max: 1 }) &&
                    value > 0 &&
                    value < 100

                break
            case name.indexOf('email') > -1:
                isValid = isEmail(value)

                break
            case name.indexOf('password') > -1:
            case name.indexOf('secret') > -1:
            case name.indexOf('nick') > -1:
                isValid = isLength(value, { min: 1 })

                break
            case name.indexOf('date') > -1 && name.indexOf('day') > -1:
                isValid =
                    isNumeric(value) &&
                    isLength(value, { min: 1, max: 2 }) &&
                    value > 0 &&
                    value < 32

                break
            case name.indexOf('date') > -1 && name.indexOf('month') > -1:
                isValid =
                    isNumeric(value) &&
                    isLength(value, { min: 1, max: 2 }) &&
                    value > 0 &&
                    value < 13

                break
            case name.indexOf('date') > -1 && name.indexOf('year') > -1:
                isValid =
                    isNumeric(value) &&
                    isLength(value, { min: 4, max: 4 }) &&
                    value > 1900 &&
                    value < 2019

                break
            default:
                throw Error(`${name} has no validator assigned`)
        }

        target.classList.remove('is-success')
        target.classList.remove('is-error')
        if (isValid) {
            target.classList.add('is-success')
        } else {
            target.classList.add('is-error')
        }
        if (validationcb) {
            validationcb({ name: target.name, valid: isValid })
        }
    },
}

const Form = (props, children) => {
    const { horizontal, classes, ...otherProps } = props

    const allClasses = classMerge([
        'btzForm',
        horizontal && 'form-horizontal',
        classes,
    ])

    return (
        <form class={allClasses} {...otherProps}>
            {children}
        </form>
    )
}

const Group = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['btzForm-btzFormGroup', classes])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

const Label = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['btzForm-btzFormLabel', classes])

    return (
        <label class={allClasses} {...otherProps}>
            {children}
        </label>
    )
}

const Hint = (props, children) => {
    const { success, error, classes, ...otherProps } = props

    const allClasses = classMerge([
        'form-input-hint',
        success && 'is-success',
        error && 'is-error',
        classes,
    ])

    return (
        <p class={allClasses} {...otherProps}>
            {children}
        </p>
    )
}

const Select = (props) => {
    const { name, classes, onchange = null, ...otherProps } = props

    const allClasses = classMerge(['form-input-select', classes])

    return (
        <select class={allClasses} name={name} onchange={props.onchange}>
            {props.options.map((item) => (
                <option
                    value={item.value !== undefined ? item.value : item}
                    selected={
                        item.value !== undefined
                            ? item.value === props.selected
                                ? 'selected'
                                : ''
                            : item === props.selected
                              ? 'selected'
                              : ''
                    }
                >
                    {item.key !== undefined ? item.key : item}
                </option>
            ))}
        </select>
    )
}

const Input = (props) => {
    const {
        name,
        type = 'text',
        size,
        success,
        error,
        classes,
        loaded = true,
        dynResize = false,
        ...otherProps
    } = props

    const allClasses = classMerge([
        'btzForm-btzFormInput',
        size,
        success && 'is-success',
        error && 'is-error',
        classes,
    ])

    let dateValueDay = '',
        dateValueMonth = '',
        dateValueYear = ''
    if (type === 'date' && otherProps.value !== undefined) {
        dateValueDay = otherProps.value.day
        dateValueMonth = otherProps.value.month
        dateValueYear = otherProps.value.year
        delete otherProps.value
    }

    let codeNum1 = '',
        codeNum2 = '',
        codeNum3 = '',
        codeNum4 = '',
        codeNum5 = '',
        codeNum6 = ''
    if (type === 'code' && otherProps.value !== undefined) {
        codeNum1 = otherProps.value[0]
        codeNum2 = otherProps.value[1]
        codeNum3 = otherProps.value[2]
        codeNum4 = otherProps.value[3]
        codeNum5 = otherProps.value[4]
        codeNum6 = otherProps.value[5]
        delete otherProps.value
    }

    let phoneNum1 = '',
        phoneNum2 = '',
        phoneNum3 = '',
        phoneNum4 = '',
        phoneNum5 = ''
    if (type === 'phone' && otherProps.value !== undefined) {
        phoneNum1 = otherProps.value[0]
        phoneNum2 = otherProps.value[1]
        phoneNum3 = otherProps.value[2]
        phoneNum4 = otherProps.value[3]
        phoneNum5 = otherProps.value[4]
        delete otherProps.value
    }

    if (dynResize) {
        let tmt = setTimeout(() => {
            clearTimeout(tmt)
            tmt = null
            let trg = document.querySelectorAll('input[name="' + name + '"]')
            if (trg.length > 0) {
                let ruler = document.getElementById('btzRuler'),
                    val = '',
                    offsetWidth = 0,
                    inputWidth = trg[0].offsetWidth,
                    initialFontSize = +window
                        .getComputedStyle(trg[0], null)
                        .getPropertyValue('font-size')
                        .replace('px', '')
                let fontSize = 0
                trg[0].style.height =
                    trg[0].getBoundingClientRect().height + 'px'
                trg[0].addEventListener('keyup', () => {
                    val = trg[0].value
                    fontSize = window
                        .getComputedStyle(trg[0], null)
                        .getPropertyValue('font-size')
                    ruler.style.fontSize = fontSize
                    ruler.innerHTML = val
                    offsetWidth = ruler.offsetWidth
                    if (offsetWidth + 100 >= inputWidth) {
                        if (+fontSize.replace('px', '') > 16) {
                            trg[0].style.fontSize =
                                +fontSize.replace('px', '') - 2 + 'px'
                        }
                    } else if (offsetWidth - 100 < inputWidth) {
                        if (
                            +trg[0].style.fontSize.replace('px', '') <
                            initialFontSize
                        ) {
                            trg[0].style.fontSize =
                                +fontSize.replace('px', '') + 2 + 'px'
                        }
                    }
                })

                trg[0].dispatchEvent(new KeyboardEvent('keyup', { key: '' }))
            }
        }, 1000)
    }

    let npt = null
    if (!loaded) {
        npt = (
            <div class="thfInput thfLoadingBox">
                <div class="thfLoadingBoxWrapper"></div>
            </div>
        )
    } else {
        npt =
            type == 'password' ? (
                <div class={allClasses} style={{ position: 'relative' }}>
                    <input
                        name={name}
                        type={type}
                        class="btzForm-btzFormInput"
                        {...otherProps}
                    />
                    {/*<img src={icoEye} srcset={icoEyex2 + ' 2x'} alt='' class='btzForm-btzFormInput-btzEye' onclick={actions.switchPwd} />
                            <img src={icoEyeOn} srcset={icoEyeOnx2 + ' 2x'} alt='' class='btzForm-btzFormInput-btzEyeOn' onclick={actions.switchPwd} />*/}
                </div>
            ) : type === 'date' ? (
                <div
                    class={allClasses + ' btzInputDate'}
                    style={{ position: 'relative' }}
                >
                    <input
                        name={name}
                        type={'hidden'}
                        value={dateValueYear + dateValueMonth + dateValueDay}
                    />
                    <input
                        style={{ width: '55px' }}
                        name={name + '-day'}
                        type={'text'}
                        maxlength={'2'}
                        class="btzForm-btzFormInput"
                        value={dateValueDay}
                        placeholder={props.placeholderDay}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        onblur={(e) => {
                            e.target.value =
                                +e.target.value < 10
                                    ? '0' + +e.target.value
                                    : e.target.value
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = dateValueDay || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                    <p>/</p>
                    <input
                        style={{ width: '55px' }}
                        name={name + '-month'}
                        type={'text'}
                        maxlength={'2'}
                        class="btzForm-btzFormInput"
                        value={dateValueMonth}
                        placeholder={props.placeholderMonth}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        onblur={(e) => {
                            e.target.value =
                                +e.target.value < 10
                                    ? '0' + +e.target.value
                                    : e.target.value
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = dateValueMonth || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                    <p>/</p>
                    <input
                        name={name + '-year'}
                        type={'text'}
                        maxlength={'4'}
                        class="btzForm-btzFormInput"
                        value={dateValueYear}
                        placeholder={props.placeholderYear}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = dateValueYear || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                </div>
            ) : type === 'code' ? (
                <div
                    class={allClasses + ' btzInputCode'}
                    style={{ position: 'relative' }}
                >
                    <input
                        name={name}
                        type={'hidden'}
                        value={
                            codeNum1 +
                            codeNum2 +
                            codeNum3 +
                            codeNum4 +
                            codeNum5 +
                            codeNum6
                        }
                    />
                    <input
                        name={name + '-num-0'}
                        type={'text'}
                        maxlength={'1'}
                        class="btzForm-btzFormInput"
                        value={codeNum1}
                        placeholder={props.placeholderNum1}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = codeNum1 || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                    <input
                        name={name + '-num-1'}
                        type={'text'}
                        maxlength={'1'}
                        class="btzForm-btzFormInput"
                        value={codeNum2}
                        placeholder={props.placeholderNum2}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = codeNum2 || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                    <input
                        name={name + '-num-2'}
                        type={'text'}
                        maxlength={'1'}
                        class="btzForm-btzFormInput"
                        value={codeNum3}
                        placeholder={props.placeholderNum3}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = codeNum3 || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                    <input
                        name={name + '-num-3'}
                        type={'text'}
                        maxlength={'1'}
                        class="btzForm-btzFormInput"
                        value={codeNum4}
                        placeholder={props.placeholderNum4}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = codeNum4 || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                    <input
                        name={name + '-num-4'}
                        type={'text'}
                        maxlength={'1'}
                        class="btzForm-btzFormInput"
                        value={codeNum5}
                        placeholder={props.placeholderNum5}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = codeNum5 || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                    <input
                        name={name + '-num-5'}
                        type={'text'}
                        maxlength={'1'}
                        class="btzForm-btzFormInput"
                        value={codeNum6}
                        placeholder={props.placeholderNum6}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = codeNum6 || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                </div>
            ) : type === 'phone' ? (
                <div
                    class={allClasses + ' btzInputPhone'}
                    style={{ position: 'relative' }}
                >
                    <input
                        name={name}
                        type={'hidden'}
                        value={
                            phoneNum1 +
                            phoneNum2 +
                            phoneNum3 +
                            phoneNum4 +
                            phoneNum5
                        }
                    />
                    <input
                        name={name + '-num-0'}
                        type={'text'}
                        maxlength={'2'}
                        class="btzForm-btzFormInput"
                        value={phoneNum1}
                        placeholder={props.placeholderPhone1}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = phoneNum1 || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                    <input
                        name={name + '-num-1'}
                        type={'text'}
                        maxlength={'2'}
                        class="btzForm-btzFormInput"
                        value={phoneNum2}
                        placeholder={props.placeholderPhone2}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = phoneNum2 || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                    <input
                        name={name + '-num-2'}
                        type={'text'}
                        maxlength={'2'}
                        class="btzForm-btzFormInput"
                        value={phoneNum3}
                        placeholder={props.placeholderPhone3}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = phoneNum3 || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                    <input
                        name={name + '-num-3'}
                        type={'text'}
                        maxlength={'2'}
                        class="btzForm-btzFormInput"
                        value={phoneNum4}
                        placeholder={props.placeholderPhone4}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = phoneNum4 || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                    <input
                        name={name + '-num-4'}
                        type={'text'}
                        maxlength={'2'}
                        class="btzForm-btzFormInput"
                        value={phoneNum5}
                        placeholder={props.placeholderPhone5}
                        onfocus={(e) => {
                            e.target.setSelectionRange(0, e.target.value.length)
                            if (props.onfocuscb) {
                                props.onfocuscb(e)
                            }
                        }}
                        oninput={(e) => {
                            if (
                                !isNumeric(e.target.value) &&
                                e.target.value !== ''
                            ) {
                                e.target.value = phoneNum5 || ''
                                return false
                            }
                            if (props.validation) {
                                actions.updateIsInputValid({
                                    target: e.target,
                                    validationcb: props.validationcb,
                                })
                            }
                            actions.focusNextField(e)
                            if (props.oninputcb) {
                                props.oninputcb(e)
                            }
                        }}
                        {...otherProps}
                    />
                </div>
            ) : (
                <input
                    name={name}
                    type={type}
                    class={allClasses}
                    {...otherProps}
                />
            )
    }

    return (
        <div
            class={'btzForm-btzFormInputWrapper'}
            style={{ position: 'relative' }}
        >
            {npt}
            <div class="btzForm-btzFormInput-hr"></div>
        </div>
    )
}

const Listing = (props) => {
    const {
        name,
        listing = [],
        withsearch = false,
        classes,
        placeholder = '',
        ...otherProps
    } = props

    const allClasses = classMerge([
        'btzForm-btzFormInput btzForm-btzFormInputListing',
        classes,
    ])

    return (
        <div>
            {withsearch === true && (
                <input
                    type="text"
                    class="btzForm-btzFormDropdown"
                    placeholder={placeholder}
                    oninput={actions.updateListingFilter}
                />
            )}
            <div class="btzForm-btzListing">
                {Object.keys(listing).map(function (key) {
                    return (
                        <div
                            key={key}
                            class={
                                allClasses +
                                (props.value === key ? ' active' : '')
                            }
                        >
                            <input
                                id={key}
                                type="radio"
                                name={name}
                                value={key}
                                checked={props.value === key}
                                data-checked={props.value === key}
                            />
                            <label
                                for={key}
                                {...otherProps}
                                data-value={key}
                                style={{ cursor: 'pointer' }}
                            >
                                <p>{listing[key]}</p>
                                <div class="btzForm-btzFormInput-QcmPuce">
                                    <img
                                        src={icoValidQCM}
                                        srcset={`${icoValidQCMx2} 2x`}
                                        alt=""
                                    />
                                </div>
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const TextArea = (props) => {
    const { name, rows = '3', classes, ...otherProps } = props

    const allClasses = classMerge(['btzForm-btzFormInput', classes])

    return (
        <textarea name={name} rows={rows} class={allClasses} {...otherProps} />
    )
}

const CheckBox = (props, children) => {
    const {
        id,
        name,
        value,
        classes,
        placeholder,
        checked,
        custom = '',
        image = null,
        ...otherProps
    } = props

    const allClasses = classMerge([
        'btzForm-btzFormInput btzForm-btzCheckbox',
        classes,
    ])

    return (
        <div class="btzForm-btzFormInputWrapper">
            {image && (
                <input
                    id={id}
                    type="checkbox"
                    name={name}
                    checked={checked}
                    value={value}
                />
            )}
            <label for={id} class={allClasses} {...otherProps}>
                {image == null && (
                    <div>
                        {custom === '' ? (
                            <div>
                                <p>{placeholder}</p>
                                <input
                                    id={id}
                                    type="checkbox"
                                    name={name}
                                    checked={checked}
                                    value={value}
                                />
                            </div>
                        ) : (
                            <label class={custom}>
                                <input
                                    type="checkbox"
                                    name={name}
                                    value={value}
                                />
                                <span class="btzCheckmark">
                                    <img
                                        src={icoValidRadio}
                                        srcset={`${icoValidRadiox2} 2x`}
                                        alt=""
                                    />
                                </span>
                                <p
                                    style={{
                                        'vertical-align': 'middle',
                                        'display': 'inline-block',
                                        'margin': '0px',
                                    }}
                                >
                                    {placeholder}
                                </p>
                            </label>
                        )}
                    </div>
                )}
                {image && (
                    <div>
                        <div
                            class="btzForm-btzFormInput-btzCheckbox-image"
                            style={{
                                'display': 'table-cell',
                                'vertical-align': 'middle',
                                'background-image': 'url(' + image + ')',
                            }}
                        ></div>
                        <p>{placeholder}</p>
                    </div>
                )}
                <i class="form-icon"></i> {children}
            </label>
        </div>
    )
}

const Dropdown = (props, children) => {
    const {
        value,
        classes,
        list,
        fulllist,
        search,
        textedarea,
        placeholder,
        success,
        error,
        loaded = true,
        ...otherProps
    } = props

    const allClasses = classMerge([
        success && 'is-success',
        error && 'is-error',
        'btzForm-btzFormInput btzForm-btzFormInputWrapper-thfLabel',
        classes,
    ])
    let labelValue = value,
        labelKey = value
    if (textedarea === false) {
        labelValue =
            list.filter((f) => String(f.key) === String(value))[0] !== undefined
                ? list.filter((f) => String(f.key) === String(value))[0].label
                : ''
        labelKey =
            list.filter((f) => String(f.key) === String(value))[0] !== undefined
                ? list.filter((f) => String(f.key) === String(value))[0].key
                : ''
    }

    if (Array.isArray(labelKey)) {
        labelKey = labelKey.join(',')
    }

    if (!loaded) {
        return (
            <div class="thfInput thfLoadingBox">
                <div class="thfLoadingBoxWrapper"></div>
            </div>
        )
    } else {
        return (
            <div key={labelValue} class={'btzForm-btzFormInputWrapper'}>
                <div
                    tabindex="0"
                    class={
                        'btzForm-btzFormInputWrapper-thfDropDown ' +
                        (textedarea === true
                            ? 'btzForm-btzFormInputWrapper-thfTextedArea'
                            : '')
                    }
                >
                    {search === false ? (
                        <p
                            class={
                                allClasses +
                                ' ' +
                                (props.readonly ? 'readonly' : '')
                            }
                        >
                            {textedarea === true ? '' : labelValue}
                        </p>
                    ) : (
                        <input
                            name={props.name}
                            class={allClasses}
                            style={{ padding: '1px 15px' }}
                            value={textedarea === true ? '' : labelValue}
                            alt={labelKey}
                            oninput={actions.updateListingFilter}
                            {...otherProps}
                        />
                    )}
                    <div class="btzForm-btzFormInputWrapper-thfDropDownWrapper">
                        {list.map((item) => (
                            <dummy class="thfDropDownDisplay btzForm-btzFormInputListing">
                                <label for={item.label}>{t(item.label)}</label>
                                <input
                                    tabindex="1"
                                    class=""
                                    name={props.name}
                                    onclick={props.onclick || props.onclickcb}
                                    value={item.key}
                                    id={item.label}
                                />
                            </dummy>
                        ))}
                    </div>
                </div>
                {textedarea === true && (
                    <div
                        class={allClasses + ' btzForm-btzFormInputTextedArea'}
                        style="height: auto;"
                    >
                        {labelValue.map((item) => (
                            <dummy class="thfDropDownDisplay">
                                <label
                                    for={
                                        fulllist.filter(
                                            (f) =>
                                                String(f.key) === String(item)
                                        )[0].label
                                    }
                                >
                                    {t(
                                        fulllist.filter(
                                            (f) =>
                                                String(f.key) === String(item)
                                        )[0].label
                                    )}
                                </label>
                                <input
                                    tabindex="1"
                                    class=""
                                    name={props.name}
                                    onclick={props.onclick}
                                    value={
                                        fulllist.filter(
                                            (f) =>
                                                String(f.key) === String(item)
                                        )[0].key
                                    }
                                    id={
                                        fulllist.filter(
                                            (f) =>
                                                String(f.key) === String(item)
                                        )[0].label
                                    }
                                />
                            </dummy>
                        ))}
                    </div>
                )}
            </div>
        )
    }
}

const Radio = (props, children) => {
    const {
        id,
        name,
        placeholder,
        checked,
        classes,
        value,
        custom = '',
        image = null,
        imagex2 = null,
        imageSelected = null,
        imageSelectedx2 = null,
        ...otherProps
    } = props

    const allClasses = classMerge([
        'btzForm-btzFormInput btzForm-btzRadiobox',
        classes,
    ])

    return (
        <div class="btzForm-btzFormInputWrapper">
            {image && (
                <input
                    id={id}
                    type="radio"
                    name={name}
                    checked={checked}
                    value={value}
                />
            )}
            <label for={id} class={allClasses} {...otherProps}>
                {image == null && (
                    <div>
                        {custom === '' ? (
                            <div>
                                <p>{placeholder}</p>
                                <input
                                    id={id}
                                    type="radio"
                                    name={name}
                                    checked={checked}
                                    value={value}
                                />
                            </div>
                        ) : (
                            <label class={custom}>
                                <input type="radio" name={name} value={value} />
                                <span class="btzCheckmark">
                                    <img
                                        src={icoValidRadio}
                                        srcset={`${icoValidRadiox2} 2x`}
                                        alt=""
                                    />
                                </span>
                                <p
                                    style={{
                                        'vertical-align': 'middle',
                                        'display': 'inline-block',
                                        'margin': '0px',
                                    }}
                                >
                                    {placeholder}
                                </p>
                            </label>
                        )}
                    </div>
                )}
                {image && (
                    <div
                        style={{
                            'display': 'table-cell',
                            'vertical-align': 'middle',
                        }}
                    >
                        <img
                            src={image}
                            srcset={
                                (imagex2 !== null ? imagex2 : image) + ' 2x'
                            }
                            alt=""
                            class="btzForm-btzFormInput-btzRadio-image"
                        />
                        {imageSelected !== null && imageSelectedx2 !== null && (
                            <img
                                src={imageSelected}
                                srcset={
                                    (imageSelectedx2 !== null
                                        ? imageSelectedx2
                                        : imageSelected) + ' 2x'
                                }
                                alt=""
                                class="btzForm-btzFormInput-btzRadio-image btzForm-btzFormInput-btzRadio-image-selected"
                            />
                        )}
                        <p>{placeholder}</p>
                    </div>
                )}
                <i class="form-icon"></i> {children}
            </label>
        </div>
    )
}

const Switch = (props, children) => {
    const { name, classes, ...otherProps } = props

    const allClasses = classMerge(['form-switch', classes])

    return (
        <label class={allClasses}>
            <input type="checkbox" name={name} />
            <i class="form-icon"></i> {children}
        </label>
    )
}

Form.Listing = Listing
Form.Group = Group
Form.Label = Label
Form.Hint = Hint
Form.Input = Input
Form.TextArea = TextArea
Form.CheckBox = CheckBox
Form.Radio = Radio
Form.Switch = Switch
Form.Select = Select
Form.Dropdown = Dropdown
export { Form }
