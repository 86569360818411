require('@app/__tests__/setup')
const { isThereAnomaliesInCards } = require('.')

const cardsSet = [
    {
        id: '65aa4e4e09ac91aecf07ee82',
        name: 'OKCUREHADFSS-QUESTION01',
        forms: [
            {
                id: '995aad6c000efcd09e37acd77f0512fe',
                tag: 'OKCUREHADFSS_1',
            },
            {
                id: 'b1559d7f10bfc808ce688a1aa34431d0',
                tag: 'OKCUREHADFSS_1',
            },
            {
                id: '4d617496ff4614cdabb590417726bb38',
                tag: 'OKCUREHADFSS_1',
            },
            {
                id: '9083774faefaea13a5b5059c86710167',
                tag: 'OKCUREHADFSS_1',
            },
            {
                id: '1f844f49e9ad19931e111a5287a5c9ef',
                tag: 'OKCUREHADFSS_1',
            },
        ],
    },
    {
        id: '65aa4e4e09ac91aecf07ee83',
        name: 'OKCUREHADFSS-QUESTION02',
        forms: [
            {
                id: '50d8b3c85303de298ee983666853ff71',
                tag: 'OKCUREHADFSS_2',
            },
            {
                id: 'a64790a7540c92d6bd22f91583c126d0',
                tag: 'OKCUREHADFSS_2',
            },
            {
                id: '135857829d745b3958629bc458334aa6',
                tag: 'OKCUREHADFSS_2',
            },
            {
                id: 'c717fbd76035299955f3f184408d8890',
                tag: 'OKCUREHADFSS_2',
            },
            {
                id: '38495271f93826517a201bdf62773f57',
                tag: 'OKCUREHADFSS_2',
            },
        ],
    },
]

const cardsSetDuplicatedCardsId = cardsSet.concat({
    id: '65aa4e4e09ac91aecf07ee82',
    name: 'OKCUREHADFSS-QUESTION01A',
    forms: [
        {
            id: '995aad6c000efcd09e37acd77f0512feA',
            tag: 'OKCUREHADFSS_1',
        },
        {
            id: 'b1559d7f10bfc808ce688a1aa34431d0A',
            tag: 'OKCUREHADFSS_1',
        },
        {
            id: '4d617496ff4614cdabb590417726bb38A',
            tag: 'OKCUREHADFSS_1',
        },
        {
            id: '9083774faefaea13a5b5059c86710167A',
            tag: 'OKCUREHADFSS_1',
        },
        {
            id: '1f844f49e9ad19931e111a5287a5c9efA',
            tag: 'OKCUREHADFSS_1',
        },
    ],
})

const cardsSetDuplicatedCardsName = cardsSet.concat({
    id: '65aa4e4e09ac91aecf07ee82A',
    name: 'OKCUREHADFSS-QUESTION01',
    forms: [
        {
            id: '995aad6c000efcd09e37acd77f0512feA',
            tag: 'OKCUREHADFSS_1',
        },
        {
            id: 'b1559d7f10bfc808ce688a1aa34431d0A',
            tag: 'OKCUREHADFSS_1',
        },
        {
            id: '4d617496ff4614cdabb590417726bb38A',
            tag: 'OKCUREHADFSS_1',
        },
        {
            id: '9083774faefaea13a5b5059c86710167A',
            tag: 'OKCUREHADFSS_1',
        },
        {
            id: '1f844f49e9ad19931e111a5287a5c9efA',
            tag: 'OKCUREHADFSS_1',
        },
    ],
})

const cardsSetDuplicatedCardFormId = cardsSet.concat({
    id: '65aa4e4e09ac91aecf07ee82B',
    name: 'OKCUREHADFSS-QUESTION01',
    forms: [
        {
            id: '995aad6c000efcd09e37acd77f0512fe',
            tag: 'OKCUREHADFSS_1',
        },
        {
            id: 'b1559d7f10bfc808ce688a1aa34431d0B',
            tag: 'OKCUREHADFSS_1',
        },
        {
            id: '4d617496ff4614cdabb590417726bb38B',
            tag: 'OKCUREHADFSS_1',
        },
        {
            id: '9083774faefaea13a5b5059c86710167B',
            tag: 'OKCUREHADFSS_1',
        },
        {
            id: '1f844f49e9ad19931e111a5287a5c9efB',
            tag: 'OKCUREHADFSS_1',
        },
    ],
})

describe('isThereAnomaliesInCards', () => {
    test('should not find any anomalies', async () => {
        expect(isThereAnomaliesInCards(cardsSet)).toBe(false)
    })

    test('should find anomaly (about a duplicate card id)', async () => {
        expect(isThereAnomaliesInCards(cardsSetDuplicatedCardsId)).toBe(true)
    })

    test('should find anomaly (about a duplicate card name)', async () => {
        expect(isThereAnomaliesInCards(cardsSetDuplicatedCardsName)).toBe(true)
    })

    test('should find anomaly (about a duplicate card form id)', async () => {
        expect(isThereAnomaliesInCards(cardsSetDuplicatedCardFormId)).toBe(true)
    })
})
