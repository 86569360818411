require('@app/__tests__/setup')
const { getUnitFromType } = require('.')

describe('getUnitFromType', () => {
    test.each([
        ['kilogrammes', 'cryptedweight'],
        ['degrés celsius', 'cryptedtemperature'],
        ['', 'dumb'],
        ['', null],
        ['', undefined],
        ['', {}],
        ['', []],
        ['', false],
    ])('should return %s when type is %s', (expected, type) => {
        localStorage.setItem('locale', 'fr-FR')
        expect(getUnitFromType(type)).toBe(expected)
    })

    test.each([
        ['livres', 'cryptedweight'],
        ['degrés fahrenheit', 'cryptedtemperature'],
        ['', 'dumb'],
        ['', null],
        ['', undefined],
        ['', {}],
        ['', []],
        ['', false],
    ])(
        'should return %s when type is %s and local is en-US',
        (expected, type) => {
            localStorage.setItem('locale', 'en-US')
            expect(getUnitFromType(type)).toBe(expected)
        }
    )

    test.each([
        ['kilogrammes', 'cryptedweight'],
        ['degrés celsius', 'cryptedtemperature'],
        ['', 'dumb'],
        ['', null],
        ['', undefined],
        ['', {}],
        ['', []],
        ['', false],
    ])(
        'should return %s when type is %s and local is en-US',
        (expected, type) => {
            localStorage.setItem('locale', 'en-FR')
            expect(getUnitFromType(type)).toBe(expected)
        }
    )

    test.each([
        ['livres', 'cryptedweight'],
        ['degrés fahrenheit', 'cryptedtemperature'],
        ['', 'dumb'],
        ['', null],
        ['', undefined],
        ['', {}],
        ['', []],
        ['', false],
    ])(
        'should return %s when type is %s and local is en-US',
        (expected, type) => {
            localStorage.setItem('locale', 'fr-US')
            expect(getUnitFromType(type)).toBe(expected)
        }
    )

    test.each([
        ['livres', 'cryptedweight'],
        ['degrés celsius', 'cryptedtemperature'],
        ['', 'dumb'],
        ['', null],
        ['', undefined],
        ['', {}],
        ['', []],
        ['', false],
    ])(
        'should return %s when type is %s and local is en-US',
        (expected, type) => {
            localStorage.setItem('locale', 'en-GB')
            expect(getUnitFromType(type)).toBe(expected)
        }
    )
})
