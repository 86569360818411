import { t } from 'i18next';
import { h } from 'hyperapp';
import { Card, Button, Form, FormInput, Tile, Enter, Calendar, Modal } from '@app/elements';
import * as constants from '@app/constants';
import { BotFooterView } from '@app/layouts/botfooter';

import { ModalViewResetPWD } from './../../modals/modalView-resetPwd-default';

import icoInfo from '@app/img/ico/ico-login-info.png';
import icoInfox2 from '@app/img/ico/ico-login-info@2x.png';

import icoTopMobile from '@app/img/ico/mobile/ico-login-mobile.png';
import icoTopMobilex2 from '@app/img/ico/mobile/ico-login-mobile@2x.png';

const ViewWelcome = (props, actions) => (
    <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
        <dummy>
            <div class='thfProgressBarWrapper hide-xs'><div class='thfProgressBar' style='width: 30%'></div></div>
            <img class='show-xs' src={icoTopMobile} srcset={`${icoTopMobilex2} 2x`} alt='' />
            <Tile>
                <Tile.Content>
                    <div class='loginHeader'>
                        <p class='btzTitle btzXl'>{t('Bienvenue.', {ns: 'login'})}</p>
                        {props.customer === 'unicancer' ?
                            <p class='btzSubTitle'>{t('Merci pour votre participation à l’étude Curve. Pour compléter les questionnaires de l’étude, régulièrement, vous recevrez des notifications par SMS ou Email. Tous seront disponibles au bon moment dans votre espace Patient.', {ns: 'login'})}</p>
                            :
                            <p class='btzSubTitle'>{t('Cet outil est mis à votre disposition pour répondre aux questionnaires de votre équipe médicale. Vous retrouverez aussi, des fiches pour prévenir et agir en cas d’apparition de symptômes et enfin une bibliothèque de contenus pour approfondir vos connaissances sur votre pathologie.', {ns: 'login'})}</p>
                        }
                    </div>
                </Tile.Content>
            </Tile>

            <Form onsubmit={event => event.preventDefault()} autocomplete={'on'}>
                <button type='submit' disabled style='display: none' aria-hidden='true'></button>
                <Form.Group classes='btzForm-btzFooter'>
                    <Button primary active onclick={() => { props._setState({key: 'displayWelcome', value: false}); }}>
                        {t('Continuer', {ns: 'login'})}
                    </Button>
                </Form.Group>
            </Form>
        </dummy>
        <div class='show-xs'><BotFooterView customer={props.customer}></BotFooterView></div>
    </Enter>
);

export default ViewWelcome;