require('@app/__tests__/setup')
const { setLocaleInLocalStorage } = require('@app/core/languagesHandler')
const { dateTimeFormat } = require('.')

describe('dateTimeFormat', () => {
    test.each([
        [undefined, null, undefined],
        [undefined, undefined, undefined],
        [undefined, {}, undefined],
        [undefined, [], undefined],
        [undefined, 'dumb', undefined],
        [undefined, 0, undefined],
        ['01/12/2024', new Date('2024-12-01'), undefined],
        [
            '01/12/2024 23:11:01',
            new Date('2024-12-01 23:11:01'),
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            },
        ],
    ])('should return %s when input Date is %s', (expected, date, opt) => {
        setLocaleInLocalStorage('fr-FR')
        expect(dateTimeFormat(date, opt)).toBe(expected)
    })

    test.each([
        ['01/12/2024', new Date('2024-12-01'), undefined],
        [
            '01/12/2024 23:11:01',
            new Date('2024-12-01 23:11:01'),
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            },
        ],
    ])('should return %s when input Date is %s', (expected, date, opt) => {
        setLocaleInLocalStorage('fr-US')
        expect(dateTimeFormat(date, opt)).toBe(expected)
    })

    test.each([
        ['12/1/2024', new Date('2024-12-01'), undefined],
        [
            '12/01/2024, 11:11:01 PM',
            new Date('2024-12-01 23:11:01'),
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            },
        ],
    ])('should return %s when input Date is %s', (expected, date, opt) => {
        setLocaleInLocalStorage('en-US')
        expect(dateTimeFormat(date, opt)).toBe(expected)
    })

    test.each([
        ['12/1/2024', new Date('2024-12-01'), undefined],
        [
            '12/01/2024, 11:11:01 PM',
            new Date('2024-12-01 23:11:01'),
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            },
        ],
    ])('should return %s when input Date is %s', (expected, date, opt) => {
        setLocaleInLocalStorage('en-FR')
        expect(dateTimeFormat(date, opt)).toBe(expected)
    })

    test.each([
        ['1.12.2024', new Date('2024-12-01'), undefined],
        [
            '01.12.2024, 23:11:01',
            new Date('2024-12-01 23:11:01'),
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            },
        ],
    ])('should return %s when input Date is %s', (expected, date, opt) => {
        setLocaleInLocalStorage('de-DE') // not supported locale
        expect(dateTimeFormat(date, opt)).toBe(expected)
    })
})
