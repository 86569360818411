import { location } from '@hyperapp/router'
const withRouting =
    (opts = {}) =>
    (nextApp) =>
    (initialState, initialActions, initialView, initialRoot) => {
        const enhancedState = { ...initialState, ...location.state }
        const enhancedActions = { ...initialActions, ...location.actions }

        const enhancedApp = nextApp(
            enhancedState,
            enhancedActions,
            initialView,
            initialRoot
        )

        return enhancedApp
    }

export { withRouting }
