import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined } from '@app/core';
import { Card, Button, Form, FormInput, Tile, Enter, Modal, Convert } from '@app/elements';
import ConsentLayout from '@app/layouts/register/layout';

import ModalViewCGU from './documents/cgu';
import ModalViewPDC from './documents/pdc';

import * as constants from '@app/constants';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div key={'consent'} class='root-wrapper'>
                {state.agreementToAprove &&
                    <ConsentLayout page='consent-root layout-fullheight' customer={props.customer}>
                        <div class='central-wrapper'>
                            <div class='consent-central'>
                                <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'}>
                                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                                        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                            <dummy>
                                                {state.refused &&
                                                    <div class='consentRefused'>
                                                        <p class=''>{t('En refusant le cadre réglementaire, vous ne pouvez pas utiliser le service.')}<br/>{t('Pour toutes questions, contactez') + ' ' + state.support}</p>
                                                    </div>
                                                }
                                                <div class='thfProgressBarWrapper'><div class='thfProgressBar' style='width: 70%'></div></div>
                                                <Tile>
                                                    <Tile.Content>
                                                        <div class='consentHeader'>
                                                            <p class='btzTitle btzXl'>{state.title}</p>
                                                            <p class='btzSubTitle' innerHTML={<Convert separator={''} is_xhtml={true} type='nl2br'>{state.description}</Convert>}></p>
                                                        </div>
                                                    </Tile.Content>
                                                </Tile>

                                                <Form onsubmit={event => event.preventDefault()} autocomplete={'on'}>
                                                    <button type='submit' disabled style='display: none' aria-hidden='true'></button>

                                                    <Form.Group classes='btzForm-btzFooter'>
                                                        <Button flat cancel onclick={() => { actions.onAcceptAgreement(false); actions.onCancel(); }}>
                                                            {t('Je refuse')}
                                                        </Button>
                                                        <Button flat  active onclick={() => { actions.onAcceptAgreement(true); }}>
                                                            {t('J\'accepte')}
                                                        </Button>
                                                    </Form.Group>
                                                </Form>
                                            </dummy>
                                        </Enter>
                                    </Card.Header>
                                </Card>
                            </div>
                        </div>
                    </ConsentLayout>
                }
            </div>
        );
        return view;
    }
}