import { t } from 'i18next';
import { h } from 'hyperapp';
import { Component } from '@app/utils';

import { Enter, Button } from '@app/elements';
import * as constants from '@app/constants';

import icoClose from '@app/img/ico/ico-close.png';
import icoClosex2 from '@app/img/ico/ico-close@2x.png';

import './index.scss';

const state = {
    //
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        //
    },
}

const view = (state, actions) => (props, children) => (
    <div key={'modal'} class={'responsiveModal'}>
        <div class={'responsiveModalWrapper'}>
            <div class='responsiveModalClose' onclick={props.closeModal}></div>
            <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                {children}
            </Enter>
            <div class='responsiveModalFooter'>
                <Button primary active onclick={props.closeModal}>
                    {t('Fermer', {ns: 'generals'})}
                </Button>
            </div>
        </div>
    </div>
);

export default Component(state, actions, view, 'responsivemodallayout');