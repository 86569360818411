import { t } from 'i18next'
import { h } from 'hyperapp'
import { allRoutes, addCSRFToken } from '@app/core'
import { Link, Form, FormInput } from '@app/elements'
import * as constants from '@app/constants'

import icoAccount from '@app/img/ico/left-menu/ico-account.png'
import icoAccountx2 from '@app/img/ico/left-menu/ico-account@2x.png'
import icoAccountBlue from '@app/img/ico/left-menu/ico-account-active.png'
import icoAccountBluex2 from '@app/img/ico/left-menu/ico-account-active@2x.png'

import icoLogout from '@app/img/ico/left-menu/ico-logout.png'
import icoLogoutx2 from '@app/img/ico/left-menu/ico-logout@2x.png'

import icoMentions from '@app/img/ico/left-menu/ico-mentions.png'
import icoMentionsx2 from '@app/img/ico/left-menu/ico-mentions@2x.png'
import icoMentionsBlue from '@app/img/ico/left-menu/ico-mentions-active.png'
import icoMentionsBluex2 from '@app/img/ico/left-menu/ico-mentions-active@2x.png'

import icoHelp from '@app/img/ico/left-menu/ico-help.png'
import icoHelpx2 from '@app/img/ico/left-menu/ico-help@2x.png'
import icoHelpBlue from '@app/img/ico/left-menu/ico-help-active.png'
import icoHelpBluex2 from '@app/img/ico/left-menu/ico-help-active@2x.png'
import { languages } from '@app/core/languagesHandler'

export default {
    initView: function () {
        let view = (state, actions) => (props, children) => (
            <div class={'usermenu-displayed-' + props.display}>
                {props.display === 'login' && (
                    <div class={'usermenu-userlanguages'}>
                        <Form onsubmit={(event) => event.preventDefault()}>
                            <Form.Group>
                                <FormInput
                                    key="input-language"
                                    type="dropdown"
                                    id="language"
                                    name="language"
                                    value={state.currentLanguage}
                                    list={languages
                                        .filter(
                                            // TO-REMOVE in mep 7 (when es, de, it will be added)
                                            (language) =>
                                                language.availableInLanguePicker
                                        )
                                        .map((m) => {
                                            return {
                                                label: t(m.label, {
                                                    ns: 'generals',
                                                }),
                                                key: m.key,
                                                img: m.img,
                                            }
                                        })}
                                    oninputcb={(input) => {
                                        const { values } = input
                                        actions.updateLanguage(values)
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                )}
                {props.display !== 'login' && (
                    <ul class="usermenu-bottom">
                        <li
                            onclick={() =>
                                (window.location.href = addCSRFToken(
                                    allRoutes['private.account'].pathname
                                ))
                            }
                            class={props.page == 'account' ? 'active' : ''}
                        >
                            <div>
                                {props.page == 'account' ? (
                                    <img
                                        class="activated"
                                        src={icoAccountBlue}
                                        srcset={`${icoAccountBluex2} 2x`}
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        class="activated"
                                        src={icoAccount}
                                        srcset={`${icoAccountx2} 2x`}
                                        alt=""
                                    />
                                )}
                            </div>
                            <Link>{t('Votre compte')}</Link>
                        </li>
                        <li
                            onclick={() =>
                                (window.location.href = addCSRFToken(
                                    allRoutes['private.help'].pathname
                                ))
                            }
                            class={props.page == 'help' ? 'active' : ''}
                        >
                            <div>
                                {props.page == 'help' ? (
                                    <img
                                        class="activated"
                                        src={icoHelpBlue}
                                        srcset={`${icoHelpBluex2} 2x`}
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        class="activated"
                                        src={icoHelp}
                                        srcset={`${icoHelpx2} 2x`}
                                        alt=""
                                    />
                                )}
                            </div>
                            <Link>{t('Aide')}</Link>
                        </li>
                        <li
                            onclick={() =>
                                (window.location.href = addCSRFToken(
                                    allRoutes['private.mentions'].pathname
                                ))
                            }
                            class={props.page == 'mentions' ? 'active' : ''}
                        >
                            <div>
                                {props.page == 'mentions' ? (
                                    <img
                                        class="activated"
                                        src={icoMentionsBlue}
                                        srcset={`${icoMentionsBluex2} 2x`}
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        class="activated"
                                        src={icoMentions}
                                        srcset={`${icoMentionsx2} 2x`}
                                        alt=""
                                    />
                                )}
                            </div>
                            <Link>{t('Mentions Légales')}</Link>
                        </li>
                        <li onclick={actions.deconnectUser}>
                            <div>
                                <img
                                    class="activated"
                                    src={icoLogout}
                                    srcset={`${icoLogoutx2} 2x`}
                                    alt=""
                                />
                            </div>
                            <Link>{t('Déconnexion')}</Link>
                        </li>
                    </ul>
                )}
            </div>
        )
        return view
    },
}
