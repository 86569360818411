import { dynLoad, isDefined, allRoutes } from '@app/core'
import { t } from 'i18next'
import { h } from 'hyperapp'

import {
    Balloon,
    Button,
    Form,
    Tile,
    Counter,
    DoubleInput,
    Convert,
    Modal,
} from '@app/elements'
import './../index.scss'

import gifLoader from '@app/img/loader.gif'
import OnboardingLayout from '@app/layouts/onboarding/layout'
import { ModalConsentHeal } from './modals/modalView-consentHeal-default'

// import icoInfo from '@app/img/ico/ico-ask-white.png';
// import icoInfox2 from '@app/img/ico/ico-ask-white@2x.png';

export const img = dynLoad('img', 'Onboarding/custo')
const ONBOARDING_IMG = img.ONBOARDING_IMG

import * as constants from '@app/constants'

import icoBackGrey from '@app/img/ico/ico-back-grey.png'
import icoBackGreyx2 from '@app/img/ico/ico-back-grey@2x.png'
import bgOnboarding from '@app/img/bg/bg-onboarding@2x.png'
import { retrieveEmergencyPhoneByCountry } from '@app/core/tools/retrieveEmergencyPhoneByCountry'
import { trimLeadingAndEndingBreakline } from '@app/core/tools/trimLeadingAndEndingBreakline'

export default {
    initView: function () {
        let view = (state, actions) => (props, children) => (
            <div
                id="autoScrollMarker"
                key={state.loaded}
                data-formtype={state.current?.form?.type}
                class={'specificFullHeightMobile ' + state.customDisplay}
                style={{
                    'background-image':
                        state.customDisplay === 'popup'
                            ? 'none'
                            : 'url(' + bgOnboarding + ')',
                    'background-color':
                        state.customDisplay === 'popup' ? 'white' : '""',
                }}
            >
                {state.current && state.current.form && !state.onboardingEnd ? (
                    <OnboardingLayout
                        page="onboarding"
                        theme={state.current.theme}
                        customer={props.customer}
                        removeConfirmQuitEvent={actions.removeConfirmQuitEvent}
                    >
                        <div
                            class="specificFullHeightMobile"
                            style={{ position: 'relative' }}
                        >
                            {state.idx > 0 && (
                                <img
                                    class="onboardingBackButton hide-xs"
                                    onclick={(e) => {
                                        actions.previousCard()
                                    }}
                                    style="position: absolute; top: 5px; cursor: pointer;left: 0;"
                                    src={icoBackGrey}
                                    srcset={`${icoBackGreyx2} 2x`}
                                    alt=""
                                />
                            )}
                            <Tile style={{ 'text-align': 'left' }}>
                                {state.mobileOnboarding === true ? (
                                    <div
                                        class="btzTitle-btzImage btzTitle-btzImage-absolute"
                                        style={{ 'max-width': '350px' }}
                                    >
                                        {state.idx > 0 && (
                                            <img
                                                class="onboardingBackButton show-xs"
                                                onclick={(e) => {
                                                    actions.previousCard()
                                                }}
                                                style="position: absolute; top: 26px; left: 35x; cursor: pointer;max-width: 15px;pointer-events: all;"
                                                src={icoBackGrey}
                                                srcset={`${icoBackGreyx2} 2x`}
                                                alt=""
                                            />
                                        )}
                                        {state.current.form && (
                                            <dummy>
                                                <p
                                                    key={
                                                        'index-' +
                                                        state.current.form.id +
                                                        '-' +
                                                        state.current.numCard
                                                    }
                                                    class="btzTopTitle"
                                                >
                                                    <font>
                                                        {state.current.numCard}
                                                    </font>
                                                    {' / ' + state.totalCards}
                                                </p>
                                                <div class="thfProgressBarWrapper">
                                                    <div
                                                        class="thfProgressBar"
                                                        style={{
                                                            width:
                                                                (100 /
                                                                    state.totalCards) *
                                                                    String(
                                                                        state
                                                                            .current
                                                                            .numCard
                                                                    ).split(
                                                                        '.'
                                                                    )[0] +
                                                                '%',
                                                        }}
                                                    ></div>
                                                </div>
                                                <p
                                                    key={
                                                        'question-' +
                                                        state.current.form.id +
                                                        '-' +
                                                        state.current.numCard
                                                    }
                                                    class="btzTitle btzXl"
                                                >
                                                    {state.current.question}
                                                </p>
                                            </dummy>
                                        )}
                                        <p
                                            class={
                                                'btzSubTitle ' +
                                                (state.error !== ''
                                                    ? 'error'
                                                    : 'info')
                                            }
                                        >
                                            <dummy
                                                innerHTML={
                                                    <Convert
                                                        separator={''}
                                                        is_xhtml={true}
                                                        type="nl2br"
                                                    >
                                                        {state.error !== ''
                                                            ? state.error
                                                            : state.current
                                                                  .help}
                                                    </Convert>
                                                }
                                            ></dummy>
                                        </p>
                                    </div>
                                ) : (
                                    <div
                                        class="btzTitle-btzImage btzTitle-btzImage-absolute"
                                        style={{ 'max-width': '350px' }}
                                    >
                                        <p
                                            class="btzTitle btzXl"
                                            style={{
                                                'margin-left': '80px',
                                            }}
                                        >
                                            {state.current.question}
                                        </p>
                                    </div>
                                )}
                                <Tile.Content
                                    classes="btzLeftHrArrowedOnboarding touchScrollingSafari"
                                    style={{ 'text-align': 'center' }}
                                >
                                    {state.current.form && (
                                        <div
                                            key={
                                                'form-' +
                                                state.current.form.id +
                                                '-' +
                                                state.current.numCard
                                            }
                                            class={
                                                'form-' +
                                                state.current.form.type +
                                                ' form-id-' +
                                                state.current.form.id +
                                                '-' +
                                                state.current.numCard
                                            }
                                        >
                                            {state.current.form.type ==
                                                'slider' && (
                                                <div
                                                    class="btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-slider"
                                                    style={{
                                                        'max-width': '425px',
                                                    }}
                                                >
                                                    {state.current.form.datas.map(
                                                        (item) => (
                                                            <div
                                                                onclick={
                                                                    actions.selectThisAnswer
                                                                }
                                                                data-id={
                                                                    state
                                                                        .current
                                                                        .form.id
                                                                }
                                                                data-tag={
                                                                    state
                                                                        .current
                                                                        .form
                                                                        .tag
                                                                }
                                                                data-kind="slider"
                                                                data-value={
                                                                    item.value
                                                                }
                                                                class="btzOnBoarding-btzButton btzUnselectable btzOnBoarding-btzButton-btzNumber"
                                                            >
                                                                {item.name}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                            {(state.current.form.type ==
                                                'number' ||
                                                state.current.form.type ==
                                                    'counter' ||
                                                state.current.form.type ==
                                                    'statcounter') && (
                                                <div class="btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number">
                                                    <Counter
                                                        key={
                                                            state.current.form
                                                                .id
                                                        }
                                                        type={state.current.form.type.toLowerCase()}
                                                        mobileOnboarding={
                                                            state.mobileOnboarding
                                                        }
                                                        class="btzCurrentEvaluationMarker"
                                                        floating={
                                                            (isDefined(
                                                                state.current
                                                                    .stepRanges[0]
                                                                    .datas[0]
                                                            ) &&
                                                                isDefined(
                                                                    state
                                                                        .current
                                                                        .stepRanges[0]
                                                                        .datas[0]
                                                                        .step
                                                                )) ||
                                                            isDefined(
                                                                state.current
                                                                    .stepRanges[0]
                                                                    .step
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        min={
                                                            isDefined(
                                                                state.current
                                                                    .stepRanges[0]
                                                                    .datas[0]
                                                            )
                                                                ? state.current
                                                                      .stepRanges[0]
                                                                      .datas[0]
                                                                      .min
                                                                : state.current
                                                                      .stepRanges[0]
                                                                      .min
                                                        }
                                                        max={
                                                            isDefined(
                                                                state.current
                                                                    .stepRanges[0]
                                                                    .datas[0]
                                                            )
                                                                ? state.current
                                                                      .stepRanges[0]
                                                                      .datas[0]
                                                                      .max
                                                                : state.current
                                                                      .stepRanges[0]
                                                                      .max
                                                        }
                                                        default={
                                                            state.defaultValue ||
                                                            (isDefined(
                                                                state.current
                                                                    .stepRanges[0]
                                                                    .datas[0]
                                                            )
                                                                ? state.current
                                                                      .stepRanges[0]
                                                                      .datas[0]
                                                                      .default
                                                                : state.current
                                                                      .stepRanges[0]
                                                                      .default)
                                                        }
                                                        step={
                                                            isDefined(
                                                                state.current
                                                                    .stepRanges[0]
                                                                    .datas[0]
                                                            )
                                                                ? state.current
                                                                      .stepRanges[0]
                                                                      .datas[0]
                                                                      .step
                                                                : state.current
                                                                      .stepRanges[0]
                                                                      .step
                                                        }
                                                        did={
                                                            state.current.form
                                                                .id
                                                        }
                                                        kind="number"
                                                        tag={
                                                            state.current.form
                                                                .tag
                                                        }
                                                        label={t(
                                                            state.current.form
                                                                .label
                                                        )}
                                                        initialCount={
                                                            state.specialInitialCount
                                                        }
                                                        isMobile={
                                                            state.mobileOnboarding
                                                        }
                                                        onfocus={() => {
                                                            actions.setError('')
                                                        }}
                                                    ></Counter>
                                                </div>
                                            )}
                                            {[
                                                'cryptedweight',
                                                'cryptedtemperature',
                                                'heartbeat',
                                            ].indexOf(
                                                state.current.form.type.toLowerCase()
                                            ) > -1 && (
                                                <div class="btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number">
                                                    <Counter
                                                        key={
                                                            state.current.form
                                                                .id
                                                        }
                                                        type={state.current.form.type.toLowerCase()}
                                                        mobileOnboarding={
                                                            state.mobileOnboarding
                                                        }
                                                        class="btzCurrentEvaluationMarkerParent btzCurrentEvaluationMarker"
                                                        floating={
                                                            state.current.form.type.toLowerCase() ===
                                                            'heartbeat'
                                                                ? false
                                                                : true
                                                        }
                                                        defaultDecimal={1}
                                                        min={
                                                            isDefined(
                                                                state.current
                                                                    .stepRanges[0]
                                                                    .datas[0]
                                                            )
                                                                ? state.current
                                                                      .stepRanges[0]
                                                                      .datas[0]
                                                                      .min
                                                                : 0
                                                        }
                                                        max={
                                                            isDefined(
                                                                state.current
                                                                    .stepRanges[0]
                                                                    .datas[0]
                                                            )
                                                                ? state.current
                                                                      .stepRanges[0]
                                                                      .datas[0]
                                                                      .max
                                                                : 5
                                                        }
                                                        did={
                                                            state.current.form
                                                                .id
                                                        }
                                                        kind="number"
                                                        tag={
                                                            isDefined(
                                                                constants.custo.EVAL_TYPES_MEDICAL.filter(
                                                                    (f) =>
                                                                        f.key.toLowerCase() ===
                                                                        state.current.form.type.toLowerCase()
                                                                )[0]
                                                            )
                                                                ? constants.custo.EVAL_TYPES_MEDICAL.filter(
                                                                      (f) =>
                                                                          f.key.toLowerCase() ===
                                                                          state.current.form.type.toLowerCase()
                                                                  )[0].tag[0]
                                                                : ''
                                                        }
                                                        label={t(
                                                            state.current.form
                                                                .label
                                                        )}
                                                        default={
                                                            state.defaultValue ||
                                                            state.current
                                                                .stepRanges[0]
                                                                .datas[0]
                                                                .default
                                                        }
                                                        initialCount={
                                                            state.current
                                                                .stepRanges[0]
                                                                .datas[0]
                                                                .default
                                                        }
                                                        step={
                                                            isDefined(
                                                                state.current
                                                                    .stepRanges[0]
                                                                    .datas[0]
                                                            )
                                                                ? state.current
                                                                      .stepRanges[0]
                                                                      .datas[0]
                                                                      .step
                                                                : state.current
                                                                      .stepRanges[0]
                                                                      .step
                                                        }
                                                        isMobile={
                                                            state.mobileOnboarding
                                                        }
                                                        onfocus={() => {
                                                            actions.setError('')
                                                        }}
                                                    ></Counter>
                                                    {state.current.unit ? (
                                                        <div class="btzOnboarding-subUnit">
                                                            {state.current.unit}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                            {state.current.form.type.toLowerCase() ==
                                                'bloodpressure' && (
                                                <div class="btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-number">
                                                    <DoubleInput
                                                        key={
                                                            state.current.form
                                                                .id
                                                        }
                                                        mobileOnboarding={
                                                            state.mobileOnboarding
                                                        }
                                                        class="btzCurrentEvaluationMarkerParent"
                                                        floating={false}
                                                        min={{
                                                            first: state.current
                                                                .stepRanges[0]
                                                                .datas[0].min,
                                                            second: state
                                                                .current
                                                                .stepRanges[1]
                                                                .datas[0].min,
                                                        }}
                                                        max={{
                                                            first: state.current
                                                                .stepRanges[0]
                                                                .datas[0].max,
                                                            second: state
                                                                .current
                                                                .stepRanges[1]
                                                                .datas[0].max,
                                                        }}
                                                        did={
                                                            state.current.form
                                                                .id
                                                        }
                                                        kind="number"
                                                        tag={{
                                                            first: 'BLOODPRESSURE_SYSTOLIC',
                                                            second: 'BLOODPRESSURE_DIASTOLIC',
                                                        }}
                                                        label={t(
                                                            state.current.form
                                                                .label
                                                        )}
                                                        default={{
                                                            first: state.current
                                                                .stepRanges[0]
                                                                .datas[0]
                                                                .default,
                                                            second: state
                                                                .current
                                                                .stepRanges[1]
                                                                .datas[0]
                                                                .default,
                                                        }}
                                                        initialCount={{
                                                            first: state.current
                                                                .stepRanges[0]
                                                                .datas[0]
                                                                .default,
                                                            second: state
                                                                .current
                                                                .stepRanges[1]
                                                                .datas[0]
                                                                .default,
                                                        }}
                                                        isMobile={
                                                            state.mobileOnboarding
                                                        }
                                                        onfocus={() => {
                                                            actions.setError('')
                                                        }}
                                                    ></DoubleInput>
                                                </div>
                                            )}
                                            {state.current.form.type ==
                                                'textarea' && (
                                                <div class="btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-textarea">
                                                    <textarea
                                                        oncreate={(el) => {
                                                            if (
                                                                state.mobileOnboarding &&
                                                                el
                                                            ) {
                                                                el.click()
                                                                el.focus()
                                                            }
                                                            el.value = ''
                                                        }}
                                                        key={
                                                            state.current.form
                                                                .id
                                                        }
                                                        class="btzCurrentEvaluationMarker"
                                                        data-id={
                                                            state.current.form
                                                                .id
                                                        }
                                                        data-tag={
                                                            state.current.form
                                                                .tag
                                                        }
                                                        placeholder={t(
                                                            'Expliquez ici avec les mots de votre choix',
                                                            {
                                                                ns: 'onboarding',
                                                            }
                                                        )}
                                                        oninput={(el) => {
                                                            const currentValue =
                                                                trimLeadingAndEndingBreakline(
                                                                    el.target
                                                                        .value
                                                                )
                                                            if (
                                                                currentValue !==
                                                                ''
                                                            ) {
                                                                actions.switchValidationActivation(
                                                                    true
                                                                )
                                                            } else {
                                                                actions.switchValidationActivation(
                                                                    false
                                                                )
                                                            }
                                                        }}
                                                        onfocus={() => {
                                                            actions.setError('')
                                                        }}
                                                    >
                                                        {state.defaultValue ||
                                                            ''}
                                                    </textarea>
                                                </div>
                                            )}
                                            {state.current.form.type ==
                                                'qcm' && (
                                                <div
                                                    class="btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-qcm"
                                                    style={{
                                                        'margin-left': '48px',
                                                    }}
                                                >
                                                    {state.current.form.datas.map(
                                                        (item) => (
                                                            <div
                                                                class=""
                                                                oncreate={
                                                                    actions.updateButtonHeight
                                                                }
                                                                style={{
                                                                    'min-height':
                                                                        'auto',
                                                                }}
                                                            >
                                                                {item
                                                                    .datas?.[0] !==
                                                                    undefined && (
                                                                    <div
                                                                        onclick={
                                                                            actions.selectThisAnswer
                                                                        }
                                                                        data-id={
                                                                            state
                                                                                .current
                                                                                .form
                                                                                .id
                                                                        }
                                                                        data-tag={
                                                                            item.tag
                                                                        }
                                                                        data-kind="qcm"
                                                                        data-value={
                                                                            item
                                                                                .datas?.[0]
                                                                                .value
                                                                        }
                                                                        class="btzOnBoarding-btzButton btzOnBoarding-btzQcm-btzLabel"
                                                                    >
                                                                        <div class="btzOnboarding-btzButtonsWrapper-Label">
                                                                            <p
                                                                                style={{
                                                                                    display:
                                                                                        'table',
                                                                                    height: 'auto',
                                                                                }}
                                                                            >
                                                                                <font
                                                                                    style={{
                                                                                        'display':
                                                                                            'table-cell',
                                                                                        'vertical-align':
                                                                                            'middle',
                                                                                        'text-align':
                                                                                            'left',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item
                                                                                            .datas?.[0]
                                                                                            ?.name
                                                                                    }
                                                                                </font>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                            {(state.current.form.type ==
                                                'button' ||
                                                state.current.form.type.toLowerCase() ==
                                                    'yesno') && (
                                                <div
                                                    class={
                                                        'btzOnboarding-btzButtonsWrapper btzOnboarding-btzButtonsWrapper-button ' +
                                                        state.current.name
                                                    }
                                                    data-length={
                                                        state.current.form.datas
                                                            .length
                                                    }
                                                >
                                                    {state.current.form.datas.map(
                                                        (item) => (
                                                            <dummy>
                                                                {item
                                                                    ?.datas?.[0] !==
                                                                    undefined && (
                                                                    <div
                                                                        onclick={
                                                                            actions.selectThisAnswer
                                                                        }
                                                                        oncreate={
                                                                            actions.updateButtonHeight
                                                                        }
                                                                        data-id={
                                                                            state
                                                                                .current
                                                                                .form
                                                                                .id
                                                                        }
                                                                        data-tag={
                                                                            state
                                                                                .current
                                                                                .form
                                                                                .tag
                                                                        }
                                                                        data-kind="button"
                                                                        data-value={
                                                                            item
                                                                                ?.datas?.[0]
                                                                                .value
                                                                        }
                                                                        class={
                                                                            'btzOnBoarding-btzButton btzOnBoarding-btzSlider-btzLabel ' +
                                                                            (item
                                                                                ?.datas?.[0]
                                                                                ?.value ===
                                                                            state.defaultValue
                                                                                ? 'btzCurrentEvaluationMarker active'
                                                                                : '')
                                                                        }
                                                                        style={{
                                                                            'min-height':
                                                                                'auto',
                                                                        }}
                                                                    >
                                                                        <p
                                                                            class="btzUnselectable"
                                                                            style={{
                                                                                padding:
                                                                                    '10px 0',
                                                                            }}
                                                                        >
                                                                            {
                                                                                item
                                                                                    ?.datas?.[0]
                                                                                    ?.name
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </dummy>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Tile.Content>
                            </Tile>

                            <Form
                                onsubmit={() => null}
                                style={{ 'min-height': 0 }}
                            >
                                <Form.Group
                                    classes={
                                        'btzForm-btzFooter ' +
                                        (state.current.skippableEval === true
                                            ? 'btzForm-btzFooter-withSkippable'
                                            : '')
                                    }
                                >
                                    {state.current.skippableEval === true && (
                                        <Button
                                            primary
                                            small
                                            onclick={() => {
                                                actions.nextCard(true)
                                            }}
                                        >
                                            {t('Passer', {
                                                ns: 'onboarding',
                                            })}
                                        </Button>
                                    )}
                                    <Button
                                        primary
                                        active={state.activeValidation}
                                        loading={state.formIsSubmitting}
                                        onclick={() => {
                                            actions.nextCard(false)
                                        }}
                                    >
                                        {t('Continuer', {
                                            ns: 'onboarding',
                                        })}
                                    </Button>
                                </Form.Group>
                            </Form>
                        </div>
                    </OnboardingLayout>
                ) : (
                    <OnboardingLayout
                        page="onboardingdone"
                        customer={props.customer}
                    >
                        {state.onboardingEnd && (
                            <div
                                style={{ position: 'relative' }}
                                class={'onboardingdone-step'}
                            >
                                <dummy>
                                    <Tile>
                                        <Tile.Content>
                                            <p
                                                class="btzTitle btzXl"
                                                style={{
                                                    'margin-bottom': '0',
                                                }}
                                            >
                                                {t('Merci.', {
                                                    ns: 'onboarding',
                                                })}
                                            </p>
                                            <p
                                                class="btzSubTitle btzXl"
                                                style={{
                                                    'margin-bottom': '0',
                                                }}
                                            >
                                                {t(
                                                    "Vos réponses sont en cours d'enregistrement.",
                                                    { ns: 'onboarding' }
                                                )}
                                            </p>
                                            <img
                                                class=""
                                                src={gifLoader}
                                                srcset={`${gifLoader} 2x`}
                                                alt=""
                                            />
                                            <p
                                                class="btzSubTitle"
                                                style={{
                                                    'margin-bottom': '0',
                                                }}
                                            >
                                                {t('Veuillez patienter...', {
                                                    ns: 'onboarding',
                                                })}
                                            </p>
                                            <p class="text-call-emergency">
                                                {t('globals.emergency.phone', {
                                                    ns: 'generals',
                                                    emergencyPhone:
                                                        retrieveEmergencyPhoneByCountry(),
                                                })}
                                            </p>
                                        </Tile.Content>
                                    </Tile>
                                </dummy>
                            </div>
                        )}
                    </OnboardingLayout>
                )}
                {state.healConsentDisplayed && (
                    <div class="ModalHealConsent">
                        <Modal
                            key={'modal-heal-consent'}
                            cancelAction={() => {
                                actions.closeHealConsent()
                            }}
                            modalDisplayed={true}
                            content={
                                <ModalConsentHeal
                                    {...state}
                                    _retrieveInput={actions._retrieveInput}
                                    onAcceptHealConsent={
                                        actions.onAcceptHealConsent
                                    }
                                    closeModal={() => {
                                        actions.closeHealConsent()
                                    }}
                                ></ModalConsentHeal>
                            }
                        ></Modal>
                    </div>
                )}
            </div>
        )
        return view
    },
}
