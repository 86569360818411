import { metricPoundsToKilogramFormula } from '../..'
import { generatePower } from '../../../power'

const metricPoundsToKilogram = (metric, decimal) => {
    if (metric && !Array.isArray(metric) && !isNaN(+metric)) {
        const operation = !decimal || decimal <= 1 ? 'floor' : 'round'
        const power = generatePower(decimal) ?? 1
        const calculation = metricPoundsToKilogramFormula(metric) * power
        if (!decimal) {
            return String(calculation)
        }
        return String(parseFloat((calculation / power).toFixed(decimal)))
    }
    return undefined
}
export { metricPoundsToKilogram }
