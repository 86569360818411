import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { APP_API_URL } = settings

function getTheraflowUserById(id) {
    return queueQueries(`${APP_API_URL}/theraflow/users/${id}/`, 'GET')
}
function blockTheraflowUser(id) {
    return queueQueries(`${APP_API_URL}/theraflow/users/${id}/block/`, 'GET')
}
function searchTheraflowUser(page = 0, sorts = {}, searchval = '') {
    return queueQueries(
        `${APP_API_URL}/theraflow/users/search/${page}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { search: searchval, ...sorts }
    )
}

function putUser(id, data) {
    return queueQueries(
        `${APP_API_URL}/user/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function isEmailAvailable(email) {
    return queueQueries(
        `${APP_API_URL}/user/checkemail/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { email: email }
    )
}

function resiliateUser(id) {
    return queueQueries(`${APP_API_URL}/admin/users/${id}/resiliate/`, 'POST')
}

function createTheraflowUser(data) {
    return queueQueries(
        `${APP_API_URL}/theraflow/users/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function updateTheraflowUser(id, data) {
    return queueQueries(
        `${APP_API_URL}/theraflow/users/${id}/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function checkPatientId(id) {
    return queueQueries(
        `${APP_API_URL}/theraflow/users/check-hospital-id/${id}/`,
        'GET'
    )
}

function getQrCode(id) {
    return queueQueries(`${APP_API_URL}/theraflow/users/${id}/qrcode/`, 'GET')
}
function getQrCodeImage(id) {
    return queueQueries(
        `${APP_API_URL}/theraflow/users/${id}/qrcode/image/?xtoken=` +
            localStorage.getItem('apitoken'),
        'GET'
    )
}

function getAlerts(id) {
    return queueQueries(`${APP_API_URL}/theraflow/user-alerts/${id}/`, 'GET')
}

function updateThemes(id, data) {
    return queueQueries(
        `${APP_API_URL}/theraflow/users/${id}/themes/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function updateMonitoring(id, data) {
    return queueQueries(
        `${APP_API_URL}/theraflow/users/${id}/monitoring/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function getInfos(id) {
    return queueQueries(`${APP_API_URL}/theraflow/user/${id}/infos/`, 'GET')
}

function getTimezone() {
    return queueQueries(`${APP_API_URL}/timezones/`, 'GET')
}
function getUser(withHeader) {
    return queueQueries(
        `${APP_API_URL}/user/`,
        'GET',
        'application/json',
        true,
        withHeader
    )
}
function updateUser(data) {
    return queueQueries(
        `${APP_API_URL}/user/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function acceptMonitoringConsent(remoteMonitoringConsent) {
    return queueQueries(
        `${APP_API_URL}/user/remote-monitoring-consent/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { remoteMonitoringConsent: remoteMonitoringConsent }
    )
}

export const User = {
    getTheraflowUserById,
    blockTheraflowUser,
    searchTheraflowUser,
    putUser,
    isEmailAvailable,
    resiliateUser,
    createTheraflowUser,
    updateTheraflowUser,
    checkPatientId,
    getQrCode,
    getQrCodeImage,
    getAlerts,
    updateThemes,
    updateMonitoring,
    getInfos,
    getTimezone,
    getUser,
    acceptMonitoringConsent,
    updateUser,
}
