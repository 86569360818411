import { default as isLength } from 'validator/lib/isLength'
import { t } from 'i18next'
import { Medical, Cardline, Admin } from '@app/api'
import {
    getUser,
    coreFn,
    redirectToOnboarding,
    getTodayYMD,
    createUTCDate,
    getDiffDate,
    isDefined,
    getQueryVariable,
    sortStringNumber,
    scrollTo,
    isMobile,
    getMobileOperatingSystem,
} from '@app/core'
import { displayErrors } from '@app/api/errors'
import moment from 'moment'

import * as constants from '@app/constants'
/**
 * @module DailyTimeline
 * @description Daily Timeline
 */

export default {
    initAction: function () {
        let DailyTimeline = {
            onComponentCreate: (props) => (state, actions) => {
                getUser((user) => {
                    // actions._setState({key: 'appBannerDisplayed', value: true})

                    let os = getMobileOperatingSystem()
                    let displaybanner = user.settings.filter(
                        (f) => f.name === 'RESILIENCE_APP_BANNER'
                    )[0]
                    let displaybannerLastDisplay = localStorage.getItem(
                        'displaybannerLastDisplay'
                    )
                    let today = getTodayYMD()
                    if (
                        isMobile() &&
                        today !== displaybannerLastDisplay &&
                        props.customer === 'resilience' &&
                        ['android', 'ios'].indexOf(os) > -1 &&
                        (!isDefined(displaybanner) ||
                            (isDefined(displaybanner) &&
                                displaybanner.value == 1))
                    ) {
                        actions._setState({
                            key: 'appBannerDisplayed',
                            value: true,
                        })
                        localStorage.setItem('displaybannerLastDisplay', today)
                        if (os === 'android') {
                            actions._setState({
                                key: 'appUrl',
                                value: constants.custo.CUSTOMERS[props.customer]
                                    .appurl.android,
                            })
                        } else if (os === 'ios') {
                            actions._setState({
                                key: 'appUrl',
                                value: constants.custo.CUSTOMERS[props.customer]
                                    .appurl.ios,
                            })
                        }
                    }
                    //
                    actions.setUser(user)
                    Medical.getStudies().then((res) => {
                        let studies = res.data
                        for (let study in studies) {
                            studies[study].answerAgainToday = true
                            studies[study].answerAgainTomorrow = true
                            //
                            if (!isDefined(studies[study].answerDate)) {
                                //
                                continue
                            } else {
                                let dayDiff = getDiffDate(
                                    moment(0, 'HH'),
                                    createUTCDate(
                                        studies[study].answerDate.date
                                    )
                                )
                                if (
                                    props.customer === 'unicancer' ||
                                    props.customer === 'uroconnect'
                                ) {
                                    // one survey = one answer, no more
                                    if (dayDiff <= 0) {
                                        studies[study].answerAgainToday = false
                                        continue
                                    }
                                } else {
                                    // one survey = one answer, each day
                                    if (dayDiff === 0) {
                                        studies[
                                            study
                                        ].answerAgainTomorrow = false
                                        continue
                                    }
                                }
                            }
                        }
                        actions.setSurveys(studies)
                        //
                        let timelineId = getQueryVariable('justanswered', false)
                        if (isDefined(timelineId) && timelineId !== false) {
                            let justAnsweredSurvey = studies.filter(
                                (f) => f.timeline === timelineId
                            )[0]
                            if (isDefined(justAnsweredSurvey)) {
                                actions.displayRightMessage(justAnsweredSurvey)
                            }
                        }
                    })
                }, true)
                actions.getSymptomsCards()
                actions.setCustomer(props.customer)
            },
            getSymptomsCards: () => (state, actions) => {
                Cardline.getCards(60).then((res) => {
                    if (isDefined(res.data) && Array.isArray(res.data)) {
                        actions.setSymptoms(sortStringNumber(res.data, 'name'))
                    }
                    actions.getLibrariesCards()
                })
            },
            getLibrariesCards: () => (state, actions) => {
                Cardline.getCards(61).then((res) => {
                    if (isDefined(res.data) && Array.isArray(res.data)) {
                        actions.setLibraries(sortStringNumber(res.data, 'name'))
                    }
                })
            },
            redirectToSurvey: (survey) => (state, actions) => {
                if (!actions.displayRightMessage(survey)) {
                    return false
                }
                //
                localStorage.setItem('medicalStudysurvey', survey.id)
                localStorage.setItem(
                    'medicalStudytoken',
                    survey.userMedicalStudyToken
                )
                localStorage.setItem('timelineId', survey.timeline)
                //
                redirectToOnboarding(
                    state.user.id,
                    getTodayYMD(),
                    survey.timeline
                )
            },
            displayRightMessage: (survey) => (state, actions) => {
                if (!survey.answerAgainToday) {
                    displayErrors(
                        t(
                            'Merci pour vos réponses au questionnaire. Elles sont enregistrées.\nVous ne pouvez pas répondre de nouveau à ce questionnaire.',
                            { ns: 'timeline' }
                        ),
                        5000,
                        'success'
                    )
                    return false
                } else if (!survey.answerAgainTomorrow) {
                    displayErrors(
                        t(
                            'Merci pour vos réponses au questionnaire. Elles sont enregistrées.\nVous pourrez soumettre un nouveau questionnaire à partir de demain.',
                            { ns: 'timeline' }
                        ),
                        5000,
                        'success'
                    )
                    return false
                }
                return true
            },
            displaySymptom: (symptomid) => (state, actions) => {
                let detailObj = state.symptoms.filter(
                    (f) => f.id === symptomid
                )[0]
                if (isDefined(detailObj)) {
                    detailObj.children = state.symptoms.filter(
                        (f) => f.parent === symptomid
                    )
                    actions.setDetailCard(detailObj)
                }
            },
            displayLibrary: (libraryid) => (state, actions) => {
                let detailObj = state.libraries.filter(
                    (f) => f.id === libraryid
                )[0]
                if (isDefined(detailObj)) {
                    detailObj.children = state.libraries.filter(
                        (f) => f.parent === libraryid
                    )
                    actions.setDetailCard(detailObj)
                }
            },
            displayDetailCardChild:
                ({ childid, parentid, kind, idx }) =>
                (state, actions) => {
                    let detailChildObj = null
                    if (isDefined(childid)) {
                        if (kind === 61) {
                            detailChildObj = state.libraries.filter(
                                (f) => f.id === childid
                            )[0]
                        } else if (kind === 60) {
                            detailChildObj = state.symptoms.filter(
                                (f) => f.id === childid
                            )[0]
                        }
                    } else {
                        if (kind === 61) {
                            detailChildObj = state.libraries.filter(
                                (f) =>
                                    isDefined(f.parent) && f.parent === parentid
                            )[idx]
                        } else if (kind === 60) {
                            detailChildObj = state.symptoms.filter(
                                (f) =>
                                    isDefined(f.parent) && f.parent === parentid
                            )[idx]
                        }
                    }
                    actions.setDetailCardChild(detailChildObj)
                    actions.setIdxCardChild(idx)
                },
            resetScrollTop: () => (state, actions) => {
                let targetEl = document.querySelector(
                    'div.btzMainPage.btzMainPageCenter'
                )
                if (targetEl) {
                    scrollTo(targetEl, 0, 1)
                }
            },
            setUser: (user) => (state) => ({
                user: user,
            }),
            setDetailCard: (newState) => (state) => ({
                detailCard: newState,
            }),
            setDetailCardChild: (newState) => (state) => ({
                detailCardChild: newState,
            }),
            setIdxCardChild: (newState) => (state) => ({
                idxCardChild: newState,
            }),
            setCustomer: (user) => (state) => ({
                customer: user,
            }),
            setSurveys: (newState) => (state) => ({
                surveys: newState,
            }),
            setSymptoms: (newState) => (state) => ({
                symptoms: newState,
            }),
            setLibraries: (newState) => (state) => ({
                libraries: newState,
            }),
            closeBanner: (redirect) => (state, actions) => {
                if (!isDefined(redirect) || isDefined(redirect.target)) {
                    Admin.updateSettings([
                        {
                            name: 'RESILIENCE_APP_BANNER',
                            value: state.values.banner === true ? 0 : 1,
                        },
                    ])
                } else {
                    Admin.updateSettings([
                        {
                            name: 'RESILIENCE_APP_BANNER',
                            value: state.values.banner === true ? 0 : 1,
                        },
                    ]).then((res) => {
                        window.location.href = redirect
                    })
                }
                actions._setState({ key: 'appBannerDisplayed', value: false })
            },
        }
        DailyTimeline = { ...coreFn, ...DailyTimeline }
        return DailyTimeline
    },
}
