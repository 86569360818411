require('@app/__tests__/setup')
const { trimLeadingAndEndingBreakline } = require('.')

const trimed = `Resilience 
 is 
 Awesome`
describe('trimLeadingAndEndingBreakline', () => {
    test.each([
        [undefined, undefined],
        [null, null],
        [NaN, NaN],
        [false, false],
        ['', ''],
        [5, 5],
    ])('should return %s when string is %s', (expected, string) => {
        expect(trimLeadingAndEndingBreakline(string)).toBe(expected)
    })

    test('should return trimed string', async () => {
        expect(
            trimLeadingAndEndingBreakline(
                '\n\nResilience \n is \n Awesome \n\n'
            )
        ).toBe(trimed)
    })
})
