import { marked } from "marked";
import DOMPurify from "dompurify";

const markedDownContent = function (str) {
	if (str === undefined || str === null) {
		return str;
	}
	str = str.replace(
		/(\[video(\(height=(.*)\))?\](.*)\[\/video\])/g,
		'<iframe width="100%" height="$3" src="$4" style="display: block;" allowfullscreen frameborder="0"></iframe>'
	);
	//
	return sanitizeContent(
		marked(str, function (err, content) {
			// eslint-disable-next-line
			content = content.replace(/<a/g, '<a target="_blank"');
			// eslint-disable-next-line
			content = content.replace(
				/(<img([a-zA-Z ":.\/=-\d])+(src="([a-zA-Z ":.\/=-\d]+)")>)/g,
				'<a class="dyn-wrapper-clictoenlarge" href="$4" target="_blank">$1<span class="dyn-marked-clictoenlarge">Cliquez pour agrandir</span></a>'
			);
			return content;
		})
	);
};

const sanitizeContent = function (str) {
	return DOMPurify.sanitize(str, {
		ADD_ATTR: ["target"],
		ALLOWED_TAGS: [
			"img",
			"iframe",
			"a",
			"p",
			"div",
			"span",
			"section",
			"h1",
			"h2",
			"h3",
			"h4",
			"h5",
			"h6",
			"table",
			"tbody",
			"th",
			"tr",
			"td",
			"ul",
			"li",
			"b",
			"br",
			"em",
			"font",
			"head",
			"hr",
			"i",
			"label",
			"pre",
		],
	});
};

export { markedDownContent, sanitizeContent };
