import { t } from "i18next";
import { h } from "hyperapp";
import { Card, Enter, Form, FormInput } from "@app/elements";
import * as constants from "@app/constants";

import ResponsiveModalLayout from "@app/layouts/modal/layout";

import icoRls from "@app/img/deco/illu-rsl.png";
import icoRlsx2 from "@app/img/deco/illu-rsl@2x.png";

export const ModalAppBanner = (props, actions) => (
	<ResponsiveModalLayout page={"appbanner"} closeModal={props.closeModal}>
		<div
			class="appbanner-central"
			style={{ "background-color": "#f8fafb" }}>
			<Card
				classes={"btzPageCard btzBackgroundCover"}
				kind={"datas"}
				style={{ "padding-bottom": "30px" }}>
				<Card.Header classes={"btzCard-btzHeader-btzPage"}>
					<Enter
						time={constants.custo.ANIM_DURATION}
						easing={constants.custo.ANIM_EASING}
						css={constants.custo.ANIM_CSS}>
						<p style="max-width: 70%;margin: auto auto 25px auto;">
							{t("Resilience, notre application mobile")}
						</p>
						<img src={icoRls} srcset={`${icoRlsx2} 2x`} alt="" />
						<p style="margin-bottom: 35px;">
							{t(
								"Vous aider à mieux vivre le cancer au quotidien, telle est notre ambition. Pour réconcilier les mots « vie » et « qualité », l'application Résilience vous accompagne sur le chemin de la résilience."
							)}
						</p>
						<a
							onclick={(e) => {
								props.closeModal(props.appUrl);
							}}>
							{t("Télécharger l'application")}
						</a>
						<Form
							onsubmit={(event) => event.preventDefault()}
							classes={"columns"}>
							<Form.Group classes={"borderedFormGroup"}>
								<FormInput
									placeholder={t(
										"ne plus m'afficher ce message"
									)}
									key="input-banner"
									type="checkbox"
									valueType="checkbox"
									id="banner"
									name="banner"
									value={props.values.banner}
									list={[
										{
											key: true,
											label: t(
												"ne plus m'afficher ce message"
											),
										},
									]}
									oninputcb={props._retrieveInput}
									required
								/>
							</Form.Group>
						</Form>
					</Enter>
				</Card.Header>
			</Card>
		</div>
	</ResponsiveModalLayout>
);
