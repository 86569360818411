import * as constants from '@app/constants'
export const state = {
    values: {
        phoneOrEmail: '',
        birthdate: '',
    },
    isTouched: {
        phoneOrEmail: false,
        birthdate: false,
    },
    isValid: {
        phoneOrEmail: false,
        birthdate: false,
    },
    isTyping: {
        phoneOrEmail: false,
        birthdate: false,
    },
    errorMessages: {
        phoneOrEmail: '',
        birthdate: '',
        global: '',
    },
    api: {
        message: '',
        code: 200,
        kind: 'info',
    },
    customer: 'theraflow',
    accessToken: null,
    medicalStudytoken: null,
    medicalInfos: null,
    timelineId: null,
    medicalStudysurvey: null,
    idPatient: null,
    loginStep: 'welcome',
    message: [],
    formIsSubmitting: false,
    formSubmittedAtLeastOneTime: false,
    rendering: false,
    formSubmittedOnce: false,
    modalDisplayed: false,
    directAccess: false,
    displayWelcome: false,
    invitToken: null,
    askForMonitoringConsent: false,
}
