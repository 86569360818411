require('@app/__tests__/setup')
const { getQueryVariable } = require('.')

const fakeUrlQueryParams = (queryParams) => {
    window.history.pushState({}, '', `/${queryParams}`)
}

describe('getQueryVariable', () => {
    test('getQueryVariable', async () => {
        const expectedParamKey = 'param'
        const expectedParamValue = 'param'
        const expectedParam = `?${expectedParamKey}=${expectedParamValue}`
        fakeUrlQueryParams(expectedParam)
        expect(getQueryVariable(expectedParamKey)).toBe(expectedParamValue)

        const expectedParamKey2 = 'param2'
        const expectedParamValue2 = 'param2'
        const expectedParam2 = `&${expectedParamKey2}=${expectedParamValue2}`
        fakeUrlQueryParams(`${expectedParam}${expectedParam2}`)
        expect(getQueryVariable(expectedParamKey2)).toBe(expectedParamValue2)
    })

    test.each([
        [false, 'none'],
        [false, {}],
        [false, []],
        [false, false],
        [false, null],
        [false, undefined],
    ])(
        'should return %s when searched param (%s) does not exist in url',
        (expected, queryParam) => {
            fakeUrlQueryParams(`?foo=bar`)
            expect(getQueryVariable(queryParam)).toBe(expected)
        }
    )
})
