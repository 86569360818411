import { h } from 'hyperapp';
import { Component } from '@app/utils';
import './index.scss';

import Layout from '@app/layouts/pages/layout';

import DailyTimeline from './dailyTimeline';

const state = {
    hiddenOverflow: false,
    headerColor: 'blue',
    pageLevel: 'timeline',
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        // localStorage.clear();
    },
    updateHiddenOverflow: newState => state => ({
        hiddenOverflow: newState,
    }),
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    }),
    updateHeaderColor: newState => state => ({
        headerColor: newState,
    }),
    updatePageLevel: newState => state => ({
        pageLevel: newState,
    }),
};

const view = (state, actions) => ({ location, match, customer }, children) => (
    <div key={'timeline'} class='root-wrapper'>
        <Layout page={'timeline-root'} customer={customer} hiddenOverflow={state.hiddenOverflow} headerColor={state.headerColor} pageLevel={state.pageLevel}>
            {/* Default view */}
            <DailyTimeline initial={state.email} customer={customer} send={actions.setState} match={match} updateHiddenOverflow={actions.updateHiddenOverflow} updatePageLevel={actions.updatePageLevel} updateHeaderColor={actions.updateHeaderColor} />
        </Layout>
    </div>
);

export {
    DailyTimeline,
};

export default Component(state, actions, view, 'timeline');
