import { metricKilogramToPoundsFormula } from '../..'
import { generatePower } from '../../../power'

const metricKilogramToPounds = (metric, decimal) => {
    if (metric && !Array.isArray(metric) && !isNaN(+metric)) {
        const power = generatePower(decimal) ?? 1
        const calculation = metricKilogramToPoundsFormula(metric) * power
        if (!decimal) {
            return String(calculation)
        }
        return String(parseFloat((calculation / power).toFixed(decimal)))
    }
    return undefined
}

export { metricKilogramToPounds }
