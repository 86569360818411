require('@app/__tests__/setup')
const { setLocaleInLocalStorage } = require('@app/core/languagesHandler')
const { keepLocaleInUrl, updateLocaleInUrl } = require('.')

const fakeUrl = 'https://dashboard.resilience.care/'
Object.defineProperty(window, 'location', {
    value: {
        href: fakeUrl,
    },
    writable: true,
})

describe('localeInUrl', () => {
    test('updateLocaleInUrl', async () => {
        expect(keepLocaleInUrl()).toBe(fakeUrl)

        setLocaleInLocalStorage('fr-FR')
        expect(keepLocaleInUrl()).toBe(`${fakeUrl}?locale=fr-FR`)

        setLocaleInLocalStorage('en-FR')
        expect(updateLocaleInUrl()).toBe(`${fakeUrl}`)

        Object.defineProperty(window, 'location', {
            value: {
                href: `${fakeUrl}?locale=fr-FR`,
            },
            writable: true,
        })
        expect(updateLocaleInUrl()).toBe(`${fakeUrl}?locale=en-FR`)
    })

    // test.each([
    //     [false, 'none'],
    //     [false, {}],
    //     [false, []],
    //     [false, false],
    //     [false, null],
    //     [false, undefined],
    // ])(
    //     'should return %s when searched param (%s) does not exist in url',
    //     (expected, queryParam) => {
    //         fakeUrlQueryParams(`?foo=bar`)
    //         expect(getQueryVariable(queryParam)).toBe(expected)
    //     }
    // )
})
