export const generatePower = (exponent = 0) => {
    if (
        !exponent ||
        isNaN(+exponent) ||
        !Number.isInteger(exponent) ||
        typeof exponent === 'string'
    ) {
        return undefined
    }
    return Math.pow(10, exponent && Number.isInteger(exponent) ? exponent : 0)
}
