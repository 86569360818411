import * as constants from '@app/constants';
export const state = {
    refused: false,
    displayedCGU: false,
    displayedPDC: false,
    modalDisplayed: false,
    modalEditing: '',
    title: '',
    description: '',
    agreements: {
        terms: null,
        privacy: null,
    },
    support: '',
    agreementToAprove: false,
};
