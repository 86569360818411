const retrieveEmergencyPhoneByCountry = function () {
    let user = localStorage.getItem('user')
    if (user) {
        try {
            user = JSON.parse(user)
            const reference =
                user?.customer?.country ??
                user?.userTheraflow?.doctor?.customer?.country
            if (['US', 'CA'].includes(reference)) {
                return '911'
            }
        } catch (err) {
            return '112'
        }
    }

    return '112'
}

export { retrieveEmergencyPhoneByCountry }
