import { t } from 'i18next';
import { h } from 'hyperapp';
import { Component } from '@app/utils';

import { Grid, Navbar,Enter } from '@app/elements';
import { allRoutes, isDefined, getUser, logoutAndRedirect } from '@app/core';
import * as constants from '@app/constants';

import { TopLogoView } from '@app/layouts/toplogo';

import icoLogout from '@app/img/ico/ico-logout.png';
import icoLogoutx2 from '@app/img/ico/ico-logout@2x.png';
import icoLogoutGrey from '@app/img/ico/ico-logout-grey.png';
import icoLogoutGreyx2 from '@app/img/ico/ico-logout-grey@2x.png';
import icoLogoutBlue from '@app/img/ico/ico-logout-blue.png';
import icoLogoutBluex2 from '@app/img/ico/ico-logout-blue@2x.png';

import { BotFooterView } from '@app/layouts/botfooter';
import icoBgHomex2 from '@app/img/bg/bg-header-home@2x.png';
import icoBgHomeRedx2 from '@app/img/bg/bg-header-card@2x.png';

import './index.scss';

const state = {
    logged: false,
    doctor: null,
    minifiedLeftMenu: false,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        //
    },
}

const view = (state, actions) => (props, children) => (
    <div key={'layout'} class={props.page + ' ' + (state.minifiedLeftMenu ? 'minifiedLeftMenu' : '') + ' ' + props.pageLevel}>
        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
            <Grid classes={'btzHeader btzHeader-btzNoMediaQuery'}>
                <Grid.Row classes={'btzHeaderWrapper'}>
                    <Grid.Col x={12} mx={'auto'} classes={'p-0'}>
                        <Navbar style={{ 'background-image': 'url(' + ((props.headerColor === 'blue') ? icoBgHomex2 : icoBgHomeRedx2) + ')' }}>
                            <Navbar.Section classes='btzHeader-btzHeaderLeftHand'>
                                <TopLogoView {...props} color={'black'} redirectTo={allRoutes['index'].pathname} customer={props.customer}></TopLogoView>
                            </Navbar.Section>

                            <Navbar.Section classes='btzHeader-btzHeaderRightHand'>
                                <div class='btzLogout' style={{ 'text-align': 'left' }}><div style={{ 'display': 'inline-block' }} onclick={() => {logoutAndRedirect();}}><p>{t('me déconnecter', {ns: 'generals'})}</p><img style='vertical-align: middle; margin-left: 15px;' src={((props.customer === 'uroconnect') ? icoLogoutBlue : ((props.customer === 'resilience') ? icoLogoutGrey : icoLogout))} srcset={`${((props.customer === 'uroconnect') ? icoLogoutBluex2 : ((props.customer === 'resilience') ? icoLogoutGreyx2 : icoLogoutx2))} 2x`} alt='' class='' /></div></div>
                            </Navbar.Section>
                        </Navbar>
                    </Grid.Col>
                </Grid.Row>
            </Grid>
            <Grid classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign'}>
                <Grid.Row classes={'btzWrapper-verticalAlignContent' + (props.howthatwork ? ' btzWrapper-verticalAlignContent-minHeight' : '')}>
                    <Grid.Col x={12} mx={'auto'} style={{ 'position': 'relative', 'padding-left': '0px', 'height': '100%' }}>
                        <div class={'btzMainPage btzMainPageCenter ' + (props.hiddenOverflow ? 'hiddenOverflow' : '')}>
                            <div class='btzMainPagePaddedMarker'>{children}</div>
                            <BotFooterView {...props} customer={props.customerssoname} displayed={'timeline'}></BotFooterView>
                        </div>
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
);

export default Component(state, actions, view, 'layout');