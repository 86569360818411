import { default as classMerge } from 'classcat';
import { h } from 'hyperapp';

const Element = tag => ({
    classes = [],
    ...otherProps
}, children) => h(tag, {
    class: classMerge(classes) || false,
    ...otherProps
}, children)

export { Element };
