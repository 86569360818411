require('@app/__tests__/setup')
const { metricKilogramToPounds } = require('./metricKilogramToPounds')
const { metricPoundsToKilogram } = require('./metricPoundsToKilogram')

let allCalculations = []
Array.from(Array(10).keys()).forEach((pounds) => {
    return Array.from(Array(99).keys()).forEach((decimal) => {
        if (decimal > 0) {
            allCalculations.push([Number(`${pounds}.${decimal}`).toString()])
        }
    })
})

describe('metricKilogramToPounds ROUND TRIP', () => {
    test.each([...allCalculations])(
        'should do the round trip with $s beginning with kg to pound then back to kg',
        (metric) => {
            const pounds = metricKilogramToPounds(metric, null) // if a decimal is defined, this would lead to a difference because result is rounded
            const backToKg = metricPoundsToKilogram(pounds, 2)
            expect(backToKg).toStrictEqual(metric)
        }
    )

    test.each([...allCalculations])(
        'should also do the round trip with $s beginning with pounds to kg then back to pounds',
        (metric) => {
            const kg = metricPoundsToKilogram(metric, null) // if a decimal is defined, this would lead to a difference because result is rounded
            const backToPounds = metricKilogramToPounds(kg, 2)
            expect(backToPounds).toStrictEqual(metric)
        }
    )
})
