import { h } from 'hyperapp'
import { Component } from '@app/utils'
import { isDefined } from '@app/core'
import { settings } from '@app/core/settings'
import { Link } from '@app/elements'
import * as constants from '@app/constants'

const { APP_CUSTOMISATION, APP_WEB_URL } = settings

const TopLogoView = Component(
    {
        customer: 'Theraflow',
        customerLogo: 'Theraflow',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            let customer = state.customer
            if (props.customer) {
                customer = props.customer
            } else {
                customer = APP_CUSTOMISATION.toLowerCase()
            }
            actions.setCustomer(customer)

            let ccustomer = constants.custo.CUSTOMERS[customer]
            if (isDefined(ccustomer)) {
                actions.setCustomerLogo(ccustomer.logo)
            }
        },
        setCustomer: (newState) => () => ({
            customer: newState,
        }),
        setCustomerLogo: (newState) => () => ({
            customerLogo: newState,
        }),
    },
    (state) => (props) => (
        <ul id="btzMainTopLogo">
            <li style={{ display: 'block' }}>
                <Link class={'btn btn-link'} to={'#'} style={{ opacity: 1 }}>
                    {APP_CUSTOMISATION.toLowerCase() === 'resilience' ? (
                        <img
                            style="max-width: 110px;"
                            src={`${APP_WEB_URL}/__logo/${props.customer.toLowerCase()}/logo-blue.png`}
                            srcset={`${APP_WEB_URL}/__logo/${props.customer.toLowerCase()}/logo-blue@2x.png`}
                            alt=""
                        />
                    ) : (
                        <img
                            style="max-width: 110px;"
                            src={`${APP_WEB_URL}/__logo/${state.customer}/logo-white.png`}
                            srcset={`${APP_WEB_URL}/__logo/${state.customer}/logo-white@2x.png`}
                            alt=""
                        />
                    )}
                </Link>
            </li>
        </ul>
    ),
    'toplogoview'
)

export { TopLogoView }
