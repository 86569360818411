import { h } from 'hyperapp'
import { t } from 'i18next'
import { Component } from '@app/utils'
import { getReadableDate, getDateObjFromYMD } from '@app/core'

import ViewInstructionDefault from './subview-instructions-resilience'
import { settings } from '@app/core/settings'

import icoMD from '@app/img/mentions/logo-right-md.png'
import icoMDx2 from '@app/img/mentions/logo-right-md@2x.png'
import icoLOT from '@app/img/mentions/logo-right-lot.png'
import icoLOTx2 from '@app/img/mentions/logo-right-lot@2x.png'
import icoREF from '@app/img/mentions/logo-right-ref.png'
import icoREFx2 from '@app/img/mentions/logo-right-ref@2x.png'
import icoUDI from '@app/img/mentions/logo-right-udi.png'
import icoUDIx2 from '@app/img/mentions/logo-right-udi@2x.png'

import icoCE from '@app/img/mentions/logo-right-ce.png'
import icoCEx2 from '@app/img/mentions/logo-right-ce@2x.png'
import icoFACTORY from '@app/img/mentions/logo-right-factory.png'
import icoFACTORYx2 from '@app/img/mentions/logo-right-factory@2x.png'
import icoINFOS from '@app/img/mentions/logo-infos.png'
import icoINFOSx2 from '@app/img/mentions/logo-infos@2x.png'
import { defaultLocale, getLocalStoredLocale } from '@app/core/languagesHandler'
import { dateTimeFormat } from '@app/core/tools/intl/dateTimeFormat'

const state = {
    language: 'fr',
    linkToPatientUseNotice: null,
    uid: '3760356940012',
}

const { APP_CUSTOMISATION, APP_WEB_URL } = settings

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        const language = (getLocalStoredLocale()?.value ?? defaultLocale).slice(
            0,
            2
        )

        actions.setLinkToPatientUseNotice(
            props.support?.modules?.notice?.stonly?.[language]
                ? props.support.modules.notice.stonly[language]
                : APP_WEB_URL + props.support.modules.notice.patient
        )
    },
    setLinkToPatientUseNotice: (newState) => (state) => ({
        linkToPatientUseNotice: newState,
    }),
}

const view = (state, actions) => (props, children) => (
    <dummy>
        <div class="responsiveModalHeader">
            <p class="btzTitle btzXl">{t('Mentions légales')}</p>
        </div>
        <div class="responsiveModalContent thfMentionsWrapper">
            <p class="">
                {t(
                    "La solution Resilience comprend le module Resilience PRO App , logiciel de transfert de données dans le domaine de l'oncologie. Elle est destinée au suivi à distance des patients atteints de cancer par les professionnels de santé, en dehors des situations d'urgence."
                )}
            </p>
            <p class="">
                {t(
                    'L‘ensemble des éléments constituant notre plateforme ont été conçus en suivant de près les normes internationales les plus strictes en termes de gestion de la qualité, de sécurité du système d‘information et de la conception de logiciels dispositifs médicaux.'
                )}
            </p>
            <div class="" style="margin-bottom: 30px;margin-top: 30px;">
                <div
                    class="thfMentionsLogos"
                    style="display: inline-block;vertical-align: middle;height: 40px;"
                >
                    <img
                        class=""
                        style="margin-right: 10px;height: 40px;"
                        src={icoMD}
                        srcset={`${icoMDx2} 2x`}
                        alt=""
                    />
                    <img
                        class=""
                        style="margin-right: 5px;height: 40px;"
                        src={icoCE}
                        srcset={`${icoCEx2} 2x`}
                        alt=""
                    />
                    <div style="display: inline-block; margin-right: 15px;position: relative;">
                        <img
                            class=""
                            style="height: 40px;"
                            src={icoINFOS}
                            srcset={`${icoINFOSx2} 2x`}
                            alt=""
                        />
                        {!!state.linkToPatientUseNotice && (
                            <a
                                class="underlined"
                                style="display: block; font-size: 10px; position: absolute; bottom: -15px;width: 100%;text-align: center;"
                                href={state.linkToPatientUseNotice}
                                target="_blank"
                            >
                                {t('lien notice')}
                            </a>
                        )}
                    </div>
                    <img
                        class=""
                        style="height: 40px;"
                        src={icoFACTORY}
                        srcset={`${icoFACTORYx2} 2x`}
                        alt=""
                    />
                </div>
                <div style="display: inline-block;margin-left: 15px;vertical-align: middle;">
                    <p class="" style="margin: 0px;line-height: 1.3;">
                        Resilience MD
                    </p>
                    <p class="" style="margin: 0px;line-height: 1.3;">
                        24 avenue Victor Hugo
                    </p>
                    <p class="" style="margin: 0px;line-height: 1.3;">
                        64200 Biarritz, France
                    </p>
                    <p class="" style="margin: 0px;line-height: 1.3;">
                        {props.support.mailto}
                    </p>
                </div>
            </div>
            <p class="mentions__block" style="">
                <img
                    class="mentions__block--ico"
                    src={icoLOT}
                    srcset={`${icoLOTx2} 2x`}
                    alt=""
                />
                {t('Produit ') +
                    (process.env.APP_VERSIONPRODUCT || 'N.C') +
                    ' (Front ' +
                    (process.env.APP_VERSION || 'N.C') +
                    ', API ' +
                    (process.env.APP_VERSIONAPI || 'N.C') +
                    ')' +
                    (process.env.APP_YMD
                        ? ', ' +
                          dateTimeFormat(
                              new Date(
                                  getDateObjFromYMD(process.env.APP_YMD).date
                              ),
                              {
                                  year: 'numeric',
                                  day: '2-digit',
                                  month: 'long',
                              }
                          )
                        : ', N.C')}
            </p>
            <p class="mentions__block" style="">
                <img
                    class="mentions__block--ico"
                    src={icoUDI}
                    srcset={`${icoUDIx2} 2x`}
                    alt=""
                />
                {'(01)' +
                    state.uid +
                    '(10)' +
                    (process.env.APP_VERSIONPRODUCT || 'Ø') +
                    ' (Front ' +
                    (process.env.APP_VERSION || 'N.C') +
                    ', API ' +
                    (process.env.APP_VERSIONAPI || 'N.C') +
                    ')' +
                    ' - ' +
                    (process.env.APP_YMD ? process.env.APP_YMD : 'N.C')}
            </p>
            <p class="mentions__block" style="">
                <img
                    class="mentions__block--ico"
                    src={icoREF}
                    srcset={`${icoREFx2} 2x`}
                    alt=""
                />
                {state.uid}
            </p>
            <ViewInstructionDefault
                {...props}
                linkToPatientUseNotice={state.linkToPatientUseNotice}
            ></ViewInstructionDefault>
        </div>
    </dummy>
)

export default Component(state, actions, view, 'subview')
