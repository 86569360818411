import moment from 'moment'
const getYMDfromDateObj = function (
    obj,
    readable = false,
    ymd = false,
    format = { year: true, month: true, day: true }
) {
    if (moment.isMoment(obj)) {
        obj = obj.toDate()
    } else {
        obj = new Date()
    }

    let dateDay = obj.getDate() // TO SURVEY //!!\\
    let dateMonth = obj.getUTCMonth() + 1
    let dateYear = obj.getUTCFullYear()

    if (+dateDay < 10) {
        dateDay = '0' + dateDay
    }
    if (+dateMonth < 10) {
        dateMonth = '0' + dateMonth
    }
    if (readable) {
        if (ymd) {
            return dateYear + '' + dateMonth + '' + dateDay
        }
        if (format.year && format.month && format.day) {
            return dateDay + '/' + dateMonth + '/' + dateYear
        } else {
            if (format.month && format.day) {
                return dateDay + '.' + dateMonth
            } else if (format.year && format.month) {
                return dateMonth + '.' + dateYear
            }
        }
    }

    return {
        day: dateDay,
        month: dateMonth,
        year: dateYear,
    }
}

export { getYMDfromDateObj }
