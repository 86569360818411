import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button, Form, FormInput, Card } from '@app/elements';

export const ModalViewResetPWD = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} class={'btzClassMarker-btz'} key={'resetPwd'}>
                <p class='btzSubTitle'>{t('Dans le tout premier champ, renseignez l’adresse email ou le numéro de téléphone portable que vous avez communiqué à votre équipe médicale.', {ns: 'login'})}</p>
                <p class='btzSubTitle'>{t('Vous disposez d\'un numéro belge ? Saisissez +32 puis votre numéro de téléphone.', {ns: 'login'})}</p>
                <p class='btzSubTitle'>{t('En cas d’oubli, contactez votre centre.', {ns: 'login'})}</p>

                <Form onsubmit={event => event.preventDefault()}>
                    <Form.Group classes='btzForm-btzFooter' style={{ 'padding': '0 15px' }}>
                        <Button anchorRight flat width={'100%'} active onclick={(e) => {
                            props.onSubmit(e);
                        }}>
                            {t('Fermer', {ns: 'generals'})}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </Card.Header>
    </Card>
);