import { getLocalStoredLocale } from '@app/core/languagesHandler'
import { LocalesConversions } from '..'

const transformMetric = (type, metricInput, way, decimal = 2) => {
    if (typeof metricInput !== 'string' && isNaN(+metricInput)) {
        return undefined
    }
    const locale = getLocalStoredLocale()
    const region = locale.region

    const conversionFn = LocalesConversions?.[type]?.regions?.includes(region)
        ? LocalesConversions?.[type]?.fn?.[way]
        : null
    if (conversionFn) {
        return conversionFn(metricInput, decimal)
    }
    return metricInput
}
export { transformMetric }
