import { t } from 'i18next'

// IE z-index is broken by translateY
// export const ANIM_CSS = { transform: 'translateY(-5px)', opacity: '0' };
export const ANIM_CSS = { opacity: '0' }
export const ANIM_EASING = 'ease-in-out'
export const ANIM_DURATION = 500
export const MANDATORY_THEMES = [5, 15, 16, 99]

export const configureSurvey = [
    {
        label: 'ToutK00001',
        key: 'toutk00001',
        frequency: 'weekly',
        issuePeriod: 'weeklyMonday',
        order: 0,
    },
    {
        label: 'ToutK00002IIa',
        key: 'toutk00002',
        frequency: 'weekly',
        issuePeriod: 'weeklyMonday',
        order: 1,
    },
]

export const METRICS = {
    height: {
        min: 140,
        max: 220,
    },
    weight: {
        min: 35,
        max: 200,
    },
    abdo: {
        min: 40,
        max: 130,
    },
}

export const THEMES = {
    0: { value: '', label: '', key: 0, color: '' },
    1: {
        value: 'food',
        label: 'Nutrition',
        i18nLabel: t('Nutrition', { ns: 'datas' }),
        key: 1,
        color: 'FF6F41',
        order: 12,
    },
    2: {
        value: 'activity',
        label: 'Activité physique',
        i18nLabel: t('Activité physique', { ns: 'datas' }),
        key: 2,
        color: '049588',
        order: 1,
    },
    3: {
        value: 'sleep',
        label: 'Sommeil',
        i18nLabel: t('Sommeil', { ns: 'datas' }),
        key: 3,
        color: '3E51B5',
        order: 16,
    },
    4: {
        value: 'stress',
        label: 'Stress',
        i18nLabel: t('Stress', { ns: 'datas' }),
        key: 4,
        color: 'E81E63',
        order: 18,
    },
    5: {
        value: 'shape',
        label: 'Moral',
        i18nLabel: t('Moral', { ns: 'datas' }),
        key: 5,
        color: 'FEC009',
        order: 11,
    },
    6: {
        value: 'tobacco',
        label: 'Tabac',
        i18nLabel: t('Tabac', { ns: 'datas' }),
        key: 6,
        color: 'FF9A00',
        order: 20,
    },
    7: {
        value: 'diabet',
        label: 'Diabete',
        i18nLabel: t('Diabete', { ns: 'datas' }),
        key: 7,
        color: '',
        order: 6,
    },
    8: {
        value: 'alcool',
        label: 'Alcool',
        i18nLabel: t('Alcool', { ns: 'datas' }),
        key: 8,
        color: 'A62671',
        order: 2,
    },
    9: {
        value: 'memory',
        label: 'Mémoire',
        i18nLabel: t('Mémoire', { ns: 'datas' }),
        key: 9,
        color: '00BABA',
        order: 10,
    },
    10: {
        value: 'anticancer',
        label: 'Prévention Cancer',
        i18nLabel: t('Prévention Cancer', { ns: 'datas' }),
        key: 10,
        color: '',
        order: 13,
    },
    11: {
        value: 'backache',
        label: 'Mal de dos',
        i18nLabel: t('Mal de dos', { ns: 'datas' }),
        key: 11,
        color: 'C83B4C',
        order: 9,
    },
    12: {
        value: 'working-position',
        label: 'Au travail',
        i18nLabel: t('Au travail', { ns: 'datas' }),
        key: 12,
        color: '4EC9DF',
        order: 3,
    },
    13: {
        value: 'shaped-heart',
        label: 'Coeur',
        i18nLabel: t('Coeur', { ns: 'datas' }),
        key: 13,
        color: '',
        order: 4,
    },
    14: {
        value: 'happiness-psychology',
        label: 'Psychologie du Bonheur',
        i18nLabel: t('Psychologie du Bonheur', { ns: 'datas' }),
        key: 14,
        color: '',
        order: 15,
    },
    15: {
        value: 'profil',
        label: 'Profil',
        i18nLabel: t('Profil', { ns: 'datas' }),
        key: 15,
        color: '',
        order: 14,
    },
    16: {
        value: 'followed-weight',
        label: 'Suivis de poids',
        i18nLabel: t('Suivis de poids', { ns: 'datas' }),
        key: 16,
        color: '5F7D8B',
        order: 19,
    },
    17: {
        value: 'happy-pregnancy',
        label: 'Grossesse',
        i18nLabel: t('Grossesse', { ns: 'datas' }),
        key: 17,
        color: '7481CF',
        order: 8,
    },
    18: {
        value: 'tiredness',
        label: 'Fatigue',
        i18nLabel: t('Fatigue', { ns: 'datas' }),
        key: 18,
        color: '3576A1',
        order: 7,
    },
    19: {
        value: 'sport',
        label: 'Sport',
        i18nLabel: t('Sport', { ns: 'datas' }),
        key: 19,
        color: '4CAF50',
        order: 17,
    },
    20: {
        value: 'denivele',
        label: 'Dénivelé',
        i18nLabel: t('Dénivelé', { ns: 'datas' }),
        key: 20,
        color: '049588',
        order: 5,
    },
    21: {
        value: 'waistsize',
        label: 'Périm abdo',
        i18nLabel: t('Périm abdo', { ns: 'datas' }),
        key: 30,
        color: '76D3CA',
        order: 20,
    },
}

export const EVAL_TYPES_MEDICAL = [
    { key: 'Slider', value: 'Slider', type: 1 },
    { key: 'Button', value: 'Button', type: 2 },
    { key: 'QCM', value: 'QCM', type: 3 },
    { key: 'Textarea', value: 'Textarea', type: 4 },
    {
        key: 'CryptedWeight',
        value: 'Poids',
        type: 5,
        tag: ['CRYPTEDWEIGHT_VALUE'],
        label: ['Poids'],
        i18nLabel: [t('Poids', { ns: 'datas' })],
    },
    {
        key: 'BloodPressure',
        value: 'Tension',
        type: 6,
        tag: ['BLOODPRESSURE_SYSTOLIC', 'BLOODPRESSURE_DIASTOLIC'],
        label: [
            'Pression sanguine Systolique',
            'Pression sanguine Diastolique',
        ],
        i18nLabel: [
            t('Pression sanguine Systolique', { ns: 'datas' }),
            t('Pression sanguine Diastolique', { ns: 'datas' }),
        ],
    },
    {
        key: 'CryptedTemperature',
        value: 'Température',
        type: 7,
        tag: ['CRYPTEDTEMPERATURE_VALUE'],
        label: ['Température'],
        i18nLabel: [t('Température', { ns: 'datas' })],
    },
    {
        key: 'Heartbeat',
        value: 'BPM',
        type: 8,
        tag: ['HEARTBEAT_VALUE'],
        label: ['BPM'],
        i18nLabel: [t('BPM', { ns: 'datas' })],
    },
]

// export const CUSTOMERS_COMPANY_PROVISIONNING = ['cerba', 'auchan'];
// export const CUSTOMERS_WHYTE_BRAND = ['cerba'];
export const CUSTOMERS = {
    theraflow: {
        title: 'Theraflow',
        description: 'Theraflow description',
        i18ndescription: t('Theraflow description'),
        logo: 'Theraflow',
        mailto: 'support@theraflow.care',
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: {
                soignant: '/__pdf/notices/default/notice-soignant.pdf',
                patient: '/__pdf/notices/default/notice-patient.pdf',
            },
        },
        pdf: {
            cgu: 'pdf.cgu',
            cga: 'pdf.cga',
        },
    },
    oncoflow: {
        title: 'Oncolaxy',
        description: 'Oncolaxy description',
        i18ndescription: t('Oncolaxy description'),
        logo: 'Oncolaxy',
        mailto: 'support@oncolaxy.care',
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: {
                soignant: '/__pdf/notices/oncoflow/notice-soignant.pdf',
                patient: '/__pdf/notices/oncoflow/notice-patient.pdf',
            },
        },
        pdf: {
            cgu: 'pdf.oncoflow.cgu',
            cga: 'pdf.cga',
        },
    },
    unicancer: {
        title: 'Unicancer',
        description: 'Unicancer description',
        i18ndescription: t('Unicancer description'),
        logo: 'Unicancer',
        mailto: 'support@theraflow.care',
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: {
                soignant: '/__pdf/notices/unicancer/notice-soignant.pdf',
                patient: '/__pdf/notices/unicancer/notice-patient.pdf',
            },
        },
        pdf: {
            cgu: 'pdf.unicancer.cgu',
            cga: 'pdf.cga',
        },
    },
    weprom: {
        title: 'KidneyPro',
        description: 'KidneyPro description',
        i18ndescription: t('KidneyPro description'),
        logo: 'KidneyPro',
        mailto: 'support@oncolaxy.care',
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: {
                soignant: '/__pdf/notices/weprom/notice-soignant.pdf',
                patient: '/__pdf/notices/weprom/notice-patient.pdf',
            },
        },
        pdf: {
            cgu: 'pdf.cgu',
            cga: 'pdf.cga',
        },
    },
    cardiolaxy: {
        title: 'Cardiolaxy',
        description: 'Cardiolaxy description',
        i18ndescription: t('Cardiolaxy description'),
        logo: 'Cardiolaxy',
        mailto: 'support@cardiolaxy.care',
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: {
                soignant: '/__pdf/notices/cardiolaxy/notice-soignant.pdf',
                patient: '/__pdf/notices/cardiolaxy/notice-patient.pdf',
            },
        },
        pdf: {
            cgu: 'pdf.cgu',
            cga: 'pdf.cga',
        },
    },
    domicalis: {
        title: 'Domicalis',
        description: 'Domicalis description',
        i18ndescription: t('Domicalis description'),
        logo: 'Domicalis',
        mailto: 'support@theraflow.care',
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: {
                soignant: '/__pdf/notices/default/notice-soignant.pdf',
                patient: '/__pdf/notices/default/notice-patient.pdf',
            },
        },
        pdf: {
            cgu: 'pdf.cgu',
            cga: 'pdf.cga',
        },
    },
    uroconnect: {
        title: 'UroConnect',
        description: 'UroConnect description',
        i18ndescription: t('UroConnect description'),
        logo: 'UroConnect',
        mailto: 'support@uroconnect.care',
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: {
                soignant: '/__pdf/notices/uroconnect/notice-soignant.pdf',
                patient: '/__pdf/notices/uroconnect/notice-patient.pdf',
            },
        },
        pdf: {
            cgu: 'pdf.cgu',
            cga: 'pdf.cga',
        },
    },
    resilience: {
        title: 'Resilience',
        description: 'Resilience description',
        i18ndescription: t('Resilience description'),
        logo: 'Resilience',
        mailto: 'aide@resilience.care',
        appurl: {
            iosbanner: 'app-id=1575221463',
            androidbanner: 'app-id=com.resiliencecare.resilience',
            ios: 'https://resicare.page.link/?link=https://resilience.care/?rpm%3Dtrue&apn=com.resiliencecare.resilience&isi=1575221463&ibi=com.resiliencecare.resilience&ius=com.resiliencecare.resilience&efr=0',
            android:
                'https://resicare.page.link/?link=https://resilience.care/?rpm%3Dtrue&apn=com.resiliencecare.resilience&isi=1575221463&ibi=com.resiliencecare.resilience&ius=com.resiliencecare.resilience&efr=0',
        },
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: {
                stonly: {
                    fr: 'https://stonly.com/guide/fr/patients-notice-dutilisation-A14woXAajV/Steps/',
                    en: 'https://stonly.com/guide/en/patient-user-manual-A14woXAajV/Steps/',
                    nl: 'https://stonly.com/guide/nl/patienten-gebruiksaanwijzing-A14woXAajV/Steps/',
                    de: 'https://stonly.com/guide/de/benutzerhandbuch-fur-patienten-A14woXAajV/Steps/2401361',
                },
            },
        },
        pdf: {
            cgu: 'pdf.cgu',
            cga: 'pdf.cga',
        },
    },
}

export const studies = [{ key: 'palatine', label: 'Palatine' }]
