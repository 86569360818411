export const isThereAnomaliesInCards = (cards) => {
    if (Array.isArray(cards)) {
        const cardsFormsLength = cards.reduce((acc, curr) => {
            return [...acc, ...curr.forms]
        }, []).length
        const uniqueCardsIds = new Set(cards.map((card) => card.id))
        const uniqueCardsNames = new Set(cards.map((card) => card.name))
        const uniqueCardsFormIds = new Set(
            cards.map((card) => card.forms.map((item) => item.id)).flat()
        )
        if (
            cards.length !== uniqueCardsIds.size ||
            cards.length !== uniqueCardsNames.size ||
            cardsFormsLength !== uniqueCardsFormIds.size
        ) {
            return true
        }
    }

    return false
}
