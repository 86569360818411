import { settings } from '@app/core/settings'
import { queueQueries } from '@app/api/controlleur'
import { isDefined } from '@app/core'
import lifecycle from 'page-lifecycle'
const { APP_API_URL } = settings

function sendKafkaEvent(data) {
    return queueQueries(
        `${APP_API_URL}/kafka/evt/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

const eventsList = [
    {
        name: 'login-reached',
        data: 'content-event-login-reached',
        type: 'DISPATCH_EVENT_DEFAULT',
    },
    {
        name: 'user-logged',
        data: 'content-event-user-logged',
        type: 'DISPATCH_EVENT_DEFAULT',
    },
]

const withKafkaEvents =
    (opts = {}) =>
    (nextApp) =>
    (initialState, initialActions, initialView, initialRoot) => {
        const options = {
            ...{
                //
            },
            ...opts,
        }

        const enhancedState = {
            // lang: options.lng
        }

        const enhancedActions = {
            initEventListeners: () => {
                // i18n.init(options)
                lifecycle.addEventListener('statechange', function (event) {
                    // console.log('KAFKA : lifecycle > ', event.oldState, event.newState);
                    if (window.location.pathname === '/onboarding') {
                        if (
                            event.originalEvent.type == 'visibilitychange' &&
                            event.newState == 'hidden'
                        ) {
                            // if (event.originalEvent.type == 'pagehide' && event.newState == 'terminated') {
                            // var url = "https://example.com/foo";
                            // var data = "bar";
                            /*
                        var fd = new FormData();
                        fd.append('ajax_data', 22);
                         */
                            // navigator.sendBeacon(url, data);
                        }
                    }
                })
                // https://stackoverflow.com/questions/6162188/javascript-browsers-window-close-send-an-ajax-request-or-run-a-script-on-win
            },
            triggerEvent: (name) => {
                const evt = eventsList.filter(
                    (evtlst) => evtlst.name === name
                )[0]
                if (isDefined(evt, true, true)) {
                    /*
                sendKafkaEvent(evt).then((res) => {
                    console.log('KAFKA : sended > ', res)
                    treatEventCallback(evt, res.data);
                })
                */
                }
            },
        }

        const treatEventCallback = (evt, response) => {
            switch (evt.type) {
                case 'DISPATCH_EVENT_DEFAULT':
                //
            }
        }
        enhancedActions.initEventListeners()

        return nextApp(
            { ...initialState, ...enhancedState },
            { ...initialActions, ...enhancedActions },
            initialView,
            initialRoot
        )
    }

export { withKafkaEvents }
