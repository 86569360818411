import { t } from 'i18next'
import { h } from 'hyperapp'
import {
    isDefined,
    createUTCDate,
    getDiffDate,
    markedDownContent,
} from '@app/core'
import {
    Card,
    Button,
    Form,
    FormInput,
    Tile,
    Enter,
    Calendar,
    Modal,
} from '@app/elements'
import * as constants from '@app/constants'
import { ModalAppBanner } from './modals/modalView-appBanner-default'

import bgBgSurveyx2 from '@app/img/bg/custo/resilience/bg-ask-small@2x.png'
import bgSymptomsx2 from '@app/img/bg/custo/resilience/bg-symptom@2x.png'
import bgGoFurtherx2 from '@app/img/bg/custo/resilience/bg-go-further@2x.png'

import icoAnsweredSurvey from '@app/img/ico/custo/resilience/ico-answered-survey.png'
import icoAnsweredSurveyx2 from '@app/img/ico/custo/resilience/ico-answered-survey@2x.png'

import icoBackBlue from '@app/img/ico/ico-back-darkblue.png'
import icoBackBluex2 from '@app/img/ico/ico-back-darkblue@2x.png'
import { dateTimeFormat } from '@app/core/tools/intl/dateTimeFormat'
import { dateTimeEndingZ } from '@app/core/tools/intl/dateTimeEndingZ'
import { dateTimeUserTimezone } from '@app/core/tools/intl/dateTimeUserTimezone'

export default {
    initView: function () {
        let view = (state, actions) => (props, children) => (
            <div class="timeline-central timeline-central-head">
                {isDefined(state.detailCard) ? (
                    <div
                        class={
                            'btzDetailCard ' +
                            (state.detailCard.type === 61
                                ? 'detailLibrary'
                                : 'detailSymptom')
                        }
                    >
                        <div class="btzDetailCardWrapper btzDetailCardContentWrapper">
                            <div class="btzDetailCardContent">
                                <img
                                    class="btzDetailCardBack"
                                    onclick={(e) => {
                                        if (isDefined(state.detailCardChild)) {
                                            actions.setDetailCardChild(null)
                                            actions.resetScrollTop()
                                        } else {
                                            actions.setDetailCard(null)
                                            props.updateHeaderColor('blue')
                                            props.updatePageLevel('timeline')
                                        }
                                    }}
                                    onkeydown={(e) => {
                                        if (isDefined(state.detailCardChild)) {
                                            actions.setDetailCardChild(null)
                                            actions.resetScrollTop()
                                        } else {
                                            actions.setDetailCard(null)
                                            props.updateHeaderColor('blue')
                                            props.updatePageLevel('timeline')
                                        }
                                    }}
                                    src={icoBackBlue}
                                    srcset={`${icoBackBluex2} 2x`}
                                    alt=""
                                />
                                <p class="btzDetailCardContent-btzDetailCardContentTitle">
                                    {state.detailCard.title}
                                </p>
                                <div
                                    class="btzDetailCardContent-btzDetailCardContentDescription"
                                    innerHTML={markedDownContent(
                                        state.detailCard.description
                                    )}
                                ></div>
                            </div>
                        </div>
                        {!isDefined(state.detailCardChild) ? (
                            <div class="btzDetailCardWrapper btzDetailCardChildrensWrapper">
                                <div class="btzDetailCardChildrens">
                                    {state.detailCard.children.map(
                                        (child, idx) => (
                                            <div
                                                class="library"
                                                style={{
                                                    'cursor': 'pointer',
                                                    'background-image':
                                                        'url(' +
                                                        (state.detailCard
                                                            .type === 61
                                                            ? bgGoFurtherx2
                                                            : bgSymptomsx2) +
                                                        ')',
                                                }}
                                                onclick={(e) => {
                                                    actions.displayDetailCardChild(
                                                        {
                                                            childid: child.id,
                                                            parentid: null,
                                                            kind: state
                                                                .detailCard
                                                                .type,
                                                            idx: idx,
                                                        }
                                                    )
                                                    props.updatePageLevel(
                                                        'subchild'
                                                    )
                                                    actions.resetScrollTop()
                                                }}
                                                onkeydown={(e) => {
                                                    actions.displayDetailCardChild(
                                                        {
                                                            childid: child.id,
                                                            parentid: null,
                                                            kind: state
                                                                .detailCard
                                                                .type,
                                                            idx: idx,
                                                        }
                                                    )
                                                    props.updatePageLevel(
                                                        'subchild'
                                                    )
                                                    actions.resetScrollTop()
                                                }}
                                            >
                                                <div
                                                    class="libraryImg"
                                                    style={{
                                                        'background-image':
                                                            'url(' +
                                                            (isDefined(
                                                                child.media
                                                            )
                                                                ? child.media
                                                                      .url
                                                                : null) +
                                                            ')',
                                                    }}
                                                ></div>
                                                <div class="libraryContent">
                                                    <p class="libraryContentTitle">
                                                        {child.title}
                                                    </p>
                                                    <p class="libraryContentButton">
                                                        {t('Appuyez ici', {
                                                            ns: 'timeline',
                                                        })}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div class="btzDetailCardWrapper btzDetailCardChildrenWrapper">
                                <img
                                    class="btzDetailCardBack show-xs"
                                    onclick={(e) => {
                                        if (isDefined(state.detailCardChild)) {
                                            actions.setDetailCardChild(null)
                                            props.updatePageLevel('child')
                                        } else {
                                            actions.setDetailCard(null)
                                            props.updateHeaderColor('blue')
                                        }
                                        actions.resetScrollTop()
                                    }}
                                    onkeydown={(e) => {
                                        if (isDefined(state.detailCardChild)) {
                                            actions.setDetailCardChild(null)
                                            props.updatePageLevel('child')
                                        } else {
                                            actions.setDetailCard(null)
                                            props.updateHeaderColor('blue')
                                        }
                                        actions.resetScrollTop()
                                    }}
                                    src={icoBackBlue}
                                    srcset={`${icoBackBluex2} 2x`}
                                    alt=""
                                />
                                <div class="btzDetailCardChildren">
                                    <div
                                        class={
                                            'libraryContent ' +
                                            (state.detailCard.type === 61
                                                ? 'childLibrary'
                                                : 'childSymptom')
                                        }
                                    >
                                        <p class="libraryContentTitle">
                                            {state.detailCardChild.title}
                                        </p>
                                        <div
                                            class="libraryMarkdownContent"
                                            innerHTML={markedDownContent(
                                                state.detailCardChild
                                                    .description
                                            )}
                                        ></div>
                                    </div>
                                    <div class="btzDetailCardChildrenFooter">
                                        {state.idxCardChild > 0 ? (
                                            <div
                                                class="btzDetailCardChildrenFooterButton btzDetailCardChildrenFooterButtonLeft"
                                                onclick={() => {
                                                    actions.displayDetailCardChild(
                                                        {
                                                            childid: null,
                                                            parentid:
                                                                state.detailCard
                                                                    .id,
                                                            kind: state
                                                                .detailCard
                                                                .type,
                                                            idx:
                                                                state.idxCardChild -
                                                                1,
                                                        }
                                                    )
                                                    actions.resetScrollTop()
                                                }}
                                                onkeydown={() => {
                                                    actions.displayDetailCardChild(
                                                        {
                                                            childid: null,
                                                            parentid:
                                                                state.detailCard
                                                                    .id,
                                                            kind: state
                                                                .detailCard
                                                                .type,
                                                            idx:
                                                                state.idxCardChild -
                                                                1,
                                                        }
                                                    )
                                                    actions.resetScrollTop()
                                                }}
                                            >
                                                {'< ' + t('Précédent')}
                                            </div>
                                        ) : (
                                            <div class="btzDetailCardChildrenFooterButton btzDetailCardChildrenFooterButtonLeft"></div>
                                        )}
                                        {state.idxCardChild + 1 <
                                        state.detailCard.children.length ? (
                                            <div
                                                class="btzDetailCardChildrenFooterButton show-xs btzDetailCardChildrenFooterButtonRight"
                                                onclick={() => {
                                                    actions.displayDetailCardChild(
                                                        {
                                                            childid: null,
                                                            parentid:
                                                                state.detailCard
                                                                    .id,
                                                            kind: state
                                                                .detailCard
                                                                .type,
                                                            idx:
                                                                state.idxCardChild +
                                                                1,
                                                        }
                                                    )
                                                    actions.resetScrollTop()
                                                }}
                                                onkeydown={() => {
                                                    actions.displayDetailCardChild(
                                                        {
                                                            childid: null,
                                                            parentid:
                                                                state.detailCard
                                                                    .id,
                                                            kind: state
                                                                .detailCard
                                                                .type,
                                                            idx:
                                                                state.idxCardChild +
                                                                1,
                                                        }
                                                    )
                                                    actions.resetScrollTop()
                                                }}
                                            >
                                                {t('Suivant') + ' >'}
                                            </div>
                                        ) : (
                                            <div class="btzDetailCardChildrenFooterButton show-xs btzDetailCardChildrenFooterButtonRight"></div>
                                        )}
                                        <Button
                                            flat
                                            cancel
                                            bgImg={bgSymptomsx2}
                                            onclick={() => {
                                                actions.setDetailCardChild(null)
                                                props.updatePageLevel('child')
                                                actions.resetScrollTop()
                                            }}
                                        >
                                            {t('Fermer', { ns: 'generals' })}
                                        </Button>
                                        {state.idxCardChild + 1 <
                                        state.detailCard.children.length ? (
                                            <div
                                                class="btzDetailCardChildrenFooterButton hide-xs btzDetailCardChildrenFooterButtonRight"
                                                onclick={() => {
                                                    actions.displayDetailCardChild(
                                                        {
                                                            childid: null,
                                                            parentid:
                                                                state.detailCard
                                                                    .id,
                                                            kind: state
                                                                .detailCard
                                                                .type,
                                                            idx:
                                                                state.idxCardChild +
                                                                1,
                                                        }
                                                    )
                                                    actions.resetScrollTop()
                                                }}
                                                onkeydown={() => {
                                                    actions.displayDetailCardChild(
                                                        {
                                                            childid: null,
                                                            parentid:
                                                                state.detailCard
                                                                    .id,
                                                            kind: state
                                                                .detailCard
                                                                .type,
                                                            idx:
                                                                state.idxCardChild +
                                                                1,
                                                        }
                                                    )
                                                    actions.resetScrollTop()
                                                }}
                                            >
                                                {t('Suivant') + ' >'}
                                            </div>
                                        ) : (
                                            <div class="btzDetailCardChildrenFooterButton hide-xs btzDetailCardChildrenFooterButtonRight"></div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <dummy>
                        <Card
                            classes={'btzPageCard btzBackgroundCover'}
                            kind={'datas'}
                            style={{ 'padding-top': '108px' }}
                        >
                            <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                                <Enter
                                    time={constants.custo.ANIM_DURATION}
                                    easing={constants.custo.ANIM_EASING}
                                    css={constants.custo.ANIM_CSS}
                                >
                                    <dummy>
                                        <Tile>
                                            <Tile.Content>
                                                <div class="timelineHeader">
                                                    {isDefined(state.user) && (
                                                        <p class="btzTitle btzXl">
                                                            {t('Bonjour', {
                                                                ns: 'timeline',
                                                            })}
                                                        </p>
                                                    )}
                                                    {Array.isArray(
                                                        state.surveys
                                                    ) &&
                                                    isDefined(
                                                        state.surveys.filter(
                                                            (f) =>
                                                                !isDefined(
                                                                    f.answerDate
                                                                ) ||
                                                                !isDefined(
                                                                    f.answerDate
                                                                        .date
                                                                )
                                                        )[0]
                                                    ) ? (
                                                        <p class="btzTitle">
                                                            {t('Vous avez', {
                                                                ns: 'timeline',
                                                            }) + ' '}
                                                            <font>
                                                                {state.surveys.filter(
                                                                    (f) =>
                                                                        !isDefined(
                                                                            f.answerDate
                                                                        ) ||
                                                                        !isDefined(
                                                                            f
                                                                                .answerDate
                                                                                .date
                                                                        )
                                                                ).length +
                                                                    ' ' +
                                                                    (state.surveys.filter(
                                                                        (f) =>
                                                                            !isDefined(
                                                                                f.answerDate
                                                                            ) ||
                                                                            !isDefined(
                                                                                f
                                                                                    .answerDate
                                                                                    .date
                                                                            )
                                                                    ).length > 1
                                                                        ? t(
                                                                              'questionnaires',
                                                                              {
                                                                                  ns: 'timeline',
                                                                              }
                                                                          )
                                                                        : t(
                                                                              'questionnaire',
                                                                              {
                                                                                  ns: 'timeline',
                                                                              }
                                                                          ))}
                                                            </font>
                                                            {' ' +
                                                                t(
                                                                    'à compléter aujourd’hui.',
                                                                    {
                                                                        ns: 'timeline',
                                                                    }
                                                                )}
                                                        </p>
                                                    ) : (
                                                        <p class="btzTitle">
                                                            {Array.isArray(
                                                                state.surveys
                                                            ) &&
                                                            isDefined(
                                                                state.surveys.filter(
                                                                    (f) =>
                                                                        isDefined(
                                                                            f.answerDate
                                                                        ) &&
                                                                        isDefined(
                                                                            f
                                                                                .answerDate
                                                                                .date
                                                                        )
                                                                )[0]
                                                            ) &&
                                                            getDiffDate(
                                                                createUTCDate(
                                                                    state.surveys.filter(
                                                                        (f) =>
                                                                            isDefined(
                                                                                f.answerDate
                                                                            ) &&
                                                                            isDefined(
                                                                                f
                                                                                    .answerDate
                                                                                    .date
                                                                            )
                                                                    )[0]?.date
                                                                ),
                                                                createUTCDate()
                                                            ) <= 0
                                                                ? t(
                                                                      "Vous n'avez plus de questionnaire à compléter.",
                                                                      {
                                                                          ns: 'timeline',
                                                                      }
                                                                  )
                                                                : t(
                                                                      "Vous n'avez pas de questionnaire à compléter.",
                                                                      {
                                                                          ns: 'timeline',
                                                                      }
                                                                  )}
                                                        </p>
                                                    )}
                                                </div>
                                            </Tile.Content>
                                        </Tile>
                                    </dummy>
                                </Enter>
                            </Card.Header>
                        </Card>
                        {Array.isArray(state.surveys) &&
                            state.surveys.filter(
                                (f) => !isDefined(f.answerDate)
                            ).length > 0 && (
                                <Card
                                    classes={
                                        'btzPageCard btzBackgroundCover btzWhiteBlock'
                                    }
                                    kind={'datas'}
                                >
                                    <div class="btzTimelineCardsWrapper">
                                        {state.surveys
                                            .filter(
                                                (f) => !isDefined(f.answerDate)
                                            )
                                            .map((survey, idx) => (
                                                <div
                                                    key={'survey-' + idx}
                                                    class="col-4 col-md-6 col-sm-12"
                                                >
                                                    <div
                                                        class="survey survey-running"
                                                        onclick={(e) => {
                                                            actions.redirectToSurvey(
                                                                survey
                                                            )
                                                            actions.resetScrollTop()
                                                        }}
                                                        onkeydown={(e) => {
                                                            actions.redirectToSurvey(
                                                                survey
                                                            )
                                                            actions.resetScrollTop()
                                                        }}
                                                        style={{
                                                            'background-image':
                                                                'url(' +
                                                                bgBgSurveyx2 +
                                                                ')',
                                                            'cursor': 'pointer',
                                                        }}
                                                    >
                                                        <div class="surveyContent">
                                                            <p class="surveyContentTitle">
                                                                {t(
                                                                    'Mesurer mes symptômes'
                                                                )}
                                                            </p>
                                                            <p class="surveyContentSubTitle">
                                                                {t(
                                                                    'Durée estimée',
                                                                    {
                                                                        ns: 'timeline',
                                                                    }
                                                                ) +
                                                                    ' : ' +
                                                                    Math.round(
                                                                        survey.countCard /
                                                                            3
                                                                    ) +
                                                                    ' ' +
                                                                    t('min', {
                                                                        ns: 'generals',
                                                                    })}
                                                            </p>
                                                            <p
                                                                class="surveyContentButton"
                                                                style="margin-top: 10px;"
                                                            >
                                                                {t(
                                                                    'Appuyez ici',
                                                                    {
                                                                        ns: 'timeline',
                                                                    }
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </Card>
                            )}
                        {state.symptoms.filter((f) => !isDefined(f.parent))
                            .length > 0 && (
                            <Card
                                classes={
                                    'btzPageCard btzBackgroundCover btzWhiteBlock rslcSymptom'
                                }
                                kind={'datas'}
                            >
                                {['unicancer', 'uroconnect'].indexOf(
                                    props.customer
                                ) > -1 ? (
                                    <div class="btzWhiteBlockHeader">
                                        <p class="btzWhiteBlockHeaderTitle">
                                            {t('Fiches à consulter', {
                                                ns: 'timeline',
                                            })}
                                        </p>
                                        <p class="btzWhiteBlockHeaderSubtitle">
                                            {t(
                                                'Consultez ici des fiches pour vous accompagner au mieux dans votre suivi.',
                                                { ns: 'timeline' }
                                            )}
                                        </p>
                                    </div>
                                ) : (
                                    <div class="btzWhiteBlockHeader">
                                        <p class="btzWhiteBlockHeaderTitle">
                                            {t('Fiches symptômes', {
                                                ns: 'timeline',
                                            })}
                                        </p>
                                        <p class="btzWhiteBlockHeaderSubtitle">
                                            {t(
                                                'Voici le contenu adapté pour vous, en fonction de vos symptômes.',
                                                { ns: 'timeline' }
                                            )}
                                        </p>
                                    </div>
                                )}
                                <div class="btzTimelineCardsWrapper btzTimelineSymptomWrapper">
                                    {state.symptoms
                                        .filter((f) => !isDefined(f.parent))
                                        .map((symptom, idx) => (
                                            <div
                                                key={'symptom-' + idx}
                                                class="col-4 col-md-6 col-sm-12 symptomBg"
                                                onclick={(e) => {
                                                    actions.displaySymptom(
                                                        symptom.id
                                                    )
                                                    props.updateHeaderColor(
                                                        'red'
                                                    )
                                                    props.updatePageLevel(
                                                        'child'
                                                    )
                                                    actions.resetScrollTop()
                                                }}
                                                onkeydown={(e) => {
                                                    actions.displaySymptom(
                                                        symptom.id
                                                    )
                                                    props.updateHeaderColor(
                                                        'red'
                                                    )
                                                    props.updatePageLevel(
                                                        'child'
                                                    )
                                                    actions.resetScrollTop()
                                                }}
                                            >
                                                <div
                                                    class="symptom"
                                                    style={{
                                                        'background-image':
                                                            'url(' +
                                                            bgSymptomsx2 +
                                                            ')',
                                                        'cursor': 'pointer',
                                                    }}
                                                >
                                                    <div
                                                        class="symptomImg"
                                                        style={{
                                                            'background-image':
                                                                'url(' +
                                                                (isDefined(
                                                                    symptom.media
                                                                )
                                                                    ? symptom
                                                                          .media
                                                                          .url
                                                                    : null) +
                                                                ')',
                                                        }}
                                                    ></div>
                                                    <div class="symptomContent">
                                                        <p class="symptomContentTitle">
                                                            {symptom.title}
                                                        </p>
                                                        <p class="symptomContentButton">
                                                            {t('Appuyez ici', {
                                                                ns: 'timeline',
                                                            })}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </Card>
                        )}
                        {state.libraries.filter((f) => !isDefined(f.parent))
                            .length > 0 && (
                            <Card
                                classes={
                                    'btzPageCard btzBackgroundCover btzWhiteBlock rslcGoFurther'
                                }
                                kind={'datas'}
                            >
                                <div class="btzWhiteBlockHeader">
                                    <p class="btzWhiteBlockHeaderTitle">
                                        {t('Pour aller plus loin...', {
                                            ns: 'timeline',
                                        })}
                                    </p>
                                    <p class="btzWhiteBlockHeaderSubtitle">
                                        {t(
                                            'Approfondissez vos connaissances sur votre pathologie, les traitements et les soins de support.',
                                            { ns: 'timeline' }
                                        )}
                                    </p>
                                </div>
                                <div class="btzTimelineCardsWrapper">
                                    {state.libraries
                                        .filter((f) => !isDefined(f.parent))
                                        .map((library, idx) => (
                                            <div
                                                key={'library-' + idx}
                                                class="col-4 col-md-6 col-sm-12"
                                                onclick={(e) => {
                                                    actions.displayLibrary(
                                                        library.id
                                                    )
                                                    props.updateHeaderColor(
                                                        'blue'
                                                    )
                                                    props.updatePageLevel(
                                                        'child'
                                                    )
                                                    actions.resetScrollTop()
                                                }}
                                                onkeydown={(e) => {
                                                    actions.displayLibrary(
                                                        library.id
                                                    )
                                                    props.updateHeaderColor(
                                                        'blue'
                                                    )
                                                    props.updatePageLevel(
                                                        'child'
                                                    )
                                                    actions.resetScrollTop()
                                                }}
                                            >
                                                <div
                                                    class="library more-over"
                                                    style={{
                                                        'background-image':
                                                            'url(' +
                                                            bgGoFurtherx2 +
                                                            ')',
                                                        'cursor': 'pointer',
                                                    }}
                                                >
                                                    <div class="libraryContent">
                                                        <p class="libraryContentTitle">
                                                            {library.title}
                                                        </p>
                                                        <div
                                                            class="libraryContentSubTitle"
                                                            innerHTML={markedDownContent(
                                                                library.description
                                                            )}
                                                            style="height: 60px;"
                                                        ></div>
                                                        <p
                                                            class="libraryContentButton"
                                                            style="margin-top: 10px;"
                                                        >
                                                            {t('Appuyez ici', {
                                                                ns: 'timeline',
                                                            })}
                                                        </p>
                                                    </div>
                                                    <div
                                                        class="libraryImg"
                                                        style={{
                                                            'background-image':
                                                                'url(' +
                                                                (isDefined(
                                                                    library.media
                                                                )
                                                                    ? library
                                                                          .media
                                                                          .url
                                                                    : null) +
                                                                ')',
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </Card>
                        )}
                        {Array.isArray(state.surveys) &&
                            state.surveys.filter((f) => isDefined(f.answerDate))
                                .length > 0 && (
                                <Card
                                    classes={
                                        'btzPageCard btzBackgroundCover btzWhiteBlock btzPageSurveyAnswered'
                                    }
                                    kind={'datas'}
                                    style={{ 'text-align': 'center' }}
                                >
                                    <div class="btzWhiteBlockHeader">
                                        <p class="btzWhiteBlockHeaderTitle">
                                            {t('Questionnaires répondus', {
                                                ns: 'timeline',
                                            })}
                                        </p>
                                        <p class="btzWhiteBlockHeaderSubtitle">
                                            {t(
                                                'Retrouvez ici les questionnaires complétés.',
                                                { ns: 'timeline' }
                                            )}
                                        </p>
                                    </div>
                                    <div class="btzTimelineCardsWrapper">
                                        {state.surveys
                                            .filter((f) =>
                                                isDefined(f.answerDate)
                                            )
                                            .map((survey, idx) => (
                                                <div
                                                    key={'survey-' + idx}
                                                    class="col-4 col-md-6 col-sm-12"
                                                >
                                                    <div
                                                        class="survey survey-done"
                                                        onclick={(e) => {
                                                            actions.redirectToSurvey(
                                                                survey
                                                            )
                                                        }}
                                                        onkeydown={(e) => {
                                                            actions.redirectToSurvey(
                                                                survey
                                                            )
                                                        }}
                                                        style="cursor: pointer;"
                                                    >
                                                        <div
                                                            class="surveyImg"
                                                            style={{
                                                                'background-image':
                                                                    'url(' +
                                                                    icoAnsweredSurveyx2 +
                                                                    ')',
                                                            }}
                                                        ></div>
                                                        <div class="surveyContent">
                                                            <p class="surveyContentTitle">
                                                                {t(
                                                                    'Mesurer mes symptômes'
                                                                )}
                                                            </p>
                                                            <p class="surveyContentSubTitle">
                                                                {t(
                                                                    'répondu le',
                                                                    {
                                                                        ns: 'timeline',
                                                                    }
                                                                ) +
                                                                    ' ' +
                                                                    dateTimeFormat(
                                                                        new Date(
                                                                            dateTimeEndingZ(
                                                                                survey
                                                                                    .answerDate
                                                                                    .date
                                                                            )
                                                                        ),
                                                                        {
                                                                            day: '2-digit',
                                                                            month: '2-digit',
                                                                            year: 'numeric',
                                                                            timeZone:
                                                                                dateTimeUserTimezone(),
                                                                        }
                                                                    ) +
                                                                    ',' +
                                                                    ' ' +
                                                                    t('à', {
                                                                        ns: 'generals',
                                                                    }) +
                                                                    ' ' +
                                                                    dateTimeFormat(
                                                                        new Date(
                                                                            dateTimeEndingZ(
                                                                                survey
                                                                                    .answerDate
                                                                                    .date
                                                                            )
                                                                        ),
                                                                        {
                                                                            hour: '2-digit',
                                                                            minute: '2-digit',
                                                                            timeZone:
                                                                                dateTimeUserTimezone(),
                                                                        }
                                                                    )}
                                                            </p>
                                                            {survey.answerAgainToday &&
                                                                survey.answerAgainTomorrow && (
                                                                    <p
                                                                        class="symptomContentButton"
                                                                        style="margin-top: 10px;"
                                                                    >
                                                                        {t(
                                                                            'Répondre à nouveau',
                                                                            {
                                                                                ns: 'timeline',
                                                                            }
                                                                        )}
                                                                    </p>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </Card>
                            )}
                    </dummy>
                )}
                {state.appBannerDisplayed && (
                    <div class="ModalAppBanner">
                        <Modal
                            key={'modal-doctor-email'}
                            cancelAction={actions.closeBanner}
                            modalDisplayed={true}
                            content={
                                <ModalAppBanner
                                    {...state}
                                    _retrieveInput={actions._retrieveInput}
                                    closeModal={actions.closeBanner}
                                ></ModalAppBanner>
                            }
                        ></Modal>
                    </div>
                )}
            </div>
        )
        return view
    },
}
