import { h } from 'hyperapp'
import { Component } from '@app/utils'

import { Grid, Enter } from '@app/elements'
import * as constants from '@app/constants'

import { settings } from '@app/core/settings'

import { UserMenu } from '@app/layouts/usermenu'

import { BotFooterView } from '@app/layouts/botfooter'

import './index.scss'

const actions = {
    goBack: () => (state) => {
        window.history.back()
    },
}

const { APP_CUSTOMISATION, APP_WEB_URL } = settings

const view = (state, actions) => (props, children) => (
    <div
        key={'register'}
        class={props.page}
        style={{
            'background-image':
                sessionStorage.getItem('customDisplay') === 'popup'
                    ? 'none'
                    : "''",
        }}
    >
        <Enter
            time={constants.custo.ANIM_DURATION}
            easing={constants.custo.ANIM_EASING}
            css={constants.custo.ANIM_CSS}
        >
            <Grid classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign'}>
                <Grid.Row
                    classes={
                        'btzWrapper-verticalAlignContent' +
                        (props.howthatwork
                            ? ' btzWrapper-verticalAlignContent-minHeight'
                            : '')
                    }
                >
                    <Grid.Col
                        x={12}
                        mx={'auto'}
                        style={{
                            position: 'relative',
                            height: '100%',
                            padding: '0px',
                        }}
                    >
                        <dummy>
                            <img
                                class={
                                    (APP_CUSTOMISATION.toLowerCase() ===
                                    'resilience'
                                        ? 'show-xs'
                                        : '') + ' thfCustomerLogo'
                                }
                                style="max-width: 140px;"
                                src={`${APP_WEB_URL}/__logo/${props.customer.toLowerCase()}/logo-blue.png`}
                                srcset={`${APP_WEB_URL}/__logo/${props.customer.toLowerCase()}/logo-blue@2x.png`}
                                alt=""
                            />
                            {APP_CUSTOMISATION.toLowerCase() ===
                                'resilience' && (
                                <img
                                    class="hide-xs thfCustomerLogo"
                                    style="max-width: 140px;"
                                    src={`${APP_WEB_URL}/__logo/${props.customer.toLowerCase()}/logo-white.png`}
                                    srcset={`${APP_WEB_URL}/__logo/${props.customer.toLowerCase()}/logo-white@2x.png`}
                                    alt=""
                                />
                            )}
                        </dummy>
                        <UserMenu display={'login'}></UserMenu>
                        {children}
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
)

export default Component({}, actions, view, 'layout')
