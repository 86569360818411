import { h } from 'hyperapp'
import { t } from 'i18next'
import { convertMetrics } from '@app/core'

const Convert = (props, children) => {
    let parsedChildren = children
    if (props.type !== null) {
        switch (props.type) {
            case 'minuteAndHour': {
                let minutes = Math.round(+parsedChildren % 60)
                let hour = Math.floor(+parsedChildren / 60)

                if (+hour === 0 && +minutes === 0) {
                    hour = ''
                }

                if (minutes < 10) {
                    minutes = '0' + minutes
                }
                if (props.kind && props.kind === 'smart') {
                    //
                } else {
                    minutes = minutes + t('min', { ns: 'generals' })
                }

                if (hour > 0) {
                    hour = hour + t('h', { ns: 'generals' })
                }
                parsedChildren = hour + minutes
                break
            }
            case 'date': {
                if (parsedChildren.length > 0) {
                    let date = parsedChildren[0].date || null
                    if (date !== null) {
                        let newDate = new Date(Date.parse(new Date(date)))
                        let options = {
                            month: 'numeric',
                            day: 'numeric',
                            year: 'numeric',
                        }
                        parsedChildren = newDate.toLocaleDateString(
                            'fr-FR',
                            options
                        )
                    }
                }
                break
            }
            case 'nl2br': {
                if (
                    typeof parsedChildren === 'undefined' ||
                    parsedChildren === null
                ) {
                    return ''
                }
                var breakTag =
                    props.is_xhtml || typeof props.is_xhtml === 'undefined'
                        ? '<br />'
                        : '<br>'
                let separator = props.separator ? props.separator : ''
                parsedChildren = (separator + parsedChildren + '').replace(
                    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                    '$1' + breakTag + '$2' + separator
                )
                break
            }
            case 'capitalize': {
                if (parsedChildren[0] !== undefined) {
                    parsedChildren =
                        parsedChildren[0].charAt(0).toUpperCase() +
                        parsedChildren[0].slice(1).toLowerCase()
                }
                break
            }
            case 'étages':
            case 'pas':
            case 'points':
            case 'cigarettes':
            case 'verres': {
                parsedChildren = parsedChildren + ' ' + (props.type || '')
                break
            }
            case 'kg':
            case 'cm':
            case 'height':
            case 'weight': {
                parsedChildren = convertMetrics(
                    props.from,
                    props.type,
                    props.userMetric,
                    parsedChildren
                )
                break
            }
            default: {
                parsedChildren = parsedChildren + '' + (props.type || '')
                break
            }
        }
    }

    return parsedChildren
}

export { Convert }
