import {
    addCSRFToken,
    isDefined,
    allRoutes,
    createAndSaveCSRFToken,
    getTodayYMD,
    getUser,
} from '@app/core'

const redirectToOnboarding = function (userid, date, timelineid = null) {
    if (!isDefined(userid)) {
        userid = 'null'
    }
    // let viewed = localStorage.getItem('onboarding-viewed-' + userid + '-' + date);
    // if (!isDefined(timelineid) && ((viewed === true) || (viewed === 'true'))) {
    //     window.location.href = addCSRFToken(allRoutes['private.timeline'].pathname);
    // } else {
    window.location.href = addCSRFToken(
        allRoutes['private.onboarding'].pathname +
            (isDefined(timelineid) ? '?timelineid=' + timelineid : '')
    )
    // }
}

const redirectToGoodIndex = function () {
    createAndSaveCSRFToken()
    let userconsent = localStorage.getItem('userconsent')
    //
    // userconsent = true;
    //
    if (userconsent || userconsent === 'true') {
        localStorage.removeItem('userconsent')
        let directAccess = localStorage.getItem('directAccess')
        if (directAccess == true || directAccess === 'true') {
            window.location.href = addCSRFToken(
                allRoutes['private.timeline'].pathname
            )
        } else {
            let timelineId = localStorage.getItem('timelineId')
            let userCallback = (sessionUser) => {
                redirectToOnboarding(sessionUser.id, getTodayYMD(), timelineId)
            }
            getUser(userCallback, true)
        }
    } else {
        window.location.href = addCSRFToken(
            allRoutes['private.consent'].pathname
        )
    }
}

export { redirectToOnboarding, redirectToGoodIndex }
