import { t } from 'i18next'
import {
    redirectToOnboarding,
    getTodayYMD,
    createUTCDate,
    displayErrors,
    isDefined,
    getQueryVariable,
    logoutAndRedirect,
    allRoutes,
} from '@app/core'
import { User, Cardline } from '@app/api'

import { handleErrors } from '@app/api/errors'
import { handleHeaders } from '@app/api/headers'
import { Loader } from '@app/api/loader'
import {
    Locale,
    getLocalStoredLocale,
    setLocaleInLocalStorage,
} from '@app/core/languagesHandler'
import { updateLocaleInUrl } from '@app/core/tools/localeInUrl'

const API_MAITENANCE_ENABLED_KEY = 'api-maintenance-enabled'

const executeSynchedQuery = (
    url,
    method = 'POST',
    ctype = 'application/json',
    withToken = true,
    datas = null
) => {
    var request = new XMLHttpRequest()
    request.open(method, url, false)
    request.setRequestHeader('Content-Type', ctype)
    if (withToken) {
        request.setRequestHeader('X-Token', localStorage.getItem('apitoken'))
    }
    request.send(datas)
}
const executeQuery = async (
    url,
    method,
    ctype,
    withLoader,
    withHeader,
    withErrors,
    withToken,
    mustBeQueued,
    datas,
    withVersion
) => {
    let overrideapi = sessionStorage.getItem('overrideapi')
    if (isDefined(overrideapi) && overrideapi !== 'null') {
        if (url.indexOf('-release') > -1) {
            overrideapi = overrideapi.replace('-api', '-api-release')
        }
        if (url.indexOf('https') > -1) {
            url = url.replace(
                /(https:|)(^|\/\/)(.*?\/)/g,
                'https://' + overrideapi + '/'
            )
        } else {
            url = url.replace(
                /(http:|)(^|\/\/)(.*?\/)/g,
                'https://' + overrideapi + '/'
            )
        }
    }
    //
    let token = localStorage.getItem('apitoken')
    if (!isDefined(token)) {
        token = getQueryVariable('xtoken', false)
    }
    //
    if (withLoader) {
        Loader.addQuery()
    }
    let oHeader = null
    if (withToken) {
        if (ctype === null) {
            if (!isDefined(token)) {
                logoutAndRedirect()
            }
            oHeader = {
                'X-Token': token,
            }
        } else {
            if (!isDefined(token)) {
                logoutAndRedirect()
            }
            oHeader = {
                'X-Token': token,
                'Content-Type': ctype,
            }
        }
    } else if (ctype !== null) {
        oHeader = {
            'Content-Type': ctype,
        }
    }
    if (withVersion) {
        if (!isDefined(oHeader)) {
            oHeader = {}
        }
        oHeader['X-Version'] = process.env.APP_VERSION || 'N.A'
    }
    let oBody = null
    let totalSize = 0
    if (datas instanceof FormData) {
        oBody = datas
        for (var bod of oBody.entries()) {
            for (var entry in bod) {
                if (bod[entry].size !== undefined) {
                    totalSize += bod[entry].size
                }
            }
        }
    } else if (datas !== null) {
        oBody = JSON.stringify(datas)
    }
    if (totalSize > 2000000) {
        displayErrors(
            t(
                'Le contenue de la requête est supérieur à 2M, veuillez envoyer moins de donnée ou une image moins grande!'
            ),
            5000
        )
        return false
    }

    return await fetch(url, {
        method: method,
        headers: new Headers(oHeader),
        body: oBody,
    })
        .then((response) => {
            if (withLoader) {
                Loader.removeQuery()
            }
            return response
        })
        .then((response) => {
            if (withHeader) {
                return handleHeaders(response)
            } else {
                return response
            }
        })
        .then((response) => {
            if (response?.headers) {
                response.headers.forEach((value, key) => {
                    if (key.toLowerCase() === 'accept-language') {
                        const localeQuery = getQueryVariable('locale', false)
                        if (!localeQuery) {
                            const locale = getLocalStoredLocale()
                            if (value && locale && locale.lang !== value) {
                                const serverLocale = Locale.parseLocale(value)
                                //
                                setLocaleInLocalStorage(serverLocale.value)
                                updateLocaleInUrl({ updateUrl: true })
                                //
                                const event = new CustomEvent('changelang', {
                                    detail: { lang: serverLocale.lang },
                                })
                                dispatchEvent(event)
                            }
                        }
                    }
                })
            }
            return response
        })
        .then((response) => {
            if (response.status === 204) {
                return {}
            }
            if (response.status === 503) {
                // if the API sends this code that means we're in maintenance mode
                // the reload of the page will load the static maintenance page from the server
                // once the maintenance is over, a reload of the page will redirect to the login page if the session is expired
                // this is a bit brutal because all data not saved in forms will be lost but users are notified before so this shouldn't happen
                if (
                    sessionStorage.getItem(API_MAITENANCE_ENABLED_KEY) ===
                    'true'
                ) {
                    //prevent infinite loops if API is in maintenance but not the Front server
                    return
                }
                sessionStorage.setItem(API_MAITENANCE_ENABLED_KEY, 'true')
                window.location.reload()
            }
            return response.json()
        })
        .then((response) => {
            if (withErrors) {
                return handleErrors(response)
            } else {
                return response
            }
        })
        .then((data) => {
            return { data: data }
        })
        // .then(data => ({ data }))
        .catch((error) => {
            Loader.removeQuery()
            if (String(error).indexOf('fetch') > -1) {
                error = t(
                    'Erreur de communication avec le serveur, veuillez réessayer.'
                )
            }
            displayErrors(error, 2000)
            return { error: error }
        })
    //.catch(error => ({ error }))
}

let onceRefreshAtOnce = false
const TIMER = 1000
function sleeping() {
    var promise = new Promise(function (resolve) {
        window.setTimeout(function () {
            resolve('sleep done.')
        }, TIMER)
    })
    return promise
}

const queueQueries = async (
    url,
    method,
    ctype = 'application/json',
    withLoader = true,
    withHeader = true,
    withErrors = true,
    withToken = true,
    mustBeQueued = true,
    datas = null,
    withVersion = false
) => {
    let expiration = localStorage.getItem('expiration')
    if (expiration != false && expiration !== null && expiration !== 'null') {
        if (onceRefreshAtOnce) {
            return sleeping().then(() => {
                return queueQueries(
                    url,
                    method,
                    ctype,
                    withLoader,
                    withHeader,
                    withErrors,
                    withToken,
                    mustBeQueued,
                    datas,
                    withVersion
                )
            })
        } else {
            let expirationDate = expiration.split(' ')[0].split('-')
            let expirationTime = expiration
                .split(' ')[1]
                .split('.')[0]
                .split(':')
            let dateExpiration = new Date(
                Date.UTC(
                    expirationDate[0],
                    +expirationDate[1] - 1,
                    expirationDate[2],
                    expirationTime[0],
                    expirationTime[1],
                    expirationTime[2]
                )
            )
            let dateOffset = new Date().getTimezoneOffset()
            dateExpiration.setMinutes(dateExpiration.getMinutes() - dateOffset)

            let now = new Date()
            now.setMinutes(now.getMinutes() - dateOffset)
            if (dateExpiration.getTime() < now.getTime()) {
                return refreshSession()
            } else {
                return executeQuery(
                    url,
                    method,
                    ctype,
                    withLoader,
                    withHeader,
                    withErrors,
                    withToken,
                    mustBeQueued,
                    datas,
                    withVersion
                )
            }
        }
    } else {
        return executeQuery(
            url,
            method,
            ctype,
            withLoader,
            withHeader,
            withErrors,
            withToken,
            mustBeQueued,
            datas,
            withVersion
        )
    }
}

const refreshSession = (callback, sendtoOnboarding) => {
    let refreshToken = localStorage.getItem('refresh')
    refreshToken = false
    onceRefreshAtOnce = true

    let customer = localStorage.getItem('readablecustomer')
    if (refreshToken) {
        return User.refreshUser({ refresh: refreshToken }, customer).then(
            (res) => {
                onceRefreshAtOnce = false
                if (
                    res.data !== undefined &&
                    res.data.token &&
                    res.data.refresh
                ) {
                    localStorage.setItem('apitoken', res.data.token)
                    localStorage.setItem('refresh', res.data.refresh)
                    localStorage.setItem('expiration', res.data.expiration.date)
                    if (sendtoOnboarding) {
                        return Cardline.getCards([1, 22]).then((res) => {
                            if (res.data.length > 0) {
                                redirectToOnboarding(null, getTodayYMD())
                                // window.location.href = allRoutes['private.onboarding'].pathname;
                            } else {
                                return callback('success')
                            }
                        })
                    } else {
                        return callback('success')
                    }
                } else {
                    localStorage.removeItem('apitoken')
                    localStorage.removeItem('refresh')
                    localStorage.removeItem('expiration')
                    if (callback !== null) {
                        return callback(res)
                    } else {
                        window.location.href = allRoutes['index'].pathname
                    }
                    return false
                }
            }
        )
    } else {
        onceRefreshAtOnce = false
        localStorage.removeItem('apitoken')
        localStorage.removeItem('refresh')
        localStorage.removeItem('expiration')
        if (isDefined(callback)) {
            return callback('error')
        } else {
            window.location.href = allRoutes['index'].pathname
        }
        return false
    }
}

export { queueQueries }
export { executeSynchedQuery }
export { executeQuery }
export { refreshSession }
