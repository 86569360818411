import { t } from "i18next";
import { h } from "hyperapp";
import { Component } from "@app/utils";

import { Grid, Enter } from "@app/elements";
import * as constants from "@app/constants";

import { logoutAndRedirect } from "@app/core";
import { settings } from '@app/core/settings';

import icoLogout from "@app/img/ico/ico-logout.png";
import icoLogoutx2 from "@app/img/ico/ico-logout@2x.png";

const actions = {
	goBack: () => (state) => {
		window.history.back();
	},
};

const { APP_WEB_URL } = settings;

const view = (state, actions) => (props, children) =>
	(
		<div
			key={"onboarding"}
			class={props.page + " specificFullHeightMobile"}
			data-theme={props.theme}
			style={{
				"background-color":
					sessionStorage.getItem("customDisplay") === "popup"
						? "white"
						: "transparent",
			}}>
			<Enter
				time={constants.custo.ANIM_DURATION}
				easing={constants.custo.ANIM_EASING}
				css={constants.custo.ANIM_CSS}>
				<Grid
					classes={
						"btzWrapper btzWrapperFullHeight btzVerticalAlign specificFullHeightMobile"
					}>
					<Grid.Row
						classes={
							"btzWrapper-verticalAlignContent specificFullHeightMobile"
						}>
						<Grid.Col
							classes={
								"specificFullHeightMobile btzWrapper-onboardingWrapper"
							}
							x={12}
							mx={"auto"}
							style={{ position: "relative" }}>
							<img
								class="thfCustomerLogo"
								style="max-width: 140px;position: fixed;top: 17px;left: 100px;"
								src={`${
									APP_WEB_URL
								}/__logo/${props.customer.toLowerCase()}/logo-white.png`}
								srcset={`${
									APP_WEB_URL
								}/__logo/${props.customer.toLowerCase()}/logo-white@2x.png`}
								alt=""
							/>
							<div
								class="btzLogout"
								style="text-align: left;position: fixed;top: 19px;right: 60px;">
								<div
									style={{ display: "inline-block" }}
									onclick={() => {
										if (props.removeConfirmQuitEvent) {
											props.removeConfirmQuitEvent();
										}
										logoutAndRedirect();
									}}>
									<p>
										{t("me déconnecter", {
											ns: "generals",
										})}
									</p>
									<img
										style="vertical-align: middle; margin-left: 15px;"
										src={icoLogout}
										srcset={`${icoLogoutx2} 2x`}
										alt=""
										class=""
									/>
								</div>
							</div>
							{children}
						</Grid.Col>
					</Grid.Row>
				</Grid>
			</Enter>
		</div>
	);

export default Component({}, actions, view, "layout");
