import {
    Login,
    Index,
    Onboarding,
    Timeline,
    Consent,
} from '@app/modules';

const allRoutes = {
    'index': {
        label: 'Accueil',
        pathname: '/',
        component: Login,
        parent: false,
    },
    'index.reset': {
        label: 'Mot de passe',
        pathname: '/login/recovery',
        component: Login,
        parent: false,
    },
    'home.login.medical': {
        label: 'Login',
        pathname: '/medical-study/:medicalStudytoken/:timelineId/',
        component: Login,
        parent: false,
    },
    'private.onboarding': {
        label: 'Onboarding',
        pathname: '/onboarding',
        component: Onboarding,
        parent: false
    },
    'private.doneonboarding': {
        label: 'Onboarding',
        pathname: '/onboarding/done',
        component: Onboarding,
        parent: false
    },
    'private.timeline': {
        label: 'Timeline',
        pathname: '/timeline',
        component: Timeline,
        parent: false
    },
    'private.consent': {
        label: 'Consentement',
        pathname: '/consent',
        component: Consent,
        parent: false,
    },
    'pdf.cgu': {
        label: 'Voir les CGU',
        pathname: '/__pdf/pdf-cgu.pdf',
        component: null,
        parent: false
    },
    'pdf.oncoflow.cgu': {
        label: 'Voir les CGU',
        pathname: '/__pdf/oncoflow/pdf-cgu.pdf',
        component: null,
        parent: false
    },
    'pdf.unicancer.cgu': {
        label: 'Voir les CGU',
        pathname: '/__pdf/unicancer/pdf-cgu.pdf',
        component: null,
        parent: false
    },
    // 'private.programs.edit': {
    //     label: 'programs',
    //     pathname: '/content/programs/:id',
    //     component: Programs,
    //     parent: false,
    // },
};

export { allRoutes };
