import { t } from "i18next";
import { h } from "hyperapp";
import {
	Card,
	Button,
	Form,
	FormInput,
	Tile,
	Enter,
	Calendar,
	Modal,
} from "@app/elements";
import * as constants from "@app/constants";
import { BotFooterView } from "@app/layouts/botfooter";

import { ModalViewResetPWD } from "./../../modals/modalView-resetPwd-default";

import icoInfo from "@app/img/ico/ico-login-info.png";
import icoInfox2 from "@app/img/ico/ico-login-info@2x.png";
import icoInfoGrey from "@app/img/ico/custo/resilience/ico-login-info.png";
import icoInfoGreyx2 from "@app/img/ico/custo/resilience/ico-login-info@2x.png";

import icoTopMobile from "@app/img/ico/mobile/ico-login-mobile.png";
import icoTopMobilex2 from "@app/img/ico/mobile/ico-login-mobile@2x.png";

const ViewMonitoringConsent = (props, actions) => (
	<Enter
		time={constants.custo.ANIM_DURATION}
		easing={constants.custo.ANIM_EASING}
		css={constants.custo.ANIM_CSS}>
		<dummy>
			<Tile>
				<Tile.Content>
					{props.refusedMonitoring === true && (
						<div class="monitoringRefused">
							<p class="">
								{t(
									"Nous avons besoin de votre accord afin d’assurer votre suivi médical à distance via Resilience. Si vous souhaitez en savoir plus sur ce suivi, nous vous invitons à vous rapprocher de votre médecin prescripteur."
								)}
							</p>
						</div>
					)}
					<div class="loginHeader">
						<p class="btzTitle btzXl">
							{t("Consentement à la télésurveillance.", {
								ns: "login",
							})}
						</p>
						<p class="btzSubTitle">
							{t(
								"Conformément à la règlementation en vigueur, aucun acte de télésurveillance médicale ne peut être pratiqué sans le consentement libre et éclairé du patient. Ce consentement peut être retiré à tout moment en contactant votre équipe de soins avec comme conséquence de suspendre la télésurveillance médicale.",
								{ ns: "login" }
							)}
							<br />
							<br />
							{t(
								"Dans le cadre du traitement médicamenteux de mon cancer, mon médecin m’a prescrit un suivi médical à distance par télésurveillance via la Solution Resilience. J’ai été informé par mon médecin et par le personnel de l’établissement de santé de l’objectif médical de ce suivi, des objectifs du transfert de mes données médicales sur la plateforme de télésurveillance Resilience et des modalités techniques de l’envoi de ces données.",
								{ ns: "login" }
							)}
							<br />
							<br />
							{t("Aussi, je consens librement :", {
								ns: "login",
							})}
							<br />
							<br />
							<b style={{ fontSize: "16px" }}>
								{
									"• A bénéficier d’un acte médical de télésurveillance"
								}
							</b>
							<br />
							<br />
							<b style={{ fontSize: "16px" }}>
								{
									"• A l’hébergement de mes données par la société ECRITEL et par la société OVH,"
								}
							</b>
							{" " +
								"certifiées « Hébergeur de Données de Santé » et réunissant les critères de sécurité optimum pour garantir la sécurité et la confidentialité de l’hébergement des données."}
						</p>
					</div>
					<Form
						onsubmit={(event) => event.preventDefault()}
						autocomplete={"on"}
						style={{
							margin: "auto",
						}}>
						<button
							type="submit"
							disabled
							style="display: none"
							aria-hidden="true"></button>

						<Form.Group classes="btzForm-btzFooter">
							<Button
								flat
								cancel
								margin={"0 24px 0 0"}
								width={"calc(50% - 12px)"}
								onclick={() => {
									props.refuseMonitoring();
								}}>
								{t("Je refuse")}
							</Button>
							<Button
								flat
								active
								width={"calc(50% - 12px)"}
								onclick={() => {
									props.redirectUserToGoodIndex(true);
								}}>
								{t("J'accepte")}
							</Button>
						</Form.Group>
					</Form>
				</Tile.Content>
			</Tile>
		</dummy>
	</Enter>
);

export default ViewMonitoringConsent;
