import { Component } from '@app/utils'
import { h } from 'hyperapp'
import './index.scss'

import LoginLayout from '@app/layouts/register/layout'

import Authentication from './authentication'

const state = {
    medicadlInfos: null,
    sendMedicalInfos: null,
    medicalInfos: null,
    email: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null,
    },
    password: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null,
    },
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        actions.setSendMedicalInfos(props.retrieveMedicalInfos)
    },
    onComponentUpdate: (props) => (state, actions) => {
        actions.setMedicalInfosObject(props.medicalInfos)
    },
    setMedicalInfos: (medicalInfos) => (state, actions) => {
        state.sendMedicalInfos(medicalInfos)
    },
    setMedicalInfosObject: (newState) => (state) => ({
        medicalInfos: newState,
    }),
    setSendMedicalInfos: (newState) => (state) => ({
        sendMedicalInfos: newState,
    }),
    setState:
        ({ ns, key, value }) =>
        (state) => ({
            [ns]: { ...state[ns], ...{ [key]: value } },
        }),
}

const view =
    (state, actions) =>
    ({ location, match, customer, directAccess, withWelcome }, children) => (
        <div key={'login'} class="root-wrapper">
            <LoginLayout
                page={'login-root' + ' ' + 'layout-fullheight'}
                customer={customer}
                medicalInfos={state.medicalInfos}
            >
                {/* Default view */}
                <Authentication
                    initial={state.email}
                    customer={customer}
                    send={actions.setState}
                    setMedicalInfos={actions.setMedicalInfos}
                    match={match}
                    directAccess={directAccess}
                    withWelcome={withWelcome}
                />
            </LoginLayout>
        </div>
    )

export { Authentication }

export default Component(state, actions, view, 'login')
