export function flattenObject(obj) {
    if (typeof obj === 'object' && obj !== null) {
        var toReturn = {}

        for (var i in obj) {
            if (!Object.prototype.hasOwnProperty.call(obj, i)) continue

            if (typeof obj[i] == 'object' && obj[i] !== null) {
                var flatObject = flattenObject(obj[i])
                for (var x in flatObject) {
                    if (!Object.prototype.hasOwnProperty.call(flatObject, x))
                        continue

                    toReturn[`${i}.${x}`] = flatObject[x]
                }
            } else {
                toReturn[i] = obj[i]
            }
        }

        return toReturn
    }

    if (obj === null || obj === undefined || isNaN(obj)) {
        return undefined
    }

    return obj
}
