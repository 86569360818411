import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined, getYMDfromDateObj, getReadableDate, createUTCDate, getDiffDate, markedDownContent } from '@app/core';
import { Card, Button, Form, FormInput, Tile, Enter, Calendar } from '@app/elements';
import * as constants from '@app/constants';

import icoSurvey from '@app/img/ico/ico-survey.png';
import icoSurveyx2 from '@app/img/ico/ico-survey@2x.png';

import icoBgSurveyx2 from '@app/img/bg/bg-ask-small@2x.png';
import icoBgHomex2 from '@app/img/bg/bg-header-home@2x.png';
import icoBgDetailx2 from '@app/img/bg/bg-header-card@2x.png';

import icoSurveyDone from '@app/img/ico/ico-survey-done.png';
import icoSurveyDonex2 from '@app/img/ico/ico-survey-done@2x.png';

import bgSymptomsx2 from '@app/img/bg/bg-symptom@2x.png';
import bgSymptomsDetailx2 from '@app/img/deco/bg-detailSymptoms@2x.png';

import icoBackWhite from '@app/img/ico/ico-back-white.png';
import icoBackWhitex2 from '@app/img/ico/ico-back-white@2x.png';

import icoBackBlue from '@app/img/ico/ico-back-blue.png';
import icoBackBluex2 from '@app/img/ico/ico-back-blue@2x.png';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='timeline-central timeline-central-head'>
                {isDefined(state.detailCard) ?
                    <div class={'btzDetailCard ' + ((state.detailCard.type === 61) ? 'detailLibrary' : 'detailSymptom')}>
                        <div class='btzDetailCardWrapper btzDetailCardContentWrapper' style={{ 'background-image': 'url(' + ((state.detailCard.type === 61) ? icoBgHomex2 : icoBgDetailx2) + ')' }}>
                            <div class='btzDetailCardContent'>
                                <img class='btzDetailCardBack' onclick={(e) => { if (isDefined(state.detailCardChild)) { actions.setDetailCardChild(null); actions.resetScrollTop(); } else { actions.setDetailCard(null); props.updateHeaderColor('blue'); props.updatePageLevel('timeline'); } }} src={icoBackWhite} srcset={`${icoBackWhitex2} 2x`} alt='' />
                                <p class='btzDetailCardContent-btzDetailCardContentTitle'>{state.detailCard.title}</p>
                                <div class='btzDetailCardContent-btzDetailCardContentDescription' innerHTML={markedDownContent(state.detailCard.description)}></div>
                            </div>
                        </div>
                        {!isDefined(state.detailCardChild) ?
                            <div class='btzDetailCardWrapper btzDetailCardChildrensWrapper'>
                                <div class='btzDetailCardChildrens'>
                                    {state.detailCard.children.map((child, idx) =>
                                        <div class='library' style='cursor: pointer;' onclick={(e) => { actions.displayDetailCardChild({childid: child.id, parentid: null, kind: state.detailCard.type, idx: idx}); props.updatePageLevel('subchild'); actions.resetScrollTop(); }}>
                                            <div class='libraryImg' style={{ 'background-image': 'url(' + (isDefined(child.media) ? child.media.url : null) + ')' }}></div>
                                            <div class='libraryContent'>
                                                <p class='libraryContentTitle'>{child.title}</p>
                                                <p class='libraryContentButton'>{t('Appuyez ici', {ns: 'timeline'})}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            :
                            <div class='btzDetailCardWrapper btzDetailCardChildrenWrapper'>
                                <img class='btzDetailCardBack show-xs' onclick={(e) => { if (isDefined(state.detailCardChild)) { actions.setDetailCardChild(null); props.updatePageLevel('child'); } else { actions.setDetailCard(null); props.updateHeaderColor('blue'); } actions.resetScrollTop(); }} src={icoBackBlue} srcset={`${icoBackBluex2} 2x`} alt='' />
                                <div class='btzDetailCardChildren'>
                                    <div class={'libraryContent ' + ((state.detailCard.type === 61) ? 'childLibrary' : 'childSymptom')}>
                                        <p class='libraryContentTitle'>{state.detailCardChild.title}</p>
                                        <div class='libraryMarkdownContent' innerHTML={markedDownContent(state.detailCardChild.description)}></div>
                                    </div>
                                </div>
                                <div class='btzDetailCardChildrenFooter'>
                                    {(state.idxCardChild > 0) ?
                                        <div class='btzDetailCardChildrenFooterButton btzDetailCardChildrenFooterButtonLeft' onclick={() => { actions.displayDetailCardChild({childid: null, parentid: state.detailCard.id, kind: state.detailCard.type, idx: (state.idxCardChild - 1)}); actions.resetScrollTop(); }}>{'< ' + t('Précédent')}</div>
                                        :
                                        <div class='btzDetailCardChildrenFooterButton btzDetailCardChildrenFooterButtonLeft'></div>
                                    }
                                    {((state.idxCardChild + 1) < state.detailCard.children.length) ?
                                        <div class='btzDetailCardChildrenFooterButton show-xs btzDetailCardChildrenFooterButtonRight' onclick={() => { actions.displayDetailCardChild({childid: null, parentid: state.detailCard.id, kind: state.detailCard.type, idx: (state.idxCardChild + 1)}); actions.resetScrollTop(); }}>{t('Suivant') + ' >'}</div>
                                        :
                                        <div class='btzDetailCardChildrenFooterButton show-xs btzDetailCardChildrenFooterButtonRight'></div>
                                    }
                                    <Button primary active onclick={() => { actions.setDetailCardChild(null); props.updatePageLevel('child'); actions.resetScrollTop(); }}>
                                        {t('Fermer', {ns: 'generals'})}
                                    </Button>
                                    {((state.idxCardChild + 1) < state.detailCard.children.length) ?
                                        <div class='btzDetailCardChildrenFooterButton hide-xs btzDetailCardChildrenFooterButtonRight' onclick={() => { actions.displayDetailCardChild({childid: null, parentid: state.detailCard.id, kind: state.detailCard.type, idx: (state.idxCardChild + 1)}); actions.resetScrollTop(); }}>{t('Suivant') + ' >'}</div>
                                        :
                                        <div class='btzDetailCardChildrenFooterButton hide-xs btzDetailCardChildrenFooterButtonRight'></div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <dummy>
                        <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'} style={{ 'background-image': 'url(' + icoBgHomex2 + ')', 'padding-top': '108px', 'padding-bottom': '0px' }}>
                            <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                                <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                    <dummy>
                                        <Tile>
                                            <Tile.Content>
                                                <div class='timelineHeader'>
                                                    {isDefined(state.user) &&
                                                        <p class='btzTitle btzXl'>{t('Bonjour', {ns: 'timeline'})}</p>
                                                    }
                                                    {(Array.isArray(state.surveys) && isDefined(state.surveys.filter((f) => (!isDefined(f.answerDate) || !isDefined(f.answerDate.date)))[0])) ?
                                                        <p class='btzTitle'>{t('Vous avez', {ns: 'timeline'}) + ' ' }<font>{state.surveys.filter((f) => (!isDefined(f.answerDate) || !isDefined(f.answerDate.date))).length + ' ' + ((state.surveys.filter((f) => (!isDefined(f.answerDate) || !isDefined(f.answerDate.date))).length > 1) ? t('questionnaires', {ns: 'timeline'}) : t('questionnaire', {ns: 'timeline'}))}</font>{ ' ' + t('à compléter aujourd’hui.', {ns: 'timeline'})}</p>
                                                        :
                                                        <p class='btzTitle'>{(Array.isArray(state.surveys) && isDefined(state.surveys.filter((f) => (isDefined(f.answerDate) && isDefined(f.answerDate.date)))[0]) ? t('Vous n\'avez plus de questionnaire à compléter.', {ns: 'timeline'}) : t('Vous n\'avez pas de questionnaire à compléter.', {ns: 'timeline'}))}</p>
                                                    }
                                                </div>
                                            </Tile.Content>
                                        </Tile>
                                    </dummy>
                                </Enter>
                            </Card.Header>
                        </Card>
                        {(Array.isArray(state.surveys) && (state.surveys.filter((f) => !isDefined(f.answerDate)).length > 0)) &&
                            <Card classes={'btzPageCard btzBackgroundCover btzWhiteBlock'} kind={'datas'} style={{ 'padding-bottom': '0px' }}>
                                <div class='btzWhiteBlockHeader'>
                                    <p class='btzWhiteBlockHeaderTitle'>{t('Questionnaires', {ns: 'timeline'})}</p>
                                    <p class='btzWhiteBlockHeaderSubtitle'>{t('Complétez les questionnaires nécessaires à votre accompagnement', {ns: 'timeline'})}</p>
                                </div>
                                <div class='btzTimelineCardsWrapper'>
                                    {state.surveys.filter((f) => !isDefined(f.answerDate)).map((survey, idx) =>
                                        <div key={'survey-' + idx} class='col-4 col-md-6 col-sm-12'>
                                            <div class='survey survey-running' onclick={(e) => { actions.redirectToSurvey(survey); actions.resetScrollTop(); }} style={{ 'background-image': 'url(' + icoBgSurveyx2 + ')', 'cursor': 'pointer' }}>
                                                <div class='surveyImg' style={{ 'background-image': 'url(' + icoSurveyx2 + ')' }}></div>
                                                <div class='surveyContent'>
                                                    {(['uroconnect'].indexOf(props.customer) > -1) ?
                                                        <p class='surveyContentTitle'>{t('Questionnaire', {ns: 'timeline'}) + ' ' + (isDefined(survey.usualName) ? survey.usualName : (survey.medicalStudySurvey || survey.name))}</p>
                                                        :
                                                        <p class='surveyContentTitle'>{t('Q.', {ns: 'timeline'}) + ' ' + (isDefined(survey.usualName) ? survey.usualName : (survey.medicalStudySurvey || survey.name))}</p>
                                                    }
                                                    <p class='surveyContentSubTitle'>{t('Durée estimée', {ns: 'timeline'}) + ' : ' + Math.round(survey.countCard / 3) + ' ' + t('min', {ns: 'generals'})}</p>
                                                    <p class='surveyContentButton' style='margin-top: 10px;'>{t('Appuyez ici', {ns: 'timeline'})}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Card>
                        }
                        {(state.symptoms.filter((f) => !isDefined(f.parent)).length > 0) &&
                            <Card classes={'btzPageCard btzBackgroundCover btzWhiteBlock'} kind={'datas'} style={{ 'padding-bottom': '0px' }}>
                                {(['unicancer', 'uroconnect'].indexOf(props.customer) > -1) ?
                                    <div class='btzWhiteBlockHeader'>
                                        <p class='btzWhiteBlockHeaderTitle'>{t('Fiches à consulter', {ns: 'timeline'})}</p>
                                        <p class='btzWhiteBlockHeaderSubtitle'>{t('Consultez ici des fiches pour vous accompagner au mieux dans votre suivi.', {ns: 'timeline'})}</p>
                                    </div>
                                    :
                                    <div class='btzWhiteBlockHeader'>
                                        <p class='btzWhiteBlockHeaderTitle'>{t('Fiches symptômes', {ns: 'timeline'})}</p>
                                        <p class='btzWhiteBlockHeaderSubtitle'>{t('Voici le contenu adapté pour vous, en fonction de vos symptômes.', {ns: 'timeline'})}</p>
                                    </div>
                                }
                                <div class='btzTimelineCardsWrapper btzTimelineSymptomWrapper'>
                                    {state.symptoms.filter((f) => !isDefined(f.parent)).map((symptom, idx) =>
                                        <div key={'symptom-' + idx} class='col-4 col-md-6 col-sm-12 symptomBg' onclick={(e) => { actions.displaySymptom(symptom.id); props.updateHeaderColor('red'); props.updatePageLevel('child'); actions.resetScrollTop(); }}>
                                            <div class='symptom' style='cursor: pointer;' style={{ 'background-image': 'url(' + bgSymptomsx2 + ')' }}>
                                                <div class='symptomImg' style={{ 'background-image': 'url(' + (isDefined(symptom.media) ? symptom.media.url : null) + ')' }}></div>
                                                <div class='symptomContent'>
                                                    <p class='symptomContentTitle'>{symptom.title}</p>
                                                    <p class='symptomContentButton'>{t('Appuyez ici', {ns: 'timeline'})}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Card>
                        }
                        {(state.libraries.filter((f) => !isDefined(f.parent)).length > 0) &&
                            <Card classes={'btzPageCard btzBackgroundCover btzWhiteBlock'} kind={'datas'}>
                                <div class='btzWhiteBlockHeader'>
                                    <p class='btzWhiteBlockHeaderTitle'>{t('Pour aller plus loin...', {ns: 'timeline'})}</p>
                                    <p class='btzWhiteBlockHeaderSubtitle'>{t('Approfondissez vos connaissances sur votre pathologie, les traitements et les soins de support.', {ns: 'timeline'})}</p>
                                </div>
                                <div class='btzTimelineCardsWrapper'>
                                    {state.libraries.filter((f) => !isDefined(f.parent)).map((library, idx) =>
                                        <div key={'library-' + idx} class='col-4 col-md-6 col-sm-12' onclick={(e) => { actions.displayLibrary(library.id); props.updateHeaderColor('blue'); props.updatePageLevel('child'); actions.resetScrollTop(); }}>
                                            <div class='library more-over' style='cursor: pointer;'>
                                                <div class='libraryContent'>
                                                    <p class='libraryContentTitle'>{library.title}</p>
                                                    <div class='libraryContentSubTitle' innerHTML={markedDownContent(library.description)} style='height: 60px;'></div>
                                                    <p class='libraryContentButton' style='margin-top: 10px;'>{t('Appuyez ici', {ns: 'timeline'})}</p>
                                                </div>
                                                <div class='libraryImg' style={{ 'background-image': 'url(' + (isDefined(library.media) ? library.media.url : null) + ')' }}></div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Card>
                        }
                        {(Array.isArray(state.surveys) && (state.surveys.filter((f) => isDefined(f.answerDate)).length > 0)) &&
                            <Card classes={'btzPageCard btzBackgroundCover btzWhiteBlock'} kind={'datas'} style={{ 'text-align': 'center' }}>
                                <div class='btzWhiteBlockHeader'>
                                    <p class='btzWhiteBlockHeaderTitle'>{t('Questionnaires répondus', {ns: 'timeline'})}</p>
                                    <p class='btzWhiteBlockHeaderSubtitle'>{t('Retrouvez ici les questionnaires complétés.', {ns: 'timeline'})}</p>
                                </div>
                                <div class='btzTimelineCardsWrapper'>
                                    {state.surveys.filter((f) => isDefined(f.answerDate)).map((survey, idx) =>
                                        <div key={'survey-' + idx} class='col-4 col-md-6 col-sm-12' style='text-align: left;'>
                                            <div class='survey survey-done' onclick={(e) => { actions.redirectToSurvey(survey); }} style='cursor: pointer;'>
                                                <div class='surveyImg' style={{ 'background-image': 'url(' + icoSurveyDonex2 + ')' }}></div>
                                                <div class='surveyContent'>
                                                    {(['uroconnect'].indexOf(props.customer) > -1) ?
                                                        <p class='surveyContentTitle'>{t('Questionnaire', {ns: 'timeline'}) + ' ' + (isDefined(survey.usualName) ? survey.usualName : (survey.medicalStudySurvey || survey.name))}</p>
                                                        :
                                                        <p class='surveyContentTitle'>{t('Q.', {ns: 'timeline'}) + ' ' + (isDefined(survey.usualName) ? survey.usualName : (survey.medicalStudySurvey || survey.name))}</p>
                                                    }
                                                    <p class='surveyContentSubTitle'>{t('répondu le', {ns: 'timeline'}) + ' ' + getYMDfromDateObj(createUTCDate(survey.answerDate.date), true) + ',' + ' ' + t('à', {ns: 'generals'}) + ' ' + getReadableDate(createUTCDate(survey.answerDate.date, false), 'HH[h]mm')}</p>
                                                    {(survey.answerAgainToday && survey.answerAgainTomorrow) &&
                                                        <p class='symptomContentButton' style='margin-top: 10px;'>{t('Répondre à nouveau', {ns: 'timeline'})}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Card>
                        }
                    </dummy>
                }
            </div>
        );
        return view;
    }
}