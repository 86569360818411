import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button } from '@app/elements';
import './index.scss';

export const ModalViewConfirm = (props, actions) => (
    <div class='btzModalConfirm'>
        <p class='btzTitle btzXl'>{props.title}</p>
        <p class='btzSubTitle'>{props.description}</p>

        <Button primary active onclick={() => {
            actions.updateParentProps(props)
        }}>
            {t('Confirmer', {ns: 'generals'})}
        </Button>

        <Button primary onclick={() => {
            actions.closeModal()
        }}>
            {t('Annuler', {ns: 'generals'})}
        </Button>
    </div>
);