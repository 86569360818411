import { h } from 'hyperapp'
import { t } from 'i18next'
import { Component } from '@app/utils'
import { settings } from '@app/core/settings'

const state = {
    linkToPatientUseNotice: null,
}

const { APP_CUSTOMISATION, APP_WEB_URL } = settings

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        actions.setLinkToPatientUseNotice(
            props.linkToPatientUseNotice
                ? props.linkToPatientUseNotice
                : APP_WEB_URL + props.support.modules.notice.patient
        )
    },
    setLinkToPatientUseNotice: (newState) => (state) => ({
        linkToPatientUseNotice: newState,
    }),
}

const view = (state, actions) => (props, children) => (
    <div class="thfMentionsWrapper-utilities">
        <p class="thfMentionsWrapper-utilityText">
            {t(
                'Seules les pages identifiées par « Resilience PRO, CE » constituent le module Resilience PRO App marqué CE.'
            )}
        </p>
        <br />
        <p class="thfMentionsWrapper-utilityText">
            {t(
                'Resilience PRO App est indiqué pour le suivi des patients atteints de cancer, en permettant la surveillance à distance par les professionnels de santé.'
            )}
        </p>
        <br />
        <p class="thfMentionsWrapper-utilityTitle">
            {t("Instructions d'utilisation :")}
        </p>
        <p class="thfMentionsWrapper-utilityText">
            {t(
                'Resilience PRO App est une web-application qui ne nécessite pas d’installation ni aucun paramétrage avant son utilisation. Le service est disponible directement sur le web à l’adresse url :'
            )}
            <a class="underlined" style="font-size: 16px;" href={APP_WEB_URL}>
                {APP_WEB_URL}
            </a>
        </p>
        <br />
        <p class="thfMentionsWrapper-utilityText">
            {t(
                'Pour utiliser Resilience PRO App, les patients doivent disposer d’une connexion internet pour se connecter au service depuis leur ordinateur et d’une boîte mail pour recevoir les messages de rappels.'
            )}
        </p>
        <br />
        <p class="thfMentionsWrapper-utilityText" style="margin-bottom: 30px;">
            {t(
                'Nous assurons le bon fonctionnement de l’ensemble du service à partir des versions des navigateurs listées ci-dessous :'
            )}
        </p>
        <p class="thfMentionsWrapper-utilityCompat">
            {t('• Google Chrome : 79')}
        </p>
        <p class="thfMentionsWrapper-utilityCompat">
            {t('• Mozilla Firefox : 80')}
        </p>
        <p
            class="thfMentionsWrapper-utilityCompat"
            style="margin-bottom: 25px;"
        >
            {t('• Microsoft Edge : 18')}
        </p>
        <p
            class="thfMentionsWrapper-utilityCompat"
            style="margin-bottom: 25px;"
        >
            {t('• Safari : 13')}
        </p>
        <p class="thfMentionsWrapper-utilityTitle">{t('Liens utiles :')}</p>
        {!!state.linkToPatientUseNotice && (
            <a
                class="underlined"
                style="display: block;"
                href={state.linkToPatientUseNotice}
                target="_blank"
            >
                {t("Notice d'utilisation Patient")}
            </a>
        )}
        {!!props.legalAgreements &&
            !!props.legalAgreements.legalDocuments &&
            props.legalAgreements.legalDocuments.length > 0 && (
                <dummy style="display: block;border: none !important;">
                    {!!props.legalAgreements.legalDocuments.filter(
                        (f) => f.type === 'privacy'
                    )[0] && (
                        <a
                            class="underlined"
                            style="display: block;"
                            href={
                                props.legalAgreements.legalDocuments.filter(
                                    (f) => f.type === 'terms'
                                )[0].media.url
                            }
                            target="_blank"
                        >
                            {t("Conditions Générales d'Utilisation")}
                        </a>
                    )}
                    {!!props.legalAgreements.legalDocuments.filter(
                        (f) => f.type === 'terms'
                    )[0] && (
                        <a
                            class="underlined"
                            style="display: block;"
                            href={
                                props.legalAgreements.legalDocuments.filter(
                                    (f) => f.type === 'privacy'
                                )[0].media.url
                            }
                            target="_blank"
                        >
                            {t('Politique de Confidentialité')}
                        </a>
                    )}
                </dummy>
            )}
    </div>
)

export default Component(state, actions, view, 'instructions')
