import { t } from 'i18next' // need to be removed to remove dependancy.
import { isDefined } from './isDefined'
import { getYMDfromDateObj } from './getYMDfromDateObj'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/nl'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import { defaultLocale, getLocalStoredLocale } from '../languagesHandler'

const getReadableDate = function (
    dat,
    format = 'Do MMMM YYYY',
    getShorts = false,
    delimiter = null
) {
    // YYYY/MM/DD HH:mm:ss ZZ
    const locale = getLocalStoredLocale()?.value || defaultLocale
    let date = moment(dat)
    if (getShorts) {
        let YMD = getYMDfromDateObj(null)
        let today = moment(YMD.year + '-' + YMD.month + '-' + YMD.day)
        let yesterday = moment(YMD.year + '-' + YMD.month + '-' + YMD.day)
        yesterday.subtract(1, 'days')
        let refD = moment(dat)
        if (refD.isUtc()) {
            refD = moment(refD.toDate())
        }
        refD.startOf('day')
        //
        if (
            getYMDfromDateObj(today, true, true) ==
            getYMDfromDateObj(refD, true, true)
        )
            return t("aujourd'hui")
        else if (
            getYMDfromDateObj(yesterday, true, true) ==
            getYMDfromDateObj(refD, true, true)
        )
            return t('hier')
    }
    //
    if (date.isUtc()) {
        date = moment(date.toDate())
    }
    let dateString = date.locale(locale).format(format)
    if (isDefined(delimiter)) {
        return dateString.replace(/\//g, delimiter)
    }
    return dateString
}

export { getReadableDate }
