import { t } from 'i18next'
import { h } from 'hyperapp'
import { Component } from '@app/utils'
import { allRoutes, addCSRFToken } from '@app/core'
import { Form } from '@app/elements'

import icoSearch from '@app/img/ico/ico-search.png'
import icoSearchx2 from '@app/img/ico/ico-search@2x.png'

const TopMenuView = Component(
    {
        search: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {},
        onComponentUpdate: (props) => (state, actions) => {},
        updateSearch: (e) => (state, actions) => {
            actions.setSearch(e.target.value)
        },
        launchSearch: (e) => (state, actions) => {
            if (+e.keyCode === 13) {
                window.location.href = addCSRFToken(
                    allRoutes['private.patients.listing'].pathname +
                        '?search=' +
                        state.search
                )
            }
        },
        setSearch: (search) => (state) => ({
            search: search,
        }),
    },
    (state, actions) => (props, children) => (
        <Form onsubmit={(event) => event.preventDefault()}>
            <Form.Group>
                <div class="thfAlignTop">
                    <img
                        class="activated"
                        src={icoSearch}
                        srcset={`${icoSearchx2} 2x`}
                        alt=""
                        style="position: absolute;z-index: 9;margin: 25px;"
                    />
                    <Form.Input
                        placeholder={t('Rechercher un patient')}
                        type="text"
                        classes={'thfInput thfInputTopSearch'}
                        value={state.search}
                        onkeypress={actions.launchSearch}
                        oninput={actions.updateSearch}
                        required
                    />
                </div>
            </Form.Group>
        </Form>
    ),
    'topmenuview'
)

export { TopMenuView }
