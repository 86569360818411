import { getQueryVariable } from '../tools/getQueryVariable'

import { t } from 'i18next'
import icoFlagfrFRx2 from '@app/img/ico/flag/flag-fr@2x.png'
import icoFlagenGBx2 from '@app/img/ico/flag/flag-en@2x.png'
import icoFlagenNLx2 from '@app/img/ico/flag/flag-nl@2x.png'
import icoFlagdeDEx2 from '@app/img/ico/flag/flag-de@2x.png'
import icoFlagdeITx2 from '@app/img/ico/flag/flag-it@2x.png'
import icoFlagdeESx2 from '@app/img/ico/flag/flag-es@2x.png'

export const defaultLocale = 'fr-FR'
export const languages = [
    {
        label: 'Français',
        shortlabel: 'FR',
        i18nlabel: t('Français', { ns: 'generals' }),
        i18nshortlabel: t('FR'),
        key: 'fr',
        img: icoFlagfrFRx2,
        availableInLanguePicker: true, // TO-REMOVE in mep 7 (when es, de, it will be added)
    },
    {
        label: 'Anglais',
        shortlabel: 'EN',
        i18nlabel: t('Anglais', { ns: 'generals' }),
        i18nshortlabel: t('EN'),
        key: 'en',
        img: icoFlagenGBx2,
        availableInLanguePicker: true, // TO-REMOVE in mep 7 (when es, de, it will be added)
    },
    {
        label: 'Néerlandais',
        shortlabel: 'NE',
        i18nlabel: t('Néerlandais', { ns: 'generals' }),
        i18nshortlabel: t('NE'),
        key: 'nl',
        img: icoFlagenNLx2,
        availableInLanguePicker: true, // TO-REMOVE in mep 7 (when es, de, it will be added)
    },
    {
        label: 'Allemand',
        shortlabel: 'DE',
        i18nlabel: t('Allemand', { ns: 'generals' }),
        i18nshortlabel: t('DE'),
        key: 'de',
        img: icoFlagdeDEx2,
        availableInLanguePicker: true, // TO-REMOVE in mep 7 (when es, de, it will be added)
    },
    {
        label: 'Espagnol',
        shortlabel: 'ES',
        i18nlabel: t('Espagnol', { ns: 'generals' }),
        i18nshortlabel: t('ES'),
        key: 'es',
        img: icoFlagdeESx2,
        availableInLanguePicker: false, // TO-REMOVE in mep 7 (when es, de, it will be added)
    },
    {
        label: 'Italien',
        shortlabel: 'IT',
        i18nlabel: t('Italien', { ns: 'generals' }),
        i18nshortlabel: t('IT'),
        key: 'it',
        img: icoFlagdeITx2,
        availableInLanguePicker: false, // TO-REMOVE in mep 7 (when es, de, it will be added)
    },
]
export const regions = ['FR', 'GB', 'ES', 'IT', 'DE', 'BE', 'CA', 'US']

const authorizedLanguages = languages.map((language) => language.key)

const authorizedRegions = regions

class Locale {
    constructor(_lang, _region) {
        this._lang = _lang
        this._region = _region
    }

    get value() {
        if (this._region) return `${this._lang}-${this._region}`
        return this._lang
    }
    get lang() {
        return this._lang
    }
    get region() {
        return this._region
    }

    set lang(lang) {
        let newLang = lang
        if (!authorizedLanguages.includes(lang)) {
            newLang = defaultLocale.slice(0, 2)
        }

        this._lang = newLang
    }

    static parseLocale(locale) {
        const region = getRegionFromLocale(locale) // should be able to return undefined if the locale is `'fr'` for example
        const lang = getLangFromLocale(locale)

        // If the lang is not good, we don't need to check the region, we already know we can fallback
        if (!authorizedLanguages.includes(lang)) {
            return new Locale(...defaultLocale.split('-'))
        }

        // Here we check if the region is good when it is defined
        // if so, then we create an instance of `Locale` with both lang and region
        if (!!region && authorizedRegions.includes(region)) {
            return new Locale(lang, region)
        }

        // If the execution came here, it means the region is either undefined or not valid
        // So in this case, we can create an instance of `Locale` without the lang
        return new Locale(lang)
    }
}

const setLocaleInLocalStorage = (toStoreLocale) => {
    try {
        const locale = Locale.parseLocale(toStoreLocale)
        localStorage.setItem('locale', locale.value)
    } catch (e) {
        // dont know what to do here :)
    }
}
const getLangFromLocale = (locale) => {
    //
    if (!locale || locale.length !== 5 || !locale.includes('-')) {
        return defaultLocale.slice(0, 2)
    }
    const lang = locale.slice(0, 2)
    if (!authorizedLanguages.includes(lang)) {
        return defaultLocale.slice(0, 2)
    }

    return lang
}

const getRegionFromLocale = (locale) => {
    //
    if (!locale || locale.length !== 5 || !locale.includes('-')) {
        return defaultLocale.slice(-2)
    }
    return locale.slice(-2)
}

const getQueryLocale = () => {
    let qlocale = getQueryVariable('locale', false)

    if (qlocale) {
        try {
            setLocaleInLocalStorage(qlocale)
            return Locale.parseLocale(qlocale)
        } catch (e) {
            setLocaleInLocalStorage(defaultLocale)
            return Locale.parseLocale(defaultLocale)
        }
    }
    return undefined
}
const getLocalStoredLocale = () => {
    let llocale =
        localStorage.getItem('locale') ?? localStorage.getItem('language')

    if (llocale) {
        try {
            setLocaleInLocalStorage(llocale)
            return Locale.parseLocale(llocale)
        } catch (e) {
            setLocaleInLocalStorage(defaultLocale)
            return Locale.parseLocale(defaultLocale)
        }
    }
    return undefined
}

export {
    getLangFromLocale,
    getQueryLocale,
    getLocalStoredLocale,
    setLocaleInLocalStorage,
    Locale,
}
