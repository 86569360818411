require('@app/__tests__/setup')
const { metricDegreesToFahrenheit } = require('./metricDegreesToFahrenheit')
const { metricFahrenheitToDegrees } = require('./metricFahrenheitToDegrees')

let allCalculations = []
Array.from(Array(10).keys()).forEach((pounds) => {
    return Array.from(Array(99).keys()).forEach((decimal) => {
        if (decimal > 0) {
            allCalculations.push([Number(`${pounds}.${decimal}`).toString()])
        }
    })
})

describe('metricKilogramToPounds ROUND TRIP', () => {
    test.each([...allCalculations])(
        'should do the round trip with $s beginning with celsius to fahrenheit then back to celsius',
        (metric) => {
            const fahrenheit = metricDegreesToFahrenheit(metric, null) // if a decimal is defined, this would lead to a difference because result is rounded
            const backToCelsius = metricFahrenheitToDegrees(fahrenheit, 2)
            expect(backToCelsius).toStrictEqual(metric)
        }
    )

    test.each([...allCalculations])(
        'should do the round trip with $s beginning with fahrenheit to celsius then back to fahrenheit',
        (metric) => {
            const celsius = metricFahrenheitToDegrees(metric, null) // if a decimal is defined, this would lead to a difference because result is rounded
            const backToFahrenheit = metricDegreesToFahrenheit(celsius, 2)
            expect(backToFahrenheit).toStrictEqual(metric)
        }
    )
})
