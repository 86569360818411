import { isDefined } from './isDefined'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/nl'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import { defaultLocale, getLocalStoredLocale } from '../languagesHandler'
const getDateObjFromYMD = function (ymd = null, time = 'now', isUTC = false) {
    const locale = getLocalStoredLocale()?.value ?? defaultLocale
    if (time === 'now') {
        // time = new Date().toLocaleTimeString(refLang, {hour: 'numeric', minute: 'numeric', second: 'numeric'}) + '.000000';
        time = moment().locale(locale).format('HH:mm:ss') //.toLocaleTimeString(refLang, {hour: 'numeric', minute: 'numeric', second: 'numeric'}) + '.000000';
    } else if (!isDefined(time)) {
        time = '00:00:00.000000'
    }
    if (ymd === '') {
        return ymd
    }
    if (!ymd || !isDefined(ymd)) {
        // var today = new Date();
        var today = moment()
        return today.startOf('day').format('YYYY-MM-DD')
        // return today.year() + '-' + String(+today.month() + 1).padStart(2, 0) + '-' + String(today.toDate()).padStart(2, 0) + 'T' + time + 'Z';
    }

    var dateYear = null
    var dateMonth = null
    var dateDay = null
    if (isDefined(ymd.year)) {
        dateYear = ymd.year
    } else {
        dateYear = ymd.substring(0, 4)
    }
    if (isDefined(ymd.month)) {
        dateMonth = ymd.month
    } else {
        dateMonth = ymd.substring(4, 6)
    }
    if (isDefined(ymd.day)) {
        dateDay = ymd.day
    } else {
        dateDay = ymd.substring(6, 8)
    }
    if (!isDefined(dateDay) || !isDefined(dateMonth) || !isDefined(dateYear)) {
        return null
    }
    var parsedDate = dateYear + '-' + dateMonth + '-' + dateDay + ' ' + time
    if (isUTC !== undefined && isUTC === true) {
        // var tz = (new Date().getTimezoneOffset()) / -60;
        var tz = moment().utcOffset() / 60
        var tzSign = '+'
        if (tz < 0) {
            tzSign = '-'
        }
        if (String(String(tz).replace('-', '')).length < 2) {
            tz = '0' + String(tz).replace('-', '')
        }
        parsedDate = moment
            .utc(
                dateYear +
                    '-' +
                    dateMonth +
                    '-' +
                    dateDay +
                    'T' +
                    time +
                    tzSign +
                    tz +
                    ':00'
            )
            .format('YYYY-MM-DD HH:mm:ss')
    }

    parsedDate = {
        // date: new Date(dateYear + '-' + dateMonth + '-' + dateDay),
        date: parsedDate,
        timezone: 'UTC',
        timezone_type: 3,
    }

    return parsedDate
}

export { getDateObjFromYMD }
