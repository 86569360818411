require('@app/__tests__/setup')
const { generatePower } = require('.')

describe('generatePower', () => {
    test.each([
        [undefined, '1'],
        [10, 1],
        [100, 2],
        [1000, 3],
        [undefined, null],
        [undefined, undefined],
        [undefined, {}],
        [undefined, []],
    ])('should return %s when metric is %s', (expected, exponent) => {
        expect(generatePower(exponent)).toBe(expected)
    })
})
