import moment from 'moment'
const createUTCDate = function (date = null, withOffset = true) {
    var oDate = moment()
    if (date !== null) {
        if (typeof date === 'string') {
            if (date.indexOf('.') > -1 && date.indexOf(' ') > -1) {
                date = date.replace(' ', 'T') + 'Z'
            } else if (date.indexOf('.') > -1) {
                return date
            }
            // oDate = new Date(date);
            oDate = moment(date)
        }
    } else {
        oDate.set('millisecond', 0)
    }
    if (Object.prototype.toString.call(oDate) === '[object Date]') {
        oDate = oDate.utc()
        var offset = withOffset ? oDate.utcOffset() : 0
        // oDate.setTime(oDate.valueOf() + (offset * 60 * -1000));
        oDate.set({ hours: oDate.hour() + offset * 60 * -1000 })
        return oDate
    } else {
        return oDate
    }
}

export { createUTCDate }
