export const dateTimeEndingZ = (dateString) => {
    if (
        !dateString ||
        Array.isArray(dateString) ||
        isNaN(new Date(dateString)) ||
        !(new Date(dateString) instanceof Date)
    ) {
        return undefined
    }

    return `${dateString}Z`
}
