import { Component } from '@app/utils';
import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined, getReadableDate, getDateObjFromYMD } from '@app/core';
import { Tile, Form, Button } from '@app/elements';

const state = {
    //
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        //
    },
};

const view = (state, actions) => (props, children) => (
    <dummy>
        <Tile>
            <Tile.Content>
                <div class='consentHeader'>
                    <p class='btzTitle btzXl'>{t('Politique de confidentialité')}</p>
                </div>
                <div class='consentContent'>
                    <p class=''>{t('Lorem ipsum.')}</p>
                </div>
            </Tile.Content>
        </Tile>

        <Form onsubmit={event => event.preventDefault()} autocomplete={'on'}>
            <button type='submit' disabled style='display: none' aria-hidden='true'></button>

            <Form.Group classes='btzForm-btzFooter'>
                <Button flat  active onclick={props.cancelAction}>
                    {t('Fermer')}
                </Button>
            </Form.Group>
        </Form>
    </dummy>
);

export default Component(state, actions, view, 'modal-pdc');