import { t } from 'i18next';

const HELP_DEFAULT_TRANS = t('0, tout va mal. 10, vous vous sentez pousser des ailes.', {ns: 'onboarding'});
const HELP_SLIDER_TRANS = t('Saisissez la réponse.', {ns: 'onboarding'});
const HELP_MORAL_TRANS = t('0, tout va mal. 10, vous vous sentez pousser des ailes.', {ns: 'onboarding'});
const HELP_STRESS_TRANS = t('0, tout va bien. 10, le stress est palpable.', {ns: 'onboarding'});
const HELP_TIREDNESS_TRANS = t('0, vous êtes en pleine forme. 10, vous ne tenez plus.', {ns: 'onboarding'});
const HELP_BUTTON_TRANS = t('Une seule réponse possible.', {ns: 'onboarding'});
const HELP_QCM_TRANS = t('Plusieurs réponses possibles.', {ns: 'onboarding'});
const LABEL_ALCOOL_TRANS = t('verre(s)', {ns: 'onboarding'});
const LABEL_TOBACCO_TRANS = t('cigarette(s)', {ns: 'onboarding'});


const HELP_DEFAULT = '0, tout va mal. 10, vous vous sentez pousser des ailes.';
const HELP_SLIDER = 'Saisissez la réponse.';
const HELP_MORAL = '0, tout va mal. 10, vous vous sentez pousser des ailes.';
const HELP_STRESS = '0, tout va bien. 10, le stress est palpable.';
const HELP_TIREDNESS = '0, vous êtes en pleine forme. 10, vous ne tenez plus.';
const HELP_BUTTON = 'Une seule réponse possible.';
const HELP_QCM = 'Plusieurs réponses possibles.';
const LABEL_ALCOOL = 'verre(s)';
const LABEL_TOBACCO = 'cigarette(s)';

export const HELPS_MESSAGES = {
    'DEFAULT' : {'label': HELP_DEFAULT, 'i18n': HELP_DEFAULT_TRANS},
    'SLIDER' : {'label': HELP_SLIDER, 'i18n': HELP_SLIDER_TRANS},
    'MORAL' : {'label': HELP_MORAL, 'i18n': HELP_MORAL_TRANS},
    'STRESS' : {'label': HELP_STRESS, 'i18n': HELP_STRESS_TRANS},
    'TIREDNESS' : {'label': HELP_TIREDNESS, 'i18n': HELP_TIREDNESS_TRANS},
    'BUTTON' : {'label': HELP_BUTTON, 'i18n': HELP_BUTTON_TRANS},
    'QCM' : {'label': HELP_QCM, 'i18n': HELP_QCM_TRANS},
    'ALCOOL' : {'label': LABEL_ALCOOL, 'i18n': LABEL_ALCOOL_TRANS},
    'TOBACCO' : {'label': LABEL_TOBACCO, 'i18n': LABEL_TOBACCO_TRANS},
};