import { t } from 'i18next'
import { h } from 'hyperapp'
import {
    Card,
    Button,
    Form,
    FormInput,
    Tile,
    Enter,
    Calendar,
    Modal,
} from '@app/elements'
import { dynLoad } from '@app/core'

import * as constants from '@app/constants'

const ViewWelcome = dynLoad(
    'view-welcome',
    'Login/authentication/views/access/welcome'
).default
const ViewDirectAccess = dynLoad(
    'view-directaccess',
    'Login/authentication/views/access/direct-access'
).default
const ViewSimpleAccess = dynLoad(
    'view-simpleaccess',
    'Login/authentication/views/access/simple-access'
).default

export default {
    initView: function () {
        let view = (state, actions) => (props, children) => (
            <div
                class={
                    'login-central ' +
                    (state.directAccess ? 'direct-access' : 'simple-access') +
                    ' ' +
                    state.loginStep +
                    ' ' +
                    (state.askForMonitoringConsent
                        ? 'askForMonitoringConsent'
                        : '')
                }
            >
                <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'}>
                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                        {sessionStorage.getItem('customDisplay') !== 'popup' ? (
                            <Enter
                                time={constants.custo.ANIM_DURATION}
                                easing={constants.custo.ANIM_EASING}
                                css={constants.custo.ANIM_CSS}
                            >
                                {state.displayWelcome ? (
                                    <ViewWelcome
                                        {...state}
                                        _setState={actions._setState}
                                    ></ViewWelcome>
                                ) : (
                                    <dummy>
                                        {state.directAccess === true ? (
                                            <ViewDirectAccess
                                                {...state}
                                                _setState={actions._setState}
                                                _retrieveInput={
                                                    actions._retrieveInput
                                                }
                                                retrieveErrorMessage={
                                                    actions.retrieveErrorMessage
                                                }
                                                redirectUserToGoodIndex={
                                                    actions.redirectUserToGoodIndex
                                                }
                                                refuseMonitoring={
                                                    actions.refuseMonitoring
                                                }
                                                onSubmit={actions.onSubmit}
                                            ></ViewDirectAccess>
                                        ) : (
                                            <ViewSimpleAccess
                                                {...state}
                                                _setState={actions._setState}
                                                _retrieveInput={
                                                    actions._retrieveInput
                                                }
                                                retrieveErrorMessage={
                                                    actions.retrieveErrorMessage
                                                }
                                                redirectUserToGoodIndex={
                                                    actions.redirectUserToGoodIndex
                                                }
                                                refuseMonitoring={
                                                    actions.refuseMonitoring
                                                }
                                                onSubmit={actions.onSubmit}
                                            ></ViewSimpleAccess>
                                        )}
                                    </dummy>
                                )}
                            </Enter>
                        ) : (
                            <div style="background-color: white;position: absolute;left: 0;top: 0;width: 100%;height: 100%;z-index: 999999;"></div>
                        )}
                    </Card.Header>
                </Card>
            </div>
        )
        return view
    },
}
