import { t } from 'i18next';
import { h } from 'hyperapp';
import { Component } from '@app/utils';
import { Tile } from '@app/elements';

import OnboardingLayout from '@app/layouts/onboarding/layout';

import gifLoader from '@app/img/loader.gif';
import bgOnboarding from '@app/img/bg/bg-onboarding@2x.png';

import './index.scss';

const state = {
    //
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        //
    },
};

const view = (state, actions) => (props, children) => (
    <div key='onboardingdone' class='specificFullHeightMobile' style={{ 'background-image': 'url(' + bgOnboarding + ')' }}>
        <OnboardingLayout page='onboardingdone' customer={props.customer}>
            <div style={{ position: 'relative' }} class={'onboardingdone-step'}>
                <dummy>
                    <Tile>
                        <Tile.Content>
                            <p class='btzTitle btzXl' style={{ 'margin-bottom': '0' }}>
                                {t('Merci.', {ns: 'onboarding'})}
                            </p>
                            <p class='btzSubTitle btzXl' style={{ 'margin-bottom': '0' }}>
                                {t('Vos réponses sont en cours d\'enregistrement.', {ns: 'onboarding'})}
                            </p>
                            <img class='' src={gifLoader} srcset={`${gifLoader} 2x`} alt='' />
                            <p class='btzSubTitle' style={{ 'margin-bottom': '0' }}>
                                {t('Veuillez patienter...', {ns: 'onboarding'})}
                            </p>
                        </Tile.Content>
                    </Tile>
                </dummy>
            </div>
        </OnboardingLayout>
    </div>
);

export default Component(state, actions, view, 'onboardingdone');
