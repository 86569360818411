// import { settings } from '@app/core/settings';
import { default as classMerge } from "classcat";
import { h, app } from "hyperapp";

/**
 *
 *
 * @param {any} state
 * @param {any} actions
 * @param {any} view
 * @param {any} tagname
 * @return vNode with an app mounted in
 */

let appOnSteroids = app;

function Component(localState, localActions, localView, tagname) {
	localActions._return = function noop() {
		return {};
	};
	return (props, children) =>
		h("div", {
			key: props.key + tagname,
			id: props.id,
			class: classMerge([tagname, props.class]),
			oncreate(element) {
				const wired = appOnSteroids(
					localState,
					localActions,
					(_state, _actions) => {
						let _view = localView(_state, _actions);
						if (typeof _view === "function")
							_view = _view(element._props, element._children);
						return _view;
					},
					element
				);

				if (window.main) window.main[tagname] = wired;

				props.children = children;
				element._props = props;
				element._children = children;
				element._return = wired._return;
				element._onComponentUpdate = wired.onComponentUpdate;
				element._onComponentDestroy = wired.onComponentDestroy;
				if (wired.onComponentCreate) wired.onComponentCreate(props);
				if (props.oncreate) props.oncreate(element);
			},
			onupdate(element) {
				props.children = children;
				element._props = props;
				element._children = children;
				element._return();
				if (element._onComponentUpdate)
					element._onComponentUpdate(props);
				if (props.onupdate) props.onupdate(element);
			},
			ondestroy(element) {
				if (element._onComponentDestroy)
					element._onComponentDestroy(props);
			},
		});
}

let EnhancedComponent = Component;

/*if (settings.DEBUG) {
    const { withLogger } = require('@hyperapp/logger')

    const verboseLogging = {
        log(prevState, action, nextState) {
            console.group('%c action', 'color: gray; font-weight: lighter;', action.name);
            console.log('%c prev state', 'color: #9E9E9E; font-weight: bold;', prevState)
            console.log('%c data', 'color: #03A9F4; font-weight: bold;', action.data);
            console.log('%c next state', 'color: #4CAF50; font-weight: bold;', nextState)
            console.groupEnd();
        }
    };

    EnhancedComponent = withLogger(verboseLogging)(Component)
}*/

export { EnhancedComponent as Component };
