export const dateTimeUserTimezone = () => {
    const userLS = localStorage.getItem('user')
    if (userLS) {
        const user = JSON.parse(userLS)
        if (user && user.timezone) {
            return user.timezone
        }
    }
    return 'Europe/Paris'
}
