import { t } from 'i18next'; // need to be removed to remove dependancy.

const ensurePasswordStrength = function(pwd) {
    var returnValue = true;
    if (pwd === '') {
        returnValue = t('Saisissez un mot de passe.');
        // eslint-disable-next-line
    } else if ((pwd.length < 8) || (pwd.match(/[A-Z]/g) === null) || (pwd.match(/[a-z]/g) === null) || (pwd.match(/[-!$%^&*()_+|~=`{}\/[:;<>?,.@#\]]/g) === null) || (pwd.match(/[0-9]/g) === null)) {
        returnValue = t('Votre mot de passe doit être composé de 8 caractères minimum avec au moins 1 majuscule, 1 minuscule, 1 chiffre, 1 caractère spécial.');
    }
    //
    let atLeastHeight = false, oneUppercase = false, oneLowercase = false, oneInt = false, oneSpecialChar = false, isValid = true;
    if (pwd.length > 8) {
        atLeastHeight = true;
    } else {
        atLeastHeight = false;
        isValid = false;
    }
    if (pwd.match(/[A-Z]/g) !== null) {
        oneUppercase = true;
    } else {
        oneUppercase = false;
        isValid = false;
    }
    if (pwd.match(/[a-z]/g) !== null) {
        oneLowercase = true;
    } else {
        oneLowercase = false;
        isValid = false;
    }
    if (pwd.match(/[0-9]/g) !== null) {
        oneInt = true;
    } else {
        oneInt = false;
        isValid = false;
    }
    // eslint-disable-next-line
    if (pwd.match(/[-!$%^&*()_+|~=`{}\/[:;<>?,.@#\]]/g) !== null) {
        oneSpecialChar = true;
    } else {
        oneSpecialChar = false;
        isValid = false;
    }
    //
    return {
        message: returnValue,
        atLeastHeight: atLeastHeight,
        oneUppercase: oneUppercase,
        oneLowercase: oneLowercase,
        oneInt: oneInt,
        oneSpecialChar: oneSpecialChar,
        isValid: isValid,
    };
}

export { ensurePasswordStrength };