const scrollTo = function (element, to, duration) {
	if (duration <= 0) return;
	if (element === null) {
		element = document.scrollingElement || document.documentElement;
	}
	var difference = to - element.scrollTop;
	var perTick = (difference / duration) * 10;
	setTimeout(function () {
		element.scrollTop = element.scrollTop + perTick;
		element?.scroll(0, element.scrollTop + perTick); // to survey
		if (element.scrollTop === to) return;
		scrollTo(element, to, duration - 10);
	}, 10);
};

export { scrollTo };
