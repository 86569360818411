import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { APP_API_URL } = settings

function getData(id, type, date, period) {
    return queueQueries(
        `${APP_API_URL}/theraflow/measure/${id}/${type}/${date}/${period}/`,
        'GET'
    )
}
function getDataDashboard(id, type, date, period) {
    return queueQueries(
        `${APP_API_URL}/theraflow/dashboard/${id}/${type}/${date}/${period}/`,
        'GET'
    )
}

export const Telesuivi = {
    getData,
    getDataDashboard,
}
