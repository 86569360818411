require('@app/__tests__/setup')
const { flattenObject } = require('.')

describe('flattenObject', () => {
    test.each([
        [undefined, undefined],
        [undefined, null],
        [undefined, NaN],
    ])('should return %s when metric is %s', (expected, param) => {
        expect(flattenObject(param)).toStrictEqual(expected)
    })

    test.each([
        [{}, []],
        [{}, {}],
    ])('should return %s when metric is %s', (expected, param) => {
        expect(flattenObject(param)).toStrictEqual(expected)
    })

    test.each([
        [0, 0],
        [1, 1],
        ['1', '1'],
        [true, true],
        [false, false],
    ])('should return %s when metric is %s', (expected, param) => {
        expect(flattenObject(param)).toStrictEqual(expected)
    })

    test('should flat array of multiple depth object to one simple depth object', async () => {
        expect(flattenObject([{ key: 1 }, { sub: { key: 2 } }])).toStrictEqual({
            '0.key': 1,
            '1.sub.key': 2,
        })
    })

    test('should flat multiple depth object to one simple depth object', async () => {
        expect(flattenObject({ sub: { key1: 1, key2: 2 } })).toStrictEqual({
            'sub.key1': 1,
            'sub.key2': 2,
        })
    })
})
