import { h } from 'hyperapp'
import { Component } from '@app/utils'
import { isDefined } from '@app/core'

import './../Form/index.scss'
import { defaultLocale, getLocalStoredLocale } from '@app/core/languagesHandler'
import { createNewMask } from './utils'

const DateInput = Component(
    {
        id: '',
        name: '',
        loaded: true,
        validation: true,
        date: '',
        hasBeenFilled: false,
        onInputCB: null,
        onBlurCB: null,
        isSuccess: '',
        isError: '',
        isValid: false,
        isTyping: false,
        readonly: false,
        optional: false,
        formSubmittedOnce: false,
        errorMessage: null,
        localErrorMessage: null,
        initialized: false,
        previousLocale: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            //
            actions.onComponentUpdate(props)
            if (isDefined(props.validation)) {
                actions.setState({ key: 'validation', value: props.validation })
            }
            if (props.oninputcb) {
                actions.setState({ key: 'onInputCB', value: props.oninputcb })
            }
            if (props.onblurcb) {
                actions.setState({ key: 'onBlurCB', value: props.onblurcb })
            }
            if (props.name) {
                actions.setState({ key: 'name', value: props.name })
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            actions.setState({
                key: 'localErrorMessage',
                value: props.errorMessage,
            })

            if (isDefined(props.formSubmittedOnce)) {
                actions.setState({
                    key: 'formSubmittedOnce',
                    value: props.formSubmittedOnce,
                })
                actions.updateValidation({ value: state.date })
            }
            if (isDefined(props.optional)) {
                actions.setState({ key: 'optional', value: props.optional })
            }
            if (props.isValid !== undefined) {
                const value = props.isValid ? props.value : null
                actions.updateValidation({ value: value })
            }
            if (isDefined(props.errorMessage)) {
                actions.setState({
                    key: 'errorMessage',
                    value: props.errorMessage,
                })
            }
            if (isDefined(props.loaded)) {
                actions.setState({ key: 'loaded', value: props.loaded })
            }
            if (isDefined(props.readonly)) {
                actions.setState({ key: 'readonly', value: props.readonly })
            }
            actions.onCreateIMask(props.name)
        },
        onCreateIMask: (name) => (state, actions) => {
            const localeObj = getLocalStoredLocale()
            const locale = localeObj?.value ?? defaultLocale
            if (locale === state.previousLocale && state.mask) {
                return false
            }
            if (state.mask) {
                state.mask.destroy()
            }
            //
            const newMask = createNewMask(name, locale, state.previousLocale)
            if (newMask) {
                actions.setState({
                    key: 'mask',
                    value: newMask,
                })
                actions.updateValues()
            }
            actions.setState({ key: 'previousLocale', value: locale })
        },
        updateValidation:
            ({ value }) =>
            (state, actions) => {
                let isValid = false
                let isFilled = false

                if (value) {
                    isValid = true
                    isFilled = true
                    //
                    if (state.optional) {
                        if (state.date === '') {
                            isFilled = false
                        }
                    }
                }
                if (
                    state.optional &&
                    (value === '' ||
                        !isDefined(value) ||
                        (Array.isArray(value) && value.length === 0))
                ) {
                    isValid = true
                    isFilled = false
                }
                actions.updateValidationState({
                    isValid: isValid,
                    isFilled: isFilled,
                    isTyping: state.isTyping,
                })

                return {
                    isValid: isValid,
                    isFilled: isFilled,
                    isTyping: state.isTyping,
                }
            },
        updateValidationState:
            ({ isValid, isFilled, isTyping }) =>
            (state, actions) => {
                if (
                    (state.optional && isValid && !isFilled) ||
                    !state.formSubmittedOnce ||
                    !state.validation ||
                    isTyping
                ) {
                    actions.setState({ key: 'isSuccess', value: '' })
                    actions.setState({ key: 'isError', value: '' })
                    actions.setState({ key: 'errorMessage', value: '' })
                } else if (
                    isFilled ||
                    state.hasBeenFilled ||
                    state.formSubmittedOnce
                ) {
                    actions.setState({ key: 'hasBeenFilled', value: true })
                    if (isValid && isFilled) {
                        actions.setState({
                            key: 'isSuccess',
                            value: 'is-success',
                        })
                        actions.setState({ key: 'isError', value: '' })
                        actions.setState({ key: 'errorMessage', value: '' })
                    } else {
                        actions.setState({ key: 'isSuccess', value: '' })
                        actions.setState({ key: 'isError', value: 'is-error' })
                    }
                }
                if (isValid) {
                    actions.setState({ key: 'errorMessage', value: '' })
                }
            },
        updateValues: () => (state, actions) => {
            const targetValue = state.mask
                ? state.mask.typedValue && state.mask?.typedValue.isValid()
                    ? state.mask.typedValue.format('YYYYMMDD')
                    : state.mask.typedValue
                : ''
            actions.setState({ key: 'date', value: targetValue })
            const validation = actions.updateValidation({ value: targetValue })

            state.onInputCB({
                name: state.name,
                values: targetValue,
                isvalid: validation.isValid,
                id: state.id,
            })
            //
        },
        defineListener: () => (state, actions) => {
            const inputs = document.getElementById(`${state.name}-datepicker`)
            if (inputs && !state.initialized) {
                actions.setState({ key: 'initialized', value: true })
                inputs.addEventListener('keyup', (e) => {
                    actions.setState({
                        key: 'isTyping',
                        value: true,
                    })
                    //
                    actions.updateValues(e)
                })
                inputs.addEventListener('blur', (e) => {
                    actions.setState({
                        key: 'isTyping',
                        value: false,
                    })
                    //
                    actions.updateValues(e)
                    if (state.onBlurCB) {
                        state.onBlurCB(e)
                    }
                })
            }
        },
        //
        setState:
            ({ key, value }) =>
            (state) => ({
                [key]: value,
            }),
    },
    (state, actions) => (props, children) => (
        <div
            class={
                'btzForm-btzFormInputWrapper ' +
                'forminputmarker forminput-' +
                state.name +
                ' dateinput-' +
                state.type
            }
            style={{ position: 'relative', height: 'auto' }}
        >
            {!state.loaded ? (
                <div class="thfInput thfLoadingBox">
                    <div class="thfLoadingBoxWrapper"></div>
                </div>
            ) : (
                <div
                    class={
                        (state.textedArea === true ? '' : 'thfInput ') +
                        state.isSuccess +
                        ' ' +
                        state.isError
                    }
                >
                    <div
                        class={
                            'btzForm-btzFormInput btzInputDate ' +
                            (!state.date ? 'isEmpty' : '')
                        }
                        style={{
                            'position': 'relative',
                            'padding-right': '20px',
                        }}
                    >
                        <input
                            type="text"
                            id={`${state.name}-datepicker`}
                            name={state.name}
                            onkeydown={(e) => {
                                if (
                                    e.code === 'Enter' &&
                                    document.activeElement
                                ) {
                                    /*
                                        Error display is triggered when the input is unfocused
                                            to avoid to many edition.
                                            The simplest is to blur the input when hitting Enter.
                                    */
                                    document.activeElement.blur()
                                }
                            }}
                            onclick={(e) => {
                                if (
                                    !/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                                        navigator.userAgent
                                    )
                                ) {
                                    e.preventDefault()
                                }
                            }}
                            onfocus={actions.defineListener}
                            oninput={actions.updateValues}
                            oncreate={() => {
                                actions.onCreateIMask(props.name)
                            }}
                            max={'2999-12-31'}
                            required
                        />
                    </div>
                </div>
            )}
            <p class="btzForm-btzFormInput-errorMessage">
                {!state.isTyping &&
                !state.isValid &&
                state.isError !== '' &&
                state.localErrorMessage
                    ? state.localErrorMessage
                    : ''}
            </p>
            <div class="btzForm-btzFormInput-hr"></div>
        </div>
    ),
    'dateinput'
)

export { DateInput }
