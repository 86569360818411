import { t } from 'i18next'
import { h } from 'hyperapp'
import { Component } from '@app/utils'
import {
    getSupport,
    allRoutes,
    addCSRFToken,
    isDefined,
    getQueryVariable,
} from '@app/core'
import './index.scss'
import * as constants from '@app/constants'

import { Mentions } from '@app/modules'
import { retrieveEmergencyPhoneByCountry } from '@app/core/tools/retrieveEmergencyPhoneByCountry'

const BotFooterView = Component(
    {
        customer: 'betterise',
        displayed: true,
        urlPdfCgu: '',
        urlPdfCga: '',
        contactMail: 'alfred@betterise.me',
        allRightReserved: 'Betterise Technologies',
        todayYear: 'Ø',
        startYear: '2019',
        mentionDisplayed: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            let d = new Date()
            actions.setTodayYear(d.getFullYear())
            actions.setSupport(getSupport())
            //
            let pdfUrl = 'pdf.cgu'
            let pdfUrls = constants.custo.CUSTOMERS[props.customer]
            if (isDefined(pdfUrls) && isDefined(pdfUrls.pdf.cgu)) {
                pdfUrl = pdfUrls.pdf.cgu
            }
            actions.setUrlPdfCgu(allRoutes[pdfUrl].pathname)
            //
            let trigger = getQueryVariable('trigger')
            if (isDefined(trigger) && trigger !== false) {
                if (trigger === 'mention') {
                    actions.displayMention(true)
                }
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            //
        },
        redirectToMention: () => (state, actions) => {
            let sessionUser = localStorage.getItem('user')
            if (isDefined(sessionUser)) {
                sessionUser = JSON.parse(sessionUser)
                if (isDefined(sessionUser.id)) {
                    window.location.href = addCSRFToken(
                        allRoutes['private.mentions'].pathname
                    )
                    return false
                }
            }
            window.location.href = addCSRFToken(
                allRoutes['index.mentions'].pathname
            )
        },
        displayMention: (newState) => (state) => ({
            mentionDisplayed: newState,
        }),
        setSupport: (newState) => (state) => ({
            support: newState,
        }),
        setUrlPdfCgu: (newState) => (state) => ({
            urlPdfCgu: newState,
        }),
        setAllRightReserved: (newState) => (state) => ({
            allRightReserved: newState,
        }),
        setTodayYear: (newState) => (state) => ({
            todayYear: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div class="btzFooter-btzFooters">
            <dummy>
                {props.displayed === 'timeline' && (
                    <p class="btzFooter-btzFootersDisclamer">
                        {t('globals.emergency.phone', {
                            ns: 'generals',
                            emergencyPhone: retrieveEmergencyPhoneByCountry(),
                        })}
                    </p>
                )}
                {/*<p class='btzFooter-btzFooter' style='display: inline-block'>{t('Tous droits réservés') + ' ' + state.allRightReserved + ' ' + state.startYear + ' - ' + state.todayYear + ' -'}</p>*/}
                {/*<a class='btn-link' style='display: inline-block;' href={state.urlPdfCgu} target={'_blank'}>{t('CGU')}</a><p class='btnTiret'>{' - '}</p>
                <a class='btn-link' style='display: inline-block;' href={'#'}>{t('Politique de confidentialité')}</a><p class='btnTiret'>{' - '}</p>
                <a class='btn-link' style='display: inline-block;' href={'#'}>{t('Version et certifications')}</a>*/}
                <div
                    class=""
                    style="display: block;line-height: 1.2;padding: 0 10px;"
                >
                    <a
                        class="btn-link"
                        style="display: inline-block;margin-left: 5px;"
                        href={'#'}
                        onclick={() => {
                            actions.displayMention(true)
                        }}
                    >
                        {t('Mentions Légales')}
                    </a>
                    {' - '}
                    <p
                        class="btzFooter-btzFooter ovr-lightgrey-colored"
                        style="display: inline-block;margin-right: 5px;"
                    >
                        {t('Un problème ?', { ns: 'generals' })}
                    </p>
                    <a
                        class="btn-link"
                        style="display: inline-block;"
                        href={'mailto:' + state.support}
                    >
                        {state.support}
                    </a>
                </div>
                {isDefined(props.medicalInfos) && (
                    <p
                        class="btzFooter-btzFooter ovr-lightgrey-colored"
                        style="display: inline-block;"
                    >
                        {'- ' +
                            t('Licence ID', { ns: 'generals' }) +
                            ' : ' +
                            props.medicalInfos.user}
                    </p>
                )}
                {state.mentionDisplayed && (
                    <Mentions
                        {...state}
                        closeModal={() => {
                            actions.displayMention(false)
                        }}
                    ></Mentions>
                )}
            </dummy>
        </div>
    ),
    'botfooterview'
)

export { BotFooterView }
