const autoClicAndSelectInput = function(el) {
    let target = el?.target || el;
    if ((target !== undefined) && (target !== null)) {
        target.focus();
        target.click();
        target.setSelectionRange(1000, 1000);
        // target.select();
    }
}

const autoSelectInput = function(el) {
    let target = el?.target || el;
    if ((target !== undefined) && (target !== null)) {
        target.focus();
        // target.setSelectionRange(1000, 1000);
        // target.select();
    }
}

export { autoSelectInput, autoClicAndSelectInput };