import { h } from 'hyperapp';
import { Component } from '@app/utils';
import { dynLoad, getSupport, isDefined } from '@app/core';
import { Consent } from '@app/api';
import './index.scss';

const state = {
    support: null,
    legalAgreements: [],
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        actions.setSupport(getSupport('whole'));
        let apitoken = localStorage.getItem('apitoken');
        if (isDefined(apitoken) && (apitoken != false)) {
            actions.getLegalAgreements();
        } else {
            actions.getPublicLegalAgreements();
        }
    },
    getLegalAgreements: () => (state, actions) => {
        Consent.getLastUserLegalAgreement('patient').then((res) => {
            let legalAgreements = {legalDocuments: res.data.map((m) => m.legalDocument)};
            actions.setLegalAgreements(legalAgreements);
        })
    },
    getPublicLegalAgreements: () => (state, actions) => {
        Consent.getLastPublicLegalAgreement('patient').then((res) => {
            actions.setLegalAgreements(res.data);
        })
    },
    setSupport: newState => state => ({
        support: newState,
    }),
    setLegalAgreements: newState => state => ({
        legalAgreements: newState,
    }),
};

export const view = dynLoad('view', 'Mentions/views');

export default Component(state, actions, view, 'mentions');