import { isDefined } from './isDefined';
const createDateComplientSafari = function (date) {
    if (!isDefined(date)) {
        date = new Date().toString();
    }
    if ((date.indexOf('Z') === -1) && (date.indexOf('GMT') === -1)) {
        date = date.split(' ');
        date = date[0] + 'T' + date[1] + 'Z';
    }
    return new Date(date);
};

export { createDateComplientSafari };