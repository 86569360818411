const tagsWithExpectedMultipleValues = ['BLOODPRESSURE']

export const isThereAnomaliesInAnswers = (answers) => {
    if (Array.isArray(answers)) {
        const uniqueAnswersTags = new Set(answers.map((answer) => answer.tag))
        const uniqueAnswersIds = new Set(
            answers.map((answer) => {
                const tagParts = answer.tag.split('_')
                if (
                    tagsWithExpectedMultipleValues.find(
                        (tag) => tag === tagParts[0]
                    )
                ) {
                    /*
                    Some tags (like BLOODPRESSURE_XXXX) will contain two values with same id
                    These tags are expected and to consider them as valid, we concatenate
                        the changing part (here SYSTOLIC / DIASTOLIC) with the id to make it unique
                    */
                    return `${answer.card}-${tagParts[1]}`
                }
                return answer.card
            })
        )
        if (
            answers.length !== uniqueAnswersIds.size ||
            answers.length !== uniqueAnswersTags.size
        ) {
            return true
        }
    }

    return false
}
