import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { APP_API_URL } = settings

function getPendingUserLegalAgreement(userType) {
    return queueQueries(
        `${APP_API_URL}/user-legal-agreement/pending/${userType}/`,
        'GET'
    )
}
function getLastUserLegalAgreement(userType) {
    return queueQueries(
        `${APP_API_URL}/user-legal-agreement/current/${userType}/`,
        'GET'
    )
}
function getLastPublicLegalAgreement(userType) {
    return queueQueries(
        `${APP_API_URL}/user-legal-agreement/last-public/${userType}/`,
        'GET',
        'application/json',
        true,
        true,
        true,
        false
    )
}
function postUserLegalAgreement(documentId, agreement) {
    return queueQueries(
        `${APP_API_URL}/user-legal-agreement/agreement/${documentId}/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { agreement: agreement }
    )
}

function postHealConsent(consent) {
    return queueQueries(
        `${APP_API_URL}/user/eds-consent/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { edsConsent: consent }
    )
}

export const Consent = {
    getPendingUserLegalAgreement,
    getLastPublicLegalAgreement,
    getLastUserLegalAgreement,
    postUserLegalAgreement,
    postHealConsent,
}
