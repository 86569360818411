require('@app/__tests__/setup')
const { metricPoundsToKilogram } = require('.')

describe('getUnitFromType', () => {
    test.each([
        [undefined, 'dumb', null],
        [undefined, null, null],
        [undefined, undefined, null],
        [undefined, false, null],
        [undefined, {}, null],
        [undefined, [], null],
    ])('should return %s when metric is %s', (expected, metric, decimal) => {
        expect(metricPoundsToKilogram(metric, decimal)).toStrictEqual(expected)
    })
})
