import {
    getClosest,
    isDefined,
    settings,
    getQueryVariable,
    checkForValidCSRFToken,
} from '@app/core'
import { withTranslation, withSecurity, withKafkaEvents } from '@app/utils'
import { location } from '@hyperapp/router/src/index'
import { h, app } from 'hyperapp'
import { t } from 'i18next'
import { Route, Switch, ProgressBar, Meta } from '@app/elements'
import { displayErrors } from '@app/api/errors'
//
import * as constants from '@app/constants'
import * as serviceWorker from './registerServiceWorker'
import icoClose from '@app/img/ico/ico-close.png'
import icoClosex2 from '@app/img/ico/ico-close@2x.png'

import { BotFooterView } from '@app/layouts/botfooter'

import {
    Login,
    Onboarding,
    OnboardingDone,
    UnregisterSW,
    QuatreCentQuatre,
    Timeline,
    Consent,
} from '@app/modules'
import {
    defaultLocale,
    getLocalStoredLocale,
    setLocaleInLocalStorage,
} from './core/languagesHandler'
import { synchQueryLangToApi } from './core/languagesSyncWithApi'
import { clearCache } from './core/tools/logoutAndRedirect'
import { grafanaInitialize } from './utils/grafana'

const { APP_CUSTOMISATION, APP_DEBUG } = settings

const state = {
    location: location.state,
    customerssoname: APP_CUSTOMISATION.toLowerCase(), // reference ssoname for custo
    meta: null, // meta are raw setted in public/index.html, then we override them if needed
    locale: getLocalStoredLocale()?.value ?? defaultLocale,
    medicalInfos: null,
}

const actions = {
    onUpdate: () => (state, actions) => {
        grafanaInitialize()
        actions.removeAPITokenIfNeeded()
        //
        let error = getQueryVariable('e')
        if (isDefined(error) && error !== false) {
            displayErrors(decodeURIComponent(error), 10000)
        }
        //
        let success = getQueryVariable('s')
        if (isDefined(success) && success !== false) {
            displayErrors(decodeURIComponent(success), 10000, 'success')
        }
        //
        checkForValidCSRFToken()
        //
        addEventListener('changelang', (e) => {
            actions.setLang(e.detail.lang)
        })
        synchQueryLangToApi()
    },
    location: location.actions,
    pendingAjaxQuery: () => (state, actions) => {
        actions.removeAPITokenIfNeeded()
        //
        let scheme = getQueryVariable('scheme', false) // redirect to mobile app
        if (
            typeof scheme !== 'undefined' &&
            scheme !== '' &&
            scheme !== null &&
            scheme !== false
        ) {
            window.location.href = scheme
            return false
        }
        //
        let customerssoname = null
        if (APP_DEBUG === 'true' || APP_DEBUG === true) {
            // get customer by query variable only if dev
            customerssoname = getQueryVariable('customerssoname', false)
            let customersso = getQueryVariable('customersso', false)
            customerssoname =
                customerssoname === false ? customersso : customerssoname // look at query parameter
            customerssoname =
                customerssoname === false
                    ? state.customerssoname
                    : customerssoname // if nothing, take CUSTO_SSO constant
        } else {
            // else we use the .env customer, no dyn, each customer has his instance
            customerssoname = state.customerssoname
        }
        actions.setCustomer(customerssoname)

        actions.loadCSS()
        actions.overrideMeta(customerssoname)

        const intervalID = window.setInterval(function () {
            if (document.readyState === 'complete') {
                window.clearInterval(intervalID)
            }
        }, 500)
    },
    loadCSS: () => (state, actions) => {
        require('@app/custo/_index.scss')
    },
    overrideMeta: (customerssoname) => (state, actions) => {
        let ccustomer = constants.custo.CUSTOMERS[customerssoname]
        if (isDefined(ccustomer)) {
            let iosbanner = null
            let androidbanner = null
            let customDisplay = getQueryVariable('display', false)
            if (customDisplay !== 'popup') {
                iosbanner =
                    ccustomer !== undefined &&
                    ccustomer.appurl !== undefined &&
                    ccustomer.appurl.iosbanner !== undefined
                        ? ccustomer.appurl.iosbanner
                        : null
                androidbanner =
                    ccustomer !== undefined &&
                    ccustomer.appurl !== undefined &&
                    ccustomer.appurl.androidbanner !== undefined
                        ? ccustomer.appurl.androidbanner
                        : null
            }
            let meta = {
                customer: customerssoname,
                title: ccustomer.title,
                description: t(ccustomer.description),
                favicon:
                    window.location.origin +
                    '/favicon/' +
                    (customerssoname !== false
                        ? customerssoname
                        : 'theraflow') +
                    '/favicon.ico',
                iosbanner: iosbanner,
                androidbanner: androidbanner,
            }
            actions.setMeta(meta)
        }
    },
    removeAPITokenIfNeeded: () => (state, actions) => {
        let expiration = localStorage.getItem('expiration')
        let removeToken = false
        if (!isDefined(expiration) || expiration == false) {
            removeToken = true
        } else {
            let expirationDate = expiration.split(' ')[0].split('-')
            let expirationTime = expiration
                .split(' ')[1]
                .split('.')[0]
                .split(':')
            let dateExpiration = new Date(
                Date.UTC(
                    expirationDate[0],
                    +expirationDate[1] - 1,
                    expirationDate[2],
                    expirationTime[0],
                    expirationTime[1],
                    expirationTime[2]
                )
            )
            let dateOffset = new Date().getTimezoneOffset()
            dateExpiration.setMinutes(dateExpiration.getMinutes() - dateOffset)

            let now = new Date()
            now.setMinutes(now.getMinutes() - dateOffset)

            if (dateExpiration.getTime() < now.getTime()) {
                removeToken = true
            }
        }
        if (removeToken) {
            localStorage.removeItem('expiration')
            localStorage.removeItem('apitoken')
        } else {
            //
        }
    },
    setCustomer: (newState) => (state) => ({
        customerssoname: newState,
    }),
    setMeta: (newState) => (state) => ({
        meta: newState,
    }),
    setLang: (newState) => (state) => ({
        lang: newState,
    }),
    retrieveMedicalInfos: (newState) => (state) => ({
        medicalInfos: newState,
    }),
}

const view = (state, actions) => (
    <div
        id="btzRoot"
        class={state.customerssoname}
        onupdate={actions.onUpdate}
        oncreate={actions.pendingAjaxQuery}
        data-loading={'loaded'}
        key={state.customerssoname + '-' + state.lang}
    >
        {state.meta !== null && (
            <div key={state.meta.customer}>
                <Meta {...state.meta}></Meta>
            </div>
        )}
        <ProgressBar customer={state.customerssoname}></ProgressBar>
        <Switch>
            {constants.custo.CUSTOMERS[
                state.customerssoname
            ].modules.noSWRoutes.map((route) => (
                <Route path={route}>{() => <UnregisterSW />}</Route>
            ))}

            <Route path="/onboarding/done" parent>
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <OnboardingDone
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                            locale={state.locale}
                        />
                        <BotFooterView
                            customer={state.customerssoname}
                        ></BotFooterView>
                    </dummy>
                )}
            </Route>
            <Route path="/onboarding" parent>
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <Onboarding
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                            locale={state.locale}
                        />
                        <BotFooterView
                            customer={state.customerssoname}
                        ></BotFooterView>
                    </dummy>
                )}
            </Route>
            <Route
                path="/medical-study/:medicalStudytoken/:timelineId/:idSurvey/:ymd/"
                parent
            >
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <Login
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                            directAccess={false}
                            retrieveMedicalInfos={actions.retrieveMedicalInfos}
                            medicalInfos={state.medicalInfos}
                        />
                        <div class="hide-xs">
                            <BotFooterView
                                customer={state.customerssoname}
                                medicalInfos={state.medicalInfos}
                            ></BotFooterView>
                        </div>
                    </dummy>
                )}
            </Route>
            <Route path="/medical-study/:idSurvey/:idPatient/:token/" parent>
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <Login
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                            directAccess={false}
                            retrieveMedicalInfos={actions.retrieveMedicalInfos}
                            medicalInfos={state.medicalInfos}
                        />
                    </dummy>
                )}
            </Route>
            <Route path="/medical-study/:medicalStudytoken/:timelineId/" parent>
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <Login
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                            directAccess={false}
                            retrieveMedicalInfos={actions.retrieveMedicalInfos}
                            medicalInfos={state.medicalInfos}
                        />
                        <div class="hide-xs">
                            <BotFooterView
                                customer={state.customerssoname}
                                medicalInfos={state.medicalInfos}
                            ></BotFooterView>
                        </div>
                    </dummy>
                )}
            </Route>
            <Route path="/login-from-invitation/" parent>
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <Login
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                            directAccess={false}
                            withWelcome={true}
                        />
                        <div class="hide-xs">
                            <BotFooterView
                                customer={state.customerssoname}
                                medicalInfos={state.medicalInfos}
                            ></BotFooterView>
                        </div>
                    </dummy>
                )}
            </Route>
            <Route path="/timeline" parent>
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <Timeline
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                            locale={state.locale}
                        />
                    </dummy>
                )}
            </Route>
            <Route path="/consent">
                {() => (
                    <dummy class="dummy-root">
                        <Consent customer={state.customerssoname} />
                    </dummy>
                )}
            </Route>
            <Route path="/" exact={true}>
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <Login
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                            directAccess={true}
                            retrieveMedicalInfos={actions.retrieveMedicalInfos}
                        />
                        <div class="hide-xs">
                            <BotFooterView
                                customer={state.customerssoname}
                            ></BotFooterView>
                        </div>
                    </dummy>
                )}
            </Route>

            <Route>
                {() => (
                    <dummy class="dummy-root">
                        <QuatreCentQuatre customer={state.customerssoname} />
                    </dummy>
                )}
            </Route>
        </Switch>

        <span id="btzRuler"></span>
        <div id="btzMainOverlay"></div>
        <div id="btzMainMessage" data-kind="error">
            <div
                id="btzMainMessageCross"
                onclick={(e) => {
                    getClosest(e.target, '#btzMainMessage').classList.remove(
                        'active'
                    )
                }}
            >
                <img src={icoClose} srcset={`${icoClosex2} 2x`} alt="" />
            </div>
            <div id="btzMaintextContent"></div>
        </div>
    </div>
)

/*
there is no auto-relog so we must clear apitoken to avoid side-effect when comming with an other patient
*/
const clearCacheOnTheseUrl = ['/', '/login-from-invitation/']
const shouldBeCleared = clearCacheOnTheseUrl.includes(window.location.pathname)
if (shouldBeCleared) {
    clearCache()
}

synchQueryLangToApi()
const isThereLocaleInLocalStorage = getLocalStoredLocale()
if (!isThereLocaleInLocalStorage) {
    // si pas de locale ni langue, mettre fr-FR par défaut dans le LS
    setLocaleInLocalStorage(defaultLocale)
}

let appOnSteroids = app
appOnSteroids = withTranslation({})(appOnSteroids) // grabs all locales, set one as default
appOnSteroids = withKafkaEvents({})(appOnSteroids) // grabs all locales, set one as default
appOnSteroids = withSecurity({})(appOnSteroids) // keeps an eye on tokens and triggers actions

const instance = appOnSteroids(state, actions, view, document.body)
// eslint-disable-next-line
const unsubscribe = location.subscribe(instance.location)

window.main = instance

// instance.initStorage()

serviceWorker.unregister()
