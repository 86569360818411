const {
    Locale,
    setLocaleInLocalStorage,
    getQueryLocale,
    getLocalStoredLocale,
} = require('../languagesHandler')
import { User } from '@app/api/User'
import { updateLocaleInUrl } from '../tools/localeInUrl'
import { getQueryVariable } from '../tools/getQueryVariable'

const sendLanguageToApi = (lang) => {
    const storedUser = localStorage.getItem('user')
    if (storedUser) {
        const user = JSON.parse(storedUser)
        if (user.id && localStorage.getItem('apitoken')) {
            try {
                const localeFromQueryParam = getQueryVariable('locale', false)
                const localeFromLocalStore = getLocalStoredLocale()
                //
                const regionFromCustomer =
                    user.userTheraflow.doctor.customer.country
                let localeFromUser = null
                try {
                    localeFromUser = Locale.parseLocale(
                        `${user.locale.slice(0, 2)}-${regionFromCustomer}`
                    )
                } catch {
                    localeFromUser = Locale.parseLocale(user.locale)
                }
                const langFromUser = localeFromUser.lang
                const regionFromUser = localeFromUser.region
                /* update LS even if we dont sync with API
                    because: LS must always reflect locale queryparam
                    but: if lang remain the same and region change, no need to sync with api
                */
                localeFromUser.lang = lang
                setLocaleInLocalStorage(localeFromUser.value)
                //
                /* update URL if locale in query param differ from new user locale
                 */
                if (localeFromUser.value !== localeFromQueryParam) {
                    updateLocaleInUrl({ updateUrl: true })
                }
                //
                if (
                    (langFromUser &&
                        langFromUser !== localeFromLocalStore.lang) ||
                    (regionFromUser &&
                        regionFromUser !== localeFromLocalStore.region)
                ) {
                    return User.updateUser({
                        locale: localeFromUser.value,
                    }).then((res) => {
                        const apiUser = res.data
                        if (apiUser) {
                            localStorage.setItem(
                                'user',
                                JSON.stringify(apiUser)
                            )
                        }
                    })
                }
            } catch (e) {
                return Promise.resolve(null)
            }
        }
    }
    return Promise.resolve(null)
}

const synchQueryLangToApi = () => {
    const locale = getQueryLocale()
    if (locale) {
        sendLanguageToApi(locale.lang)
    }
    return Promise.resolve({ locale: locale })
}

export { sendLanguageToApi, synchQueryLangToApi }
