require('@app/__tests__/setup')
const { metricDegreesToFahrenheit } = require('.')

describe('metricDegreesToFahrenheit', () => {
    test.each([
        [undefined, 'dumb', null],
        [undefined, null, null],
        [undefined, undefined, null],
        [undefined, false, null],
        [undefined, {}, null],
        [undefined, [], null],
    ])('should return %s when metric is %s', (expected, metric, decimal) => {
        expect(metricDegreesToFahrenheit(metric, decimal)).toBe(expected)
    })
})
