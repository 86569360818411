import { h } from 'hyperapp'
import { default as classMerge } from 'classcat'
import { isDefined } from '@app/core'
import './index.scss'

const Card = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['btzCard', classes])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

const Image = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['btzCard-btzImage', classes])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

const Media = (props, children) => {
    const { classes, ...otherProps } = props

    let source = props.src,
        kind = ''
    if (source !== null) {
        kind =
            source.indexOf('youtube') > -1 || source.indexOf('vimeo') > -1
                ? 'video'
                : 'image'
    }

    if (kind == 'video') {
        if (isDefined(source)) {
            if (source.indexOf('youtube') > -1) {
                source =
                    '//www.youtube.com/embed/' +
                    source.split('?v=').pop() +
                    '?controls=0&showinfo=0'
            } else if (source.indexOf('vimeo') > -1) {
                source =
                    '//player.vimeo.com/video/' +
                    source.split('/').pop() +
                    '?title=0&portrait=0&byline=0&badge=0'
            }
        }
        return (
            <iframe
                title={`iframe-video-${source}`}
                style={{ width: '100%', height: '100%' }}
                src={source}
                width="100%"
                height="100%"
                frameborder="0"
            >
                {children}
            </iframe>
        )
    } else {
        const allClasses = classMerge(['btzBackgroundCover', classes])

        return (
            <div
                class={allClasses}
                {...otherProps}
                style={{ 'background-image': 'url(' + props.src + ')' }}
            >
                {children}
            </div>
        )
    }
}

const Header = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['btzCard-btzHeader', classes])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

const Body = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['btzCard-btzBody', classes])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

const Footer = (props, children) => {
    const { classes, ...otherProps } = props

    const allClasses = classMerge(['btzCard-btzFooter', classes])

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    )
}

Card.Image = Image
Card.Media = Media
Card.Header = Header
Card.Body = Body
Card.Footer = Footer
export { Card }
