import { h } from 'hyperapp'
import { Component } from '@app/utils'
import {
    isFloat,
    isDefined,
    autoSelectInput,
    autoClicAndSelectInput,
} from '@app/core'
import './index.scss'

import ImgLess from './ico-less.png'
import ImgLessx2 from './ico-less@2x.png'

import ImgPlus from './ico-plus.png'
import ImgPlusx2 from './ico-plus@2x.png'
import { transformMetric } from '@app/core/tools/metrics/transformMetric'

const Counter = Component(
    {
        count: 0,
        max: 5,
        min: 0,
        default: 0,
        step: null,
        tmout: null,
        onfocus: null,
        oninput: null,
        name: 'counter',
        floating: false,
        type: '',
        defaultDecimal: 0,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            state.max = transformMetric(
                props.type,
                props.max || state.max,
                'input'
            )
            state.min = transformMetric(
                props.type,
                props.min || state.min,
                'input'
            )
            state.step = props.step || state.step
            state.count = props.count || state.count
            if (props.onfocus) {
                state.onfocus = props.onfocus
            }
            if (props.oninput) {
                state.oninput = props.oninput
            }
            if (props.name) {
                state.name = props.name
            }
            if (props.floating) {
                state.floating = props.floating
                state.defaultDecimal = props.defaultDecimal ?? 1
            }
            if (props.default) {
                actions.setCount(props.default)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            //
        },
        down: () => (state) => ({
            count: +state.count - 1 < state.min ? state.min : +state.count - 1,
        }),
        up: () => (state) => ({
            count: +state.count + 1 > state.max ? state.max : +state.count + 1,
        }),
        setCount: (newState) => (state) => ({
            count: newState,
        }),
        isNumberKey: (evt) => (state, actions) => {
            let charCode = evt.which ? evt.which : evt.keyCode
            if (charCode === 38) {
                actions.up()
            } else if (charCode === 40) {
                actions.down()
            } else {
                let value = isDefined(evt.target) ? evt.target.value : evt.value
                if (String(value).length > 1) {
                    value = value.replace(/^0+/, '')
                }
                let floatStep = String(state.step)
                if (
                    state.floating &&
                    (!isDefined(floatStep) ||
                        (isDefined(floatStep) && floatStep.indexOf('.') > -1))
                ) {
                    if (value === '') {
                        actions.setCount('')
                    } else if (!isFloat(value.replace(',', '.'))) {
                        return true
                    }
                    let decimalPart = value.split(
                        value.includes(',') ? ',' : '.'
                    )
                    if (value === '') {
                        actions.setCount('')
                    } else if (
                        isDefined(decimalPart[1]) &&
                        decimalPart[1].length > 1
                    ) {
                        if (isDefined(floatStep)) {
                            let decimalMax = floatStep.split('.')
                            if (decimalMax.length > 1) {
                                decimalMax = String(decimalMax[1]).length
                                if (+decimalPart[1].length <= +decimalMax) {
                                    //
                                } else {
                                    return true
                                }
                            } else {
                                return true
                            }
                        } else if (
                            state.defaultDecimal > 0 &&
                            +decimalPart[1].length > state.defaultDecimal
                        ) {
                            return true
                        } else {
                            return true
                        }
                    }
                } else {
                    let pattern = /^[0-9]+$/
                    if (value === '') {
                        actions.setCount('')
                    } else if (!pattern.test(value)) {
                        return true
                    }
                }

                state.tmout = setTimeout(function () {
                    clearTimeout(state.tmout)
                    state.tmout = null
                    if (value === '') {
                        actions.setCount('')
                    } else if (state.floating) {
                        actions.setCount(value)
                    } else if (!isNaN(+value)) {
                        actions.setCount(+value)
                    }
                }, 10)
            }
            return false
        },
    },
    (state, actions) => (props, children) => (
        <div
            class="btzCounter"
            id={props.id}
            data-id={props.did}
            data-tag={props.tag}
            data-value={state.count}
            data-kind={state.kind}
            data-min={state.min}
            data-max={state.max}
        >
            <input
                id={'input-' + (props.id || props.name)}
                type="text"
                inputmode="decimal"
                class="btzCounter-btzCounterValue"
                name={props.name}
                value={state.count}
                data-value={state.count}
                placeholder={'0'}
                onfocus={(el) => {
                    state.onfocus(el)
                    autoSelectInput(el)
                }}
                onclick={(el) => {
                    if (state.count === 0) {
                        actions.setCount('')
                    }
                    autoSelectInput(el)
                }}
                oninput={(el) => {
                    actions.isNumberKey(el)
                    if (props.mobileOnboarding && el) {
                        autoClicAndSelectInput(el)
                    }
                    if (state.oninput) {
                        state.oninput(el)
                    }
                }}
                autofocus
            />
            {props.label && (
                <div class="btzCounter-btzCounterLabel">{props.label}</div>
            )}
            <button
                class="btzCounter-btzCounterButtonLeft"
                onclick={() => {
                    actions.down()
                    if (state.oninput !== null) {
                        state.oninput({
                            target: document.getElementById(
                                'input-' + props.id
                            ),
                        })
                    }
                }}
            >
                <img src={ImgLess} srcset={`${ImgLessx2} 2x`} alt="" class="" />
            </button>
            <button
                class="btzCounter-btzCounterButtonRight"
                onclick={() => {
                    actions.up()
                    if (state.oninput !== null) {
                        state.oninput({
                            target: document.getElementById(
                                'input-' + props.id
                            ),
                        })
                    }
                }}
            >
                <img src={ImgPlus} srcset={`${ImgPlusx2} 2x`} alt="" class="" />
            </button>
        </div>
    ),
    'counter'
)

export { Counter }
