import { metricDegreesToFahrenheitFormula } from '../..'
import { generatePower } from '../../../power'

const metricDegreesToFahrenheit = (metric, decimal) => {
    if (metric && !isNaN(+metric) && !Array.isArray(metric)) {
        const power = generatePower(decimal) ?? 1
        const calculation = metricDegreesToFahrenheitFormula(metric) * power
        if (!decimal) {
            return String(calculation)
        }
        return String(parseFloat((calculation / power).toFixed(decimal)))
    }
    return undefined
}

export { metricDegreesToFahrenheit }
