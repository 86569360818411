import { getLocalStoredLocale } from '@app/core/languagesHandler'

export const dateTimeFormat = (date, options) => {
    if (!date || !(date instanceof Date)) {
        return undefined
    }
    const locale = getLocalStoredLocale()

    return Intl.DateTimeFormat(locale.value, options || {}).format(date)
}
