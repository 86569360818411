require('@app/__tests__/setup')
const { isThereAnomaliesInAnswers } = require('.')

const answerSet = [
    {
        tag: 'ONCOHEMATOSPT_0',
        value: '1',
        card: '6284ee710c6f8e908605c222',
    },
    {
        tag: 'CRYPTEDWEIGHT_VALUE',
        value: '90',
        card: '6284ee710c6f8e908605c223',
    },
    {
        tag: 'ONCOHEMATOSPT_2',
        value: '1',
        card: '6284ee710c6f8e908605c224',
    },
    {
        tag: 'ONCOHEMATOSPT_3',
        value: '0',
        card: '6284ee710c6f8e908605c225',
    },
    {
        tag: 'ONCOHEMATOSPT_4',
        value: '1',
        card: '6284ee710c6f8e908605c226',
    },
    {
        tag: 'ONCOHEMATOSPT_5',
        value: '0',
        card: '6284ee710c6f8e908605c227',
    },
    {
        tag: 'ONCOHEMATOSPT_7',
        value: '1',
        card: '6284ee710c6f8e908605c229',
    },
    {
        tag: 'ONCOHEMATOSPT_8',
        value: '1',
        card: '6284ee710c6f8e908605c22a',
    },
    {
        tag: 'ONCOHEMATOSPT_9',
        value: '4',
        card: '6284ee710c6f8e908605c22b',
    },
    {
        tag: 'ONCOHEMATOSPT_10',
        value: '0',
        card: '6284ee710c6f8e908605c22c',
    },
    {
        tag: 'ONCOHEMATOSPT_11',
        value: '0',
        card: '6284ee710c6f8e908605c22d',
    },
    {
        tag: 'ONCOHEMATOSPT_12',
        value: '0',
        card: '6284ee710c6f8e908605c22e',
    },
    {
        tag: 'ONCOHEMATOSPT_13',
        value: '0',
        card: '6284ee710c6f8e908605c231',
    },
    {
        tag: 'ONCOHEMATOSPT_14',
        value: '1',
        card: '6284ee710c6f8e908605c234',
    },
    {
        tag: 'ONCOHEMATOSPT_14A',
        value: '0',
        card: '6284ee710c6f8e908605c235',
    },
    {
        tag: 'ONCOHEMATOSPT_14B',
        value: '0',
        card: '6284ee710c6f8e908605c236',
    },
    {
        tag: 'ONCOHEMATOSPT_15',
        value: '0',
        card: '6284ee710c6f8e908605c237',
    },
    {
        tag: 'ONCOHEMATOSPT_16',
        value: '4',
        card: '6284ee720c6f8e908605c239',
    },
    {
        tag: 'ONCOHEMATOSPT_17',
        value: '1',
        card: '6284ee720c6f8e908605c23a',
    },
    {
        tag: 'ONCOHEMATOSPT_18',
        value: '4',
        card: '6284ee720c6f8e908605c23b',
    },
    {
        tag: 'ONCOHEMATOSPT_19',
        value: '1',
        card: '6284ee720c6f8e908605c23c',
    },
    {
        tag: 'ONCOHEMATOSPT_19A',
        value: '1',
        card: '6284ee720c6f8e908605c23d',
    },
    {
        tag: 'ONCOHEMATOSPT_20',
        value: '1',
        card: '6284ee720c6f8e908605c23e',
    },
    {
        tag: 'ONCOHEMATOSPT_21',
        value: '1',
        card: '6284ee720c6f8e908605c23f',
    },
    {
        tag: 'ONCOHEMATOSPT_22',
        value: '0',
        card: '6284ee720c6f8e908605c240',
    },
    {
        tag: 'ONCOHEMATOSPT_23',
        value: '0',
        card: '6284ee720c6f8e908605c242',
    },
    {
        tag: 'ONCOHEMATOSPT_24',
        value: '0',
        card: '6284ee720c6f8e908605c243',
    },
    {
        tag: 'ONCOHEMATOSPT_25',
        value: '4',
        card: '6284ee720c6f8e908605c244',
    },
    {
        tag: 'ONCOHEMATOSPT_26',
        value: '0',
        card: '6284ee720c6f8e908605c245',
    },
    {
        tag: 'ONCOHEMATOSPT_27',
        value: '0',
        card: '6284ee720c6f8e908605c247',
    },
    {
        tag: 'ONCOHEMATOSPT_28',
        value: '4',
        card: '6284ee720c6f8e908605c248',
    },
    {
        tag: 'ONCOHEMATOSPT_29',
        value: '1',
        card: '6284ee720c6f8e908605c249',
    },
    {
        tag: 'ONCOHEMATOSPT_30',
        value: '0',
        card: '6284ee720c6f8e908605c24a',
    },
    {
        tag: 'ONCOHEMATOSPT_31',
        value: '1',
        card: '6284ee720c6f8e908605c24b',
    },
    {
        tag: 'ONCOHEMATOSPT_31A',
        value: '0',
        card: '6284ee720c6f8e908605c24c',
    },
    {
        tag: 'ONCOHEMATOSPT_31B',
        value: '1',
        card: '6284ee720c6f8e908605c24d',
    },
    {
        tag: 'ONCOHEMATOSPT_32',
        value: '0',
        card: '6284ee720c6f8e908605c24e',
    },
    {
        tag: 'ONCOHEMATOSPT_33',
        value: '1',
        card: '6284ee720c6f8e908605c24f',
    },
    {
        tag: 'ONCOHEMATOSPT_34',
        value: '1',
        card: '6284ee720c6f8e908605c250',
    },
    {
        tag: 'ONCOHEMATOSPT_35',
        value: '0',
        card: '6284ee720c6f8e908605c251',
    },
    {
        tag: 'ONCOHEMATOSPT_36',
        value: '0',
        card: '6284ee720c6f8e908605c252',
    },
    {
        tag: 'ONCOHEMATOSPT_37',
        value: '0',
        card: '6284ee720c6f8e908605c253',
    },
]

const answersSetWithDuplicatedTags = answerSet.concat({
    tag: 'ONCOHEMATOSPT_37',
    value: '1',
    card: '6284ee720c6f8e908605c253A',
})

const answerSetWithExpectedDuplicatedId = answerSet.concat(
    {
        tag: 'BLOODPRESSURE_SYSTOLIC',
        value: 120,
        card: '628668a6b12e5793130323f2',
    },
    {
        tag: 'BLOODPRESSURE_DIASTOLIC',
        value: 70,
        card: '628668a6b12e5793130323f2',
    }
)

const answerSetWithUnexpectedDuplicatedId = answerSet.concat(
    {
        tag: 'UNWANTEDTAG_SYSTOLIC',
        value: 120,
        card: '628668a6b12e5793130323f2',
    },
    {
        tag: 'UNWANTEDTAG_DIASTOLIC',
        value: 70,
        card: '628668a6b12e5793130323f2',
    }
)

describe('isThereAnomaliesInAnswers', () => {
    test('should not find any anomalies', async () => {
        expect(isThereAnomaliesInAnswers(answerSet)).toBe(false)
    })

    test('should not find any anomalies, even with BLOODPRESSURE duplicate card id', async () => {
        expect(
            isThereAnomaliesInAnswers(answerSetWithExpectedDuplicatedId)
        ).toBe(false)
    })

    test('should find anomaly (about a duplicate answer tag)', async () => {
        expect(isThereAnomaliesInAnswers(answersSetWithDuplicatedTags)).toBe(
            true
        )
    })

    test('should find anomaly (about unexpected double values)', async () => {
        expect(
            isThereAnomaliesInAnswers(answerSetWithUnexpectedDuplicatedId)
        ).toBe(true)
    })
})
