import { getLocalStoredLocale } from '@app/core/languagesHandler'

const keepLocaleInUrlRegex = /[?&]locale=([^&#])*/
const updateLocaleInUrlRegex = /locale=([^&#])*/

const keepLocaleInUrl = function ({
    url = window.location.href,
    updateUrl = false,
} = {}) {
    const locale = getLocalStoredLocale()
    if (!locale) {
        return url
    }

    if (RegExp(keepLocaleInUrlRegex).exec(url)) {
        updateLocaleInUrl({ url, updateUrl })
    } else {
        const querySeparator = url.indexOf('?') > -1 ? '&' : '?'
        const newUrl = `${url}${querySeparator}locale=${locale.value}`
        if (updateUrl) {
            window.history.pushState('', '', newUrl)
        }
        return newUrl
    }
    return url
}

const updateLocaleInUrl = function ({
    url = window.location.href,
    updateUrl = false,
} = {}) {
    const locale = getLocalStoredLocale()
    if (!locale) {
        return
    }

    if (RegExp(keepLocaleInUrlRegex).exec(url)) {
        const newUrl = url.replace(
            updateLocaleInUrlRegex,
            `locale=${locale.value}`
        )
        if (updateUrl) {
            window.history.pushState('', '', newUrl)
        }
        return newUrl
    }
    return url
}

export { keepLocaleInUrl, updateLocaleInUrl }
