import { settings } from '@app/core/settings'
import { default as i18n } from 'i18next'
import { languages } from '@app/core/languagesHandler'
import {
    defaultLocale,
    getLangFromLocale,
    getLocalStoredLocale,
} from '@app/core/languagesHandler'

const translationKeys = [
    'translation',
    'onboarding',
    'login',
    'register',
    'generals',
    'datas',
    'timeline',
]

const loadTraductionFile = async (lang) => {
    const locale = getLocalStoredLocale()
    // TO-REMOVE in mep 7 (when es, de, it will be added)
    const availableInLanguePicker = languages.find(
        (language) => language.key === lang
    )?.availableInLanguePicker
    // TO-REMOVE in mep 7 (when es, de, it will be added)
    if (!locale || !availableInLanguePicker) {
        return
    }
    locale.lang = lang

    if (!i18n.hasResourceBundle(locale.value, translationKeys[0])) {
        const traductionByLocale = await loadTraductionFileByLocaleOrLang(
            locale.value
        )
        const traductionByLang = await loadTraductionFileByLocaleOrLang(
            locale.lang
        )

        translationKeys.forEach((translationKey) => {
            const translation = {
                ...(traductionByLang?.[translationKey] ?? {}),
                ...(traductionByLocale?.[translationKey] ?? {}),
            }
            i18n.addResourceBundle(
                locale.value,
                translationKey,
                translation,
                true,
                true
            )
        })
    }

    i18n.changeLanguage(locale.value)
}

const loadTraductionFileByLocaleOrLang = async (localeOrLang) => {
    try {
        return await import(`@app/locales/${localeOrLang}/index.js`).then(
            (traductionFile) => {
                return traductionFile
            }
        )
    } catch {
        return Promise.resolve({})
    }
}

const withTranslation =
    (opts = {}) =>
    (nextApp) =>
    (initialState, initialActions, initialView, initialRoot) => {
        let lng = getLangFromLocale(
            getLocalStoredLocale()?.value ?? defaultLocale
        )
        //
        const options = {
            ...{
                lng: lng,
                fallbackLng: settings.APP_FALLBACKLANG,
                debug: false,
                nsSeparator: false,
                keySeparator: false,
                formatSeparator: false,
                pluralSeparator: false,
                contextSeparator: false,
                joinArrays: true,
                //
                resources: {},
                interpolation: {
                    escapeValue: true,
                    formatSeparator: false,
                },
            },
            ...opts,
        }

        const enhancedState = {
            lang: options.lng,
        }

        const enhancedActions = {
            initLang: () => {
                i18n.init(options)
                const locale = getLocalStoredLocale()
                if (locale) {
                    loadTraductionFile(locale.lang)
                }
            },
            changeLanguage: (lang) => {
                if (lang !== 'null') {
                    loadTraductionFile(lang)
                }
            },
        }
        enhancedActions.initLang()
        addEventListener('changelang', (e) => {
            enhancedActions.changeLanguage(e.detail.lang)
        })

        return nextApp(
            { ...initialState, ...enhancedState },
            { ...initialActions, ...enhancedActions },
            initialView,
            initialRoot
        )
    }

export { withTranslation, loadTraductionFile }
