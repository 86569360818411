import { t } from 'i18next'
import { LocalesConversionsUnit, defaultLocalesUnits } from '..'
import { getLocalStoredLocale } from '@app/core/languagesHandler'

const getUnitFromType = (type) => {
    const locale = getLocalStoredLocale()
    const region = locale.region
    const targetUnit = LocalesConversionsUnit?.[type]?.regions?.includes(region)
        ? LocalesConversionsUnit?.[type].unit
        : null
    if (region && targetUnit) {
        return t(targetUnit)
    }
    return t(defaultLocalesUnits?.[type]) || ''
}

export { getUnitFromType }
