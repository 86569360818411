import IMask from 'imask'
import moment from 'moment'
import { t } from 'i18next'
import { defaultLocale, getLocalStoredLocale } from '@app/core/languagesHandler'

const getMomentFormat = (locale) => {
    return moment
        .localeData(locale)
        .longDateFormat('L')
        .replace(/(\/|-|\.)/g, ' $1 ')
}

const createNewMask = (name, locale, previousLocale) => {
    const el = document.getElementById(`${name}-datepicker`)
    const momentFormat = locale ? getMomentFormat(locale) : 'DD/MM/YYYY'
    const momentFormatPrevious = getMomentFormat(previousLocale)
    //
    if (el) {
        const newValue = el.value
            ? moment(el.value, momentFormatPrevious).format(momentFormat)
            : null
        if (newValue) {
            el.value = newValue
        }
        const newMask = IMask(el, {
            mask: Date,
            pattern: momentFormat,
            lazy: false,
            format: (date) => moment(date).format(momentFormat),
            parse: (str) => moment(str, momentFormat),
            blocks: {
                YYYY: {
                    mask: IMask.MaskedRange,
                    placeholderChar: t('A'),
                    from: 1700,
                    to: 2999,
                },
                MM: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12,
                    placeholderChar: t('M'),
                    // add '0' when typing a number that cant be a month on two numbers (like '6' cant be '16' or more so -> '06')
                    autofix: 'pad',
                },
                DD: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31,
                    placeholderChar: t('J'),
                    autofix: 'pad',
                },
            },
        })
        if (newValue) {
            newMask.value = newValue
        }

        return newMask
    }

    return undefined
}

export { createNewMask }
