import { t } from 'i18next';
import { h } from 'hyperapp';
import { allRoutes } from '@app/core';
import { Link } from '@app/elements';

export const view = (state, actions) => (props, children) => (
    <div key='QuatreCentQuatre'>
        <div class='btzQuatreCentQuatre'>
            <div class='btzQuatreCentQuatre-btzWrapper'>
                <p class='btzTitle btzXl' style='line-height: 180px;'>{'• ' + t('Page indisponible', {ns: 'generals'}) + ' •'}</p>
                <p class='btzTitle' style='font-size: 20px;'>{t('Vous devez contacter ce site en utilisant un lien envoyé sur votre smartphone.', {ns: 'generals'})}</p>
            </div>
        </div>
    </div>
);