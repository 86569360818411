require('@app/__tests__/setup')
const {
    getLangFromLocale,
    getQueryLocale,
    getLocalStoredLocale,
    setLocaleInLocalStorage,
} = require('.')

describe('languagesHandler', () => {
    test('getLangFromLocale', async () => {
        setLocaleInLocalStorage('fr-FR')
        expect(getLangFromLocale()).toBe('fr')

        setLocaleInLocalStorage('du-MB')
        expect(getLangFromLocale()).toBe('fr')
    })

    test('getQueryLocale', async () => {
        const ql = getQueryLocale()
        expect(ql).toBe(undefined)
        expect(getLocalStoredLocale()?.value).toBe('fr-FR')
        expect(getLocalStoredLocale()?.lang).toBe('fr')
        expect(getLocalStoredLocale()?.region).toBe('FR')
    })

    test('getLocalStoredLocale', async () => {
        setLocaleInLocalStorage('fr-FR')
        expect(getLocalStoredLocale()?.value).toBe('fr-FR')
        expect(getLocalStoredLocale()?.lang).toBe('fr')
        expect(getLocalStoredLocale()?.region).toBe('FR')

        setLocaleInLocalStorage('du-MB')
        expect(getLocalStoredLocale()?.value).toBe('fr-FR')
        expect(getLocalStoredLocale()?.lang).toBe('fr')
        expect(getLocalStoredLocale()?.region).toBe('FR')
    })

    test('setLocaleInLocalStorage', async () => {
        setLocaleInLocalStorage('fr-FR')
        expect(getLocalStoredLocale()?.value).toBe('fr-FR')
        expect(getLocalStoredLocale()?.lang).toBe('fr')
        expect(getLocalStoredLocale()?.region).toBe('FR')

        setLocaleInLocalStorage('du-MB')
        expect(getLocalStoredLocale()?.value).toBe('fr-FR')
        expect(getLocalStoredLocale()?.lang).toBe('fr')
        expect(getLocalStoredLocale()?.region).toBe('FR')
    })
})
