import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

export function iePolyfills() {
    if ('NodeList' in window && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function (callback, thisArg) {
            thisArg = thisArg || window
            for (var i = 0; i < this.length; i++) {
                callback.call(thisArg, this[i], i, this)
            }
        }
    }
    ;(function () {
        if (typeof window.CustomEvent === 'function') return false
        function CustomEvent(event, params) {
            params = params || {
                bubbles: false,
                cancelable: false,
                detail: undefined,
            }
            var evt = document.createEvent('CustomEvent')
            evt.initCustomEvent(
                event,
                params.bubbles,
                params.cancelable,
                params.detail
            )
            return evt
        }
        CustomEvent.prototype = window.Event.prototype
        window.CustomEvent = CustomEvent
    })()
    if (!Array.prototype.find) {
        Array.prototype.find = function (predicate) {
            if (this == null) {
                throw new TypeError(
                    'Array.prototype.find called on null or undefined'
                )
            }
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function')
            }
            var list = Object(this)
            var length = list.length >>> 0
            var thisArg = arguments[1]
            var value

            for (var i = 0; i < length; i++) {
                value = list[i]
                if (predicate.call(thisArg, value, i, list)) {
                    return value
                }
            }
            return undefined
        }
    }
}
