require('@app/__tests__/setup')
const { dateTimeEndingZ } = require('.')

describe('dateTimeEndingZ', () => {
    test.each([
        [undefined, null],
        [undefined, undefined],
        [undefined, {}],
        [undefined, []],
        [undefined, 'dumb'],
        [undefined, 0],
        ['2024-12-01 23:01:01Z', '2024-12-01 23:01:01'],
    ])(
        'should return %s when input stringDate is %s',
        (expected, stringDate) => {
            expect(dateTimeEndingZ(stringDate)).toBe(expected)
        }
    )
})
