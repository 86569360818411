require('@app/__tests__/setup')
const { transformMetric } = require('.')

describe('transformMetric', () => {
    test.each([
        ['120', 'cryptedweight', '120', 'input'],
        ['120', 'cryptedweight', '120', 'output'],
        ['120', 'cryptedtemperature', '120', 'input'],
        ['120', 'cryptedtemperature', '120', 'output'],
    ])('should return %s when metric is %s', (expected, type, metric, way) => {
        localStorage.setItem('locale', 'fr-FR')
        expect(transformMetric(type, metric, way)).toBe(expected)
    })

    test.each([
        ['264.55', 'cryptedweight', '120', 'input'],
        ['54.43', 'cryptedweight', '120', 'output'],
        ['248', 'cryptedtemperature', '120', 'input'],
        ['48.89', 'cryptedtemperature', '120', 'output'],
        ['120', 'dumb', '120', 'input'],
    ])('should return %s when metric is %s', (expected, type, metric, way) => {
        localStorage.setItem('locale', 'en-US')
        expect(transformMetric(type, metric, way)).toBe(expected)
    })

    test.each([
        ['120', 'cryptedweight', '120', 'input'],
        ['120', 'cryptedweight', '120', 'output'],
        ['120', 'cryptedtemperature', '120', 'input'],
        ['120', 'cryptedtemperature', '120', 'output'],
        ['120', 'dumb', '120', 'input'],
    ])('should return %s when metric is %s', (expected, type, metric, way) => {
        localStorage.setItem('locale', 'en-FR')
        expect(transformMetric(type, metric, way)).toBe(expected)
    })

    test.each([
        ['264.55', 'cryptedweight', '120', 'input'],
        ['54.43', 'cryptedweight', '120', 'output'],
        ['248', 'cryptedtemperature', '120', 'input'],
        ['48.89', 'cryptedtemperature', '120', 'output'],
        ['120', 'dumb', '120', 'input'],
    ])('should return %s when metric is %s', (expected, type, metric, way) => {
        localStorage.setItem('locale', 'fr-US')
        expect(transformMetric(type, metric, way)).toBe(expected)
    })

    test.each([
        ['264.55', 'cryptedweight', '120', 'input'],
        ['54.43', 'cryptedweight', '120', 'output'],
        ['120', 'cryptedtemperature', '120', 'input'],
        ['120', 'cryptedtemperature', '120', 'output'],
        ['120', 'dumb', '120', 'input'],
    ])('should return %s when metric is %s', (expected, type, metric, way) => {
        localStorage.setItem('locale', 'fr-GB')
        expect(transformMetric(type, metric, way)).toBe(expected)
    })
})
