import { t } from 'i18next'
import { h } from 'hyperapp'
import { Component } from '@app/utils'
import { allRoutes, getUser } from '@app/core'
import { User } from '@app/api'
import { dynLoad } from '@app/core'
import {
    defaultLocale,
    getLangFromLocale,
    getLocalStoredLocale,
    setLocaleInLocalStorage,
    languages,
    Locale,
} from '@app/core/languagesHandler'
import { keepLocaleInUrl } from '@app/core/tools/localeInUrl'

export const UserMenuView = dynLoad('view', 'usermenus', 'layouts')

const UserMenu = Component(
    {
        URLEmptyTimeline: '',
        URLUserMedia: '',
        userName: '',
        currentLanguage: getLangFromLocale(defaultLocale),
        messageContent: '',
        messageKind: 'success',
        messageActive: false,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            let locale = getLocalStoredLocale()
            if (locale) {
                // TO-REMOVE in mep 7 (when es, de, it will be added)
                if (
                    !languages
                        .filter((language) => language.availableInLanguePicker)
                        .find((language) => language.key === locale.lang)
                ) {
                    locale = Locale.parseLocale(defaultLocale)
                }
                // TO-REMOVE in mep 7 (when es, de, it will be added)
                actions.setCurrentLanguage(locale.lang)
            }

            if (props && props.display !== 'login') {
                let cb = function (user) {
                    if (user) {
                        if (user.nick) {
                            actions.setUserName(user.nick)
                        }
                        if (user.media && user.media.url) {
                            actions.setUserMedia(user.media.url)
                        }
                    }
                }
                getUser(cb)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {},
        setUserName: (newState) => (state) => ({
            userName: newState,
        }),
        setUserMedia: (newState) => (state) => ({
            URLUserMedia: newState,
        }),
        setCurrentLanguage: (newState) => (state) => ({
            currentLanguage: newState,
        }),
        clearTimeline: () => (state, actions) => {
            User.clearTimeline().then(() => {
                actions.setQueryMessage({
                    newStateKind: 'success',
                    newStateContent: t(
                        'Votre journal a été vider avec succés.'
                    ),
                })
                actions.displaySuccessMessage(true)
            })
        },
        deconnectUser: () => (state, actions) => {
            localStorage.setItem('apitoken', null)
            localStorage.setItem('refresh', null)
            localStorage.setItem('expiration', null)
            window.location = allRoutes['index'].pathname
        },
        updateLanguage: (lang) => (state, actions) => {
            const locale = getLocalStoredLocale()

            if (locale && state.currentLanguage !== lang) {
                locale.lang = lang
                //
                actions.setCurrentLanguage(locale.lang)
                setLocaleInLocalStorage(locale.value)
                keepLocaleInUrl({ updateUrl: true })
                const event = new CustomEvent('changelang', {
                    detail: { lang: locale.lang },
                })
                dispatchEvent(event)
            }
        },
        setQueryMessage:
            ({ newStateKind, newStateContent }) =>
            (state) => ({
                messageKind: newStateKind,
                messageContent: newStateContent,
            }),
        displaySuccessMessage: (newState) => (state) => ({
            messageActive: newState,
        }),
    },
    UserMenuView,
    'usermenu'
)

export { UserMenu }
