/* eslint-disable no-undef */
import { logoutAndRedirect } from '@app/core'
import { allRoutes, addCSRFToken, isDefined } from '@app/core'
import { settings } from '@app/core/settings'

let msgTimeout = null

const { APP_WEB_URL, APP_WEBAPP_URL } = settings

const handleErrors = (response) => {
    // if (response.length === 38) {
    // if (Array.isArray(response) && response.length === 0) {
    // 	response = {
    // 		code: 305,
    // 		message:
    // 			"Votre questionnaire n'est pas accessible entre 23h et 02h du matin. En cas d'urgence, contactez le 112. (code \\#305)",
    // 	};
    // }
    //
    if (response.code) {
        switch (+response.code) {
            case 102: // user not found
            case 200:
            case 201:
            case 202:
            case 203: // wrong mail/pwd (login)
            case 208: // 5 errors, account blocked
            case 303: // no timeline for this day
            case 433: // invalid date
            case 441: // invalid responses
            case 510: // tracker conflict
            case 812: // invalid invitation code, see #BL-5516
            case 907: // no debit was done with this credit card
            case 1801: // passphrase invalide
            case 432: // invalid auth code
                // OK, no error to display
                break

            case 122: // no valid payment method
            case 805: // customer license is expired
                logoutAndRedirect()
                break
            case 305:
                displayErrors(response.message, 10000)
                setTimeout(() => {
                    let customDisplay = sessionStorage.getItem('customDisplay')
                    if (customDisplay === 'popup') {
                        var eventFired = false
                        try {
                            // eslint-disable-next-line
                            if (
                                isDefined(AndroidWebSurveyJsInterface) &&
                                isDefined(
                                    AndroidWebSurveyJsInterface.onSurveyFinished
                                )
                            ) {
                                // eslint-disable-next-line
                                AndroidWebSurveyJsInterface.onSurveyFinished()
                                eventFired = true
                            }
                        } catch (error) {
                            //
                        }
                        //
                        if (!eventFired) {
                            try {
                                if (
                                    window.webkit &&
                                    window.webkit.messageHandlers
                                ) {
                                    window.webkit.messageHandlers.defaultMessageHandler.postMessage(
                                        JSON.stringify({ finished: true })
                                    )
                                    eventFired = true
                                }
                            } catch (error) {
                                //
                            }
                        }
                        //
                        if (!eventFired) {
                            if (!isDefined(state.timelineId)) {
                                // eslint-disable-next-line
                                var messager = new Messager({
                                    remoteWindow: window.parent,
                                    local: APP_WEB_URL,
                                    remote: APP_WEBAPP_URL,
                                })
                                messager.send('updateTimelines', {
                                    param: null,
                                })
                            }
                        }
                        //
                        return false
                    } else {
                        window.location.href = addCSRFToken(
                            allRoutes['private.timeline'].pathname
                        )
                    }
                }, 10000)
                return null

            case 115:
                logoutAndRedirect()
                break

            case 103: // disabled account
            case 210:
                logoutAndRedirect()
                break

            case 205:
            case 204: // expired session
                logoutAndRedirect()
                break

            case 1802: // crypt error
                displayErrors(response.message, 2800)
                setTimeout(() => {
                    window.location.href = addCSRFToken(
                        allRoutes['private.patients.listing'].pathname
                    )
                }, 3000)
                break

            default:
                displayErrors(response.message, 2000)
        }
        return response
    } else {
        // everything is ok
    }
    return response
}

const displayErrors = function (message, time, kind = 'error') {
    let errorContent = document.getElementById('btzMaintextContent')
    let errorMssg = document.getElementById('btzMainMessage')
    if (errorContent !== null) {
        errorContent.innerHTML = message.replace(/\\n/g, '<br>')

        if (errorMssg !== null) {
            errorMssg.classList.add('active')
            errorMssg.classList.add(kind)

            msgTimeout = setTimeout(function () {
                clearTimeout(msgTimeout)
                msgTimeout = null
                errorMssg.classList.remove('active')
                errorMssg.classList.remove(kind)
            }, time)
        }
    }
}

export { displayErrors, handleErrors }
