export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        var swFound = false
        if ("serviceWorker" in navigator) {
            // eslint-disable-next-line compat/compat
            navigator.serviceWorker
                .getRegistrations()
                .then(function (registrations) {
                    for (var registration of registrations) {
                        registration.unregister()
                        swFound = true
                    }
                    if (swFound) {
                        window.location.reload()
                    }
                })
            }
    },
}
