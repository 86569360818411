import { t } from 'i18next'
import { metricDegreesToFahrenheit } from './temperature/metricDegreesToFahrenheit'
import { metricFahrenheitToDegrees } from './temperature/metricFahrenheitToDegrees'
import { metricKilogramToPounds } from './weight/metricKilogramToPounds'
import { metricPoundsToKilogram } from './weight/metricPoundsToKilogram'

export const metricKilogramToPoundsFormula = (kilogram) => {
    return String(kilogram) * 2.20462262185
}
export const metricPoundsToKilogramFormula = (pounds) => {
    return String(pounds) / 2.20462262185
}
export const metricDegreesToFahrenheitFormula = (degree) => {
    return (parseFloat(String(degree)) * 9) / 5 + 32
}
export const metricFahrenheitToDegreesFormula = (fahrenheit) => {
    return ((parseFloat(String(fahrenheit)) - 32) * 5) / 9
}

const metricsFnWeight = {
    input: metricKilogramToPounds,
    output: metricPoundsToKilogram,
}
const metricsFnDegrees = {
    input: metricDegreesToFahrenheit,
    output: metricFahrenheitToDegrees,
}

export const LocalesConversions = {
    cryptedweight: { regions: ['US', 'GB'], fn: metricsFnWeight },
    cryptedtemperature: { regions: ['US'], fn: metricsFnDegrees },
}

export const defaultLocalesUnits = {
    cryptedweight: 'kilogrammes',
    cryptedtemperature: 'degrés celsius',
}
export const LocalesConversionsUnit = {
    cryptedweight: { regions: ['US', 'GB'], unit: 'livres' },
    cryptedtemperature: { regions: ['US'], unit: 'degrés fahrenheit' },
}

// to retrieve trans key when script is launched
const i18nUnitCryptedWeight = t('kilogrammes')
const i18nUnitCryptedTemperature = t('degrés celsius')
const i18nUnitCryptedWeightImperial = t('livres')
const i18nUnitCryptedTemperatureImperial = t('degrés fahrenheit')
