import { t } from 'i18next'
import { Admin } from '@app/api'
import { isDefined, isEmail, ensurePasswordStrength } from '@app/core'
const no400handleFor = ['phonePrefix']
//
const coreFn = {
    _handle400: (err) => (state, actions) => {
        if (
            isDefined(err) &&
            isDefined(err.extras) &&
            isDefined(err.extras.field)
        ) {
            let fields = err.extras.field.replace(/ /g, '').split('/')
            if (!Array.isArray(fields)) {
                fields = [fields]
            }
            for (let field in fields) {
                field = fields[field]
                if (no400handleFor.indexOf(field) > -1) {
                    continue
                }
                if (isDefined(state.values[field])) {
                    actions._setValidity({ [field]: false })
                    actions._setTyping({ [field]: false })
                }
            }
        }
    },
    /**
     * Retrieve form input on the fly
     * @function corefn:retrieveInput
     * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
     */
    _retrieveInput: (input) => (state, actions) => {
        const { isvalid, name, values } = input
        if (state.values[name] === undefined) {
            // if [name] is not defined in state.values, the field must not be sended
            return false
        }
        let testingValue = values
        let testedValue = state.values[name]
        if (!isDefined(testingValue)) {
            testingValue = ''
        } else if (Array.isArray(testingValue)) {
            testingValue = testingValue.join('')
        } else if (typeof testingValue === 'object') {
            testingValue = Object.values(testingValue).join('')
        }
        if (isDefined(testedValue)) {
            if (Array.isArray(testedValue)) {
                testedValue = testedValue.join('')
            } else if (typeof testedValue === 'object') {
                testedValue = Object.values(testedValue).join('')
            }
        }
        //
        let isItReallyValid = isvalid
        if (name.indexOf('password') > -1) {
            if (values !== '' && isvalid) {
                if (name === 'password') {
                    isItReallyValid = true
                    actions._setValidity({ passwordconfirm: isItReallyValid })
                } else if (
                    name === 'passwordconfirm' &&
                    values === state.values.password &&
                    state.isValid.password
                ) {
                    isItReallyValid = true
                    actions._setValidity({ password: isItReallyValid })
                } else {
                    isItReallyValid = false
                }
            } else {
                isItReallyValid = false
            }
            if (isItReallyValid) {
                actions._setErrorMessages({ password: '' })
            }
        }
        //
        actions._setValues({ [name]: values })
        actions._setValidity({ [name]: isItReallyValid })
        if (name === 'secret') {
            if (values !== state.values[name]) {
                actions._setTyping({ [name]: true })
            }
        } else {
            actions._setTyping({ [name]: true })
        }
        //
        if (isvalid) {
            actions._setErrorMessages({ [name]: '' })
        }
        if (isDefined(state.errorMessages.global)) {
            actions._setErrorMessages({ global: '' })
        }
        //
        if (
            isDefined(state.atLeastOneModification) &&
            !state.atLeastOneModification &&
            isDefined(testedValue) &&
            testedValue !== testingValue
        ) {
            window.onbeforeunload = function () {
                return ''
            }
            actions._handleLeaveWithoutSaveing(true)
            actions._setState({ key: 'atLeastOneModification', value: true })
        }
        //
        if (
            name.indexOf('password') > -1 &&
            state.values.passwordconfirm !== '' &&
            isvalid
        ) {
            if (
                (name === 'password' &&
                    values === state.values.passwordconfirm) ||
                (name === 'passwordconfirm' && values === state.values.password)
            ) {
                actions._setErrorMessages({ passwordconfirm: '' })
                actions._setErrorMessages({ password: '' })
            } else {
                actions._setValidity({ passwordconfirm: false })
                actions._setErrorMessages({
                    passwordconfirm: t(
                        'les deux mots de passe ne sont pas similaires',
                        { ns: 'generals' }
                    ),
                })
                actions._setTyping({ passwordconfirm: false })
            }
        }
        //
        if (isItReallyValid) {
            actions._setErrorMessages({ [name]: '' })
            if (name.indexOf('phone') > -1) {
                actions._setState({ key: 'modalEditing', value: 'phone' })
            } else if (name.indexOf('code') > -1) {
                actions._setState({ key: 'modalEditing', value: 'phone-code' })
            }
        }
    },
    /**
     * Listener to display disclamer popup if leaving without saving changes (displayed only if a change has occured)
     * @param {boolean} handle - Boolean to activate/deactivate the listener
     * @function corefn:handleLeaveWithoutSaveing
     */
    _handleLeaveWithoutSaveing: (handle) => (state, actions) => {
        if (handle) {
            window.onbeforeunload = function () {
                return ''
            }
        } else {
            window.onbeforeunload = function () {
                //
            }
        }
        actions._setState({ key: 'atLeastOneModification', value: handle })
    },
    /**
     * Update error message key state
     * @function corefn:setErrorMessages
     * @param {object} newState - Target {[name]: {string}} to update
     */
    _setErrorMessages: (newState) => (state) => ({
        errorMessages: { ...state.errorMessages, ...newState },
    }),
    /**
     * Update values key state
     * @function corefn:setValues
     * @param {object} newState - Target {[name]: values} to update
     */
    _setValues: (newState) => (state) => ({
        values: { ...state.values, ...newState },
    }),
    /**
     * Update the whole values object
     * @function corefn:setValuesObj
     * @param {object} newState - Values object to replace with
     */
    _setValuesObj: (newState) => (state) => ({
        values: newState,
    }),
    /**
     * Update the whole ovalues object
     * @function corefn:setOValuesObj
     * @param {object} newState - Ovalues object to replace with
     */
    _setOValuesObj: (newState) => (state) => ({
        ovalues: newState,
    }),
    /**
     * Update touched key state
     * @function corefn:setTouched
     * @param {object} newState - Target {[name]: {boolean}} to update
     */
    _setTouched: (newState) => (state) => ({
        isTouched: { ...state.isTouched, ...newState },
    }),
    /**
     * Update validity key state
     * @function corefn:setValidity
     * @param {object} newState - Target {[name]: {boolean}} to update
     */
    _setValidity: (newState) => (state) => ({
        isValid: { ...state.isValid, ...newState },
    }),
    /**
     * Update state
     * @function corefn:setState
     * @param {string} key - Target key to update
     * @param {string} value - value
     */
    _setState:
        ({ key, value }) =>
        (state) => ({
            [key]: value,
        }),
    /**
     * Update all typing key state
     * @function corefn:setTypingFalse
     */
    _setTypingFalse: () => (state, actions) => {
        let isTyping = state.isTyping
        for (let tpng in isTyping) {
            isTyping[tpng] = false
        }
        state.isTyping = isTyping
    },
    /**
     * Update typing key state
     * @function corefn:setTyping
     * @param {object} newState - Target {[name]: {boolean}} to update
     */
    _setTyping: (newState) => (state) => ({
        isTyping: { ...state.isTyping, ...newState },
    }),
}

export { coreFn }
