import {
    getWebInstrumentations,
    initializeFaro,
    LogLevel,
    faro,
    ConsoleInstrumentation,
} from '@grafana/faro-web-sdk'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'
import { flattenObject } from '../flattenObject'
import { isMobileOrTablet } from '@app/core'

const GRAFANA_ENV_MAPPING = {
    development: 'development',
    release: 'staging',
    production: 'production',
}

const grafanaInitialize = () => {
    const GRAFANA_CLOUD_URL = process.env.APP_GRAFANA_CLOUD_URL
    const GRAFANA_CLOUD_APP_NAME = process.env.APP_GRAFANA_CLOUD_APP_NAME
    if (!GRAFANA_CLOUD_URL || !GRAFANA_CLOUD_APP_NAME) {
        // defined only for resilience, we don't want to retrieve Grafana log for uroconnect/unicancer
        return
    }
    initializeFaro({
        // we cannot send dev log to grafana because of CORS configured in the grafana app
        // if this is needed, create your own Grafana Cloud instance and change GRAFANA_CLOUD_URL
        paused: process.env.NODE_ENV === 'development',
        url: GRAFANA_CLOUD_URL,
        apiKey: 'secret',
        app: {
            name: `${GRAFANA_CLOUD_APP_NAME} - ${process.env.APP_CUSTOMISATION.toLowerCase()}`,
            version: process.env.APP_VERSION,
            environment:
                GRAFANA_ENV_MAPPING[process.env.NODE_ENV] ?? 'development',
        },
        instrumentations: [
            // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
            ...getWebInstrumentations({
                captureConsole: false,
            }),

            // Initialization of the tracing package.
            // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
            new TracingInstrumentation(),

            new ConsoleInstrumentation({
                disabledLevels: [LogLevel.TRACE, LogLevel.WARN, LogLevel.INFO],
            }),
        ],
    })
}

const isFaroApiDefined = () => {
    return !!faro.api
}

const grafanaLogEvent = (
    name,
    attributes = {},
    domain = 'global',
    customLogLevel = null
) => {
    if (!isFaroApiDefined()) {
        return
    }
    let logLevel = customLogLevel ?? 'info'
    if (
        !customLogLevel &&
        attributes.code &&
        attributes.message &&
        attributes.extras
    ) {
        logLevel = 'error'
    }
    //
    faro.api.pushEvent(
        name,
        { ...flattenObject(attributes), level: logLevel },
        domain
    )
}

const grafanaOpenSession = (user) => {
    if (!isFaroApiDefined()) {
        return
    }
    faro.api.setUser({
        id: user?.id,
    })

    faro.api.setSession({
        attributes: {
            user_id: user?.id,
            user_locale: user?.locale,
            user_is_mobile: String(isMobileOrTablet()),
            browser_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            browser_timezone_offset: new Date().getTimezoneOffset().toString(),
        },
    })
}

const grafanaCloseSession = () => {
    if (!isFaroApiDefined()) {
        return
    }
    faro.api.resetSession()
    faro.api.resetUser()
}

class GrafanaInteractionsPool {
    constructor(_poolId) {
        // _poolId is used to order log in grafana attributes key
        this._poolId = _poolId
        this._pool = []
    }

    get getPool() {
        const orderPrefix = String(this._pool.length).length
        return this._pool.reduce((acc, curr, idx) => {
            const currKey = `${this._poolId}-${String(idx).padStart(orderPrefix, '0')}`
            return { ...acc, [currKey]: curr }
        }, {})
    }

    pushValue(value) {
        this._pool.push(value)
    }

    static createGrafanaInteractionsPool(poolId) {
        if (!poolId || typeof poolId !== 'string') {
            return new GrafanaInteractionsPool('pool')
        }

        return new GrafanaInteractionsPool(poolId)
    }
}

export {
    grafanaInitialize,
    grafanaLogEvent,
    grafanaOpenSession,
    grafanaCloseSession,
    GrafanaInteractionsPool,
}
